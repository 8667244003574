import { Box, Container } from "@mui/material";
import AppBarBack from "components/AppBarBack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Student Dashboard
import Calendar from "../StudentDashboard/Calendar";
import Announcements from "../StudentDashboard/Announcements";
import TodayDiary from "../StudentDashboard/TodayDiary";
import TodayClass from "../StudentDashboard/TodayClass";
import HomeWork from "../StudentDashboard/HomeWork";
import AssesmentDetail from "../StudentDashboard/AssesmentDetail";
import Attendance from "../StudentDashboard/Attendance";
import { connect, useSelector } from "react-redux";
import axiosInstance from "util/axiosInstance";
const StudentDashboard = (props) => {
  const [newTimeTable, setNewTimeTable] = useState(false);
  const ttConfig = useSelector(
    (state) => state?.user?.systemConfig?.["tt-enabled-v2"]
  );

  useEffect(() => {
    if (ttConfig?.includes(props?.branchId)) {
      setNewTimeTable(true);
    } else {
      setNewTimeTable(false);
    }
  }, [props?.branchId, ttConfig]);

  const navigate = useNavigate();
  return (
    // <Container component="main" maxWidth="sm" sx={{ p: 0 }}>
    // {/* <AppBarBack
    //   title="Dashboard"
    //   onClick={() => navigate("/dashboard/home")}
    //   noSettings
    // /> */}
    <Container
      sx={{
        p: 0,
        pt: 1,
      }}
    >
      <div
        style={{
          background:
            "linear-gradient(327deg, rgba(251, 232, 255, 0.58) 0%, rgba(209, 224, 255, 0.58) 100%)",
          borderRadius: "40px 0px 0px 0px",
          position: "relative",
          top: 50,
        }}
      >
        <div style={{ position: "relative", top: -50 }}>
          {props?.user?.user_level === 13 && (
            <Box sx={{ px: 0 }}>
              <Box
                className="scrollBar-hide"
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  p: 1,
                  px: 2,
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    pb: 0.5,
                    gap: 2,
                  }}
                > */}
                {/* {props.userNavigationData
                    ?.find((o) => o.parent_modules === "Communication")
                    ?.child_module.find(
                      (o) => o.child_name === "Announcement"
                    ) && <Announcements />} */}

                {props.userNavigationData
                  ?.find((o) => o.parent_modules === "Calendar")
                  ?.child_module.find(
                    (o) => o.child_name === "Student Calendar"
                  ) && <Calendar update={props?.update} />}
                {/* </Box> */}
              </Box>

              {/* {props.userNavigationData
                ?.find((o) => o.parent_modules === "Time Table")
                ?.child_module.find(
                  (o) => o.child_name === "Student Time Table"
                ) && (
                <Box sx={{ px: 2 }}>
                  <TodayClass newTimeTable={newTimeTable} />
                </Box>
              )} */}

              {/* {props.userNavigationData
                ?.find((o) => o.parent_modules === "Diary")
                ?.child_module.find(
                  (o) => o.child_name === "Student Diary"
                ) && (
                <Box sx={{ px: 2 }}>
                  <TodayDiary />
                </Box>
              )} */}
              {/* {props.userNavigationData
                ?.find((o) => o.parent_modules === "Homework")
                ?.child_module.find(
                  (o) => o.child_name === "Student Homework"
                ) && <HomeWork />} */}

              {/* {props.userNavigationData
                ?.find((o) => o.parent_modules === "Assessment")
                ?.child_module.find((o) => o.child_name === "Take Test") && (
                <AssesmentDetail />
              )} */}

              {/* {props.userNavigationData
                ?.find((o) => o.parent_modules === "Attendance")
                ?.child_module.find(
                  (o) => o.child_name === "View Attendance"
                ) && <Attendance />} */}

              {/* <Box sx={{ pt: 4 }}>
                  <Typography variant="subtitle2">
                    <b>All Apps</b>
                  </Typography>
                </Box> */}
              {/* <ExploreAllComponent /> */}
            </Box>
          )}
        </div>
      </div>
    </Container>
    // {/* </Container> */}
  );
};

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userNavigationData: state.user.userNavigationData,
  userAppsData: state.user.userAppsData,
  moduleId: state.user.moduleId,
  academicYear: state.user?.branch?.session_year,
  branchId: state.user.branchId,
  acadYearList: state.user.acadYearList,
  currentAcadYear: state.user.currentAcadYear,
  branch: state.user.branch,
  schoolDetails: state.user.schoolDetails,
});

export default connect(mapStateToProps)(StudentDashboard);
