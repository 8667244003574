import {
  Box,
  Dialog,
  Typography,
  Avatar,
  Paper,
  SwipeableDrawer,
  Card,
  CardContent,
  Divider,
  Chip,
} from "@mui/material";
import { AMAZON_S3 } from "apiConfig";
import React, { useState } from "react";
import { connect } from "react-redux";
import { loginWithMobileNo } from "redux/actions/userActions";

import theme from "util/theme";
import { useNavigate } from "react-router-dom";
import SimpleSnackbar from "components/Snackbar";
import Puller from "components/Drawer/Puller";
import { KeyboardArrowRight } from "@mui/icons-material";

function SelectProfileDrawer(props) {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("warning");

  const currentUserErp = props?.erpUserId;

  return (
    <>
      <SwipeableDrawer
        onOpen={() => {}}
        anchor="bottom"
        open={props?.showProfilesDialog}
        onClose={() => props?.setShowProfilesDialog(false)}
        PaperProps={{
          elevation: 0,
          style: { backgroundColor: "transparent", zIndex: 100000 },
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: "auto",
          }}
        >
          <CardContent sx={{ pb: "0px !important" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                p: 1,
                position: "relative",
              }}
            >
              <Puller />
            </Box>
            <Box
              sx={{
                py: 2,
                px: 1,
                pb: 0,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRadius: 4,
                maxHeight: "400px",
                overflowY: "scroll",
              }}
            >
              {props?.userList?.map((each, index) => {
                let isCurrentUser = each?.erp_id == currentUserErp;
                return (
                  <Box
                    sx={{
                      borderRadius: 4,
                      border: theme.border.main,
                      backgroundColor: isCurrentUser
                        ? theme.palette.blue.light5
                        : theme.palette.base.white,
                      outline: isCurrentUser
                        ? `1px solid ${theme.palette.blueDark?.dark1}`
                        : "",
                      position: "relative",
                      pt:
                        (each?.roles__role_name || each?.role) &&
                        each?.user__level__user_level != 13 &&
                        each?.level != 13
                          ? 1.5
                          : 0.5,
                    }}
                    key={each?.erp_id}
                    onClick={() => {
                      if (isCurrentUser) return;
                      const userData = {
                        contact: props?.contact,
                        erp_id: each?.erp_id,
                        hmac: each?.hmac,
                      };
                      props.loginWithMobileNo(
                        userData,
                        navigate,
                        setOpenSnackbar,
                        setSeverity
                      );
                      localStorage.setItem(
                        "selectProfileDetails",
                        JSON.stringify(each)
                      );
                      props?.setShowProfilesDialog(false);
                    }}
                    boxShadow={theme.shadows[3]}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {window.location.href.slice(8, 10) == "qa" ||
                        window.location.href.slice(7, 12) == "local" ? (
                          <Avatar
                            alt={each?.name}
                            src={`${AMAZON_S3.ERP_BUCKET}/dev/media/${each?.profile}`}
                            sx={{ width: 42, height: 42 }}
                          />
                        ) : (
                          <Avatar
                            alt={each?.name}
                            src={`${AMAZON_S3.ERP_BUCKET}/prod/media/${each?.profile}`}
                            sx={{ width: 42, height: 42 }}
                          />
                        )}
                        <Box
                          sx={{
                            ml: 1.5,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                            }}
                          >
                            <Typography
                              variant="md"
                              type="medium"
                              sx={{
                                wordBreak: "break-all",
                              }}
                            >
                              {each?.name}
                            </Typography>
                            {(each?.roles__role_name || each?.role) &&
                            each?.user__level__user_level != 13 &&
                            each?.level != 13 ? (
                              <Box
                                color={theme.palette.gray.main}
                                variant="sm"
                                type="medium"
                                sx={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "4px",
                                }}
                              >
                                <Chip
                                  label={each?.roles__role_name || each?.role}
                                  sx={{
                                    height: "auto",
                                    "& .MuiChip-label": {
                                      // fontWeight: "bold",
                                      textTransform: "capitalize",
                                    },
                                  }}
                                />
                              </Box>
                            ) : null}
                          </Box>
                          <Typography
                            variant="sm"
                            type="medium"
                            color="text.secondary"
                          >
                            {each?.erp_id}
                          </Typography>
                          {each?.user__level__user_level != 13 ? null : (
                            <Box
                              sx={{
                                display: "flex",
                              }}
                            >
                              <Typography variant="sm" color="text.secondary">
                                {each?.grade_name}
                              </Typography>
                              <Typography
                                sx={{ pl: 1 }}
                                variant="sm"
                                color="text.secondary"
                              >
                                {each?.section_name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      {isCurrentUser ? null : (
                        <KeyboardArrowRight
                          fontSize="large"
                          sx={{ p: 0.1, color: "text.secondary" }}
                        />
                      )}
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: theme.palette.gray.light4,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={"scl_logo"}
                        src={props?.schoolDetails?.school_logo}
                        sx={{ width: 28, height: 24 }}
                      />
                      <Typography
                        variant="md"
                        color={theme.palette.gray.dark3}
                        sx={{ pl: 1 }}
                      >
                        {each?.branch_name}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
              <Box
                sx={{
                  height: 32,
                }}
              ></Box>
            </Box>
          </CardContent>
        </Card>
      </SwipeableDrawer>
      {/* <Dialog
        open={props?.showProfilesDialog}
        onClose={() => props?.setShowProfilesDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ zIndex: 10000 }}
        fullWidth
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">
            <b>Welcome,</b>
          </Typography>
          <Typography variant="subtitle2" sx={{ pb: 2, pt: 1 }}>
            <b>Select Profile to explore account</b>
          </Typography>
          {props?.userList?.map((each) => (
            <Paper
              className="card-border"
              sx={{ p: 1, my: 1, borderRadius: 1 }}
              key={each?.erp_id}
              onClick={() => {
                const userData = {
                  contact: props?.contact,
                  erp_id: each?.erp_id,
                  hmac: each?.hmac,
                };
                props.loginWithMobileNo(
                  userData,
                  navigate,
                  setOpenSnackbar,
                  setSeverity
                );
                localStorage.setItem(
                  "selectProfileDetails",
                  JSON.stringify(each)
                );
              }}
            >
              <Box className="color-gray" sx={{ p: 1, mb: 1 }}>
                <Typography>
                  <b>{each?.branch_name}</b>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", py: 1 }}>
                {window.location.href.slice(8, 10) == "qa" ||
                window.location.href.slice(7, 12) == "local" ? (
                  <Avatar
                    alt={each?.name}
                    src={`${AMAZON_S3.ERP_BUCKET}/dev/media/${each?.profile}`}
                  />
                ) : (
                  <Avatar
                    alt={each?.name}
                    src={`${AMAZON_S3.ERP_BUCKET}/prod/media/${each?.profile}`}
                  />
                )}
                <Box sx={{ ml: 1 }}>
                  <Typography variant="subtile2">
                    <b>{each?.name}</b>
                  </Typography>{" "}
                  <Box
                    sx={{ display: "flex", color: theme.palette.success.main }}
                  >
                    <Typography variant="subtile2">
                      {each?.grade_name}
                    </Typography>{" "}
                    <Typography sx={{ pl: 1 }} variant="subtile2">
                      {each?.section_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </Dialog> */}

      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state?.user,
  UI: state.UI,
  userList: state?.user?.userList,
  erpUserId: state?.user?.userData?.erp,
  contact: state?.user?.userContact,
  schoolDetails: state?.user?.schoolDetails,
});
const mapActionsToProps = {
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(SelectProfileDrawer);
