import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

import React, { useContext } from "react";
// eslint-disable-next-line import/no-cycle
import { AlertNotificationContext } from "../../context-api/alert-context/alert-state";
// import "./alert-notification.scss";

const AlertNotification = () => {
  const {
    message,
    type,
    isShown,
    hideAlert,
    showCloseIcon = false,
    moreAction,
  } = useContext(AlertNotificationContext);

  let action = showCloseIcon ? (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={hideAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  ) : null;

  return (
    // <>
    //   {isShown && (
    //     <Box className={classes.alertBox}>
    //       <Alert
    //         {...(showCloseIcon && { onClose: () => hideAlert() })}
    //         variant="filled"
    //         severity={type}
    //         className={clsx(classes.menuItemIcon, "alert__container")}
    //       >
    //         {message}
    //       </Alert>
    //     </Box>
    //   )}
    // </>
    <Snackbar
      open={isShown}
      autoHideDuration={3000}
      onClose={hideAlert}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
    >
      <Alert
        // onClose={hideAlert}
        severity={type}
        sx={{ width: "100%" }}
        action={
          <React.Fragment>
            {moreAction}
            {action}
          </React.Fragment>
        }
      >
        {/* <Typography variant="sm">{message}</Typography> */}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertNotification;
