import { Box, Container } from "@mui/system";
import React, { useEffect, useRef } from "react";
// import LoginPageImage from "../../assets/img/LoginPageImage.png";
import LoginPageImage from "../../assets/img/LoginPageImage.png";
import LoginPageImage2 from "../../assets/img/LoginPageImage2.png";
import theme from "util/theme";
import { Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isB2b } from "apiConfig";
function LoginOptionPage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.base.white,
        height: "100vh",

        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          background:
            "linear-gradient(327deg, rgba(251, 232, 255, 0.58) 0%, rgba(209, 224, 255, 0.58) 100%)",
          borderRadius: "1% 15% 77% 25% / 0% 0% 28% 19% ",
          // flex: 1,
          // border: "1px solid green",
        }}
      >
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            // height: "578p",
            width: "100%",
            backgroundImage: `url(${LoginPageImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // border: "1px solid red",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // border: "1px solid red",
          flex: "auto",
          p: 3,
          px: 5,
          // flex: 2,
        }}
      >
        <Box sx={{ display: "grid", gap: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate("/login/?tab=1");
            }}
            // disabled={error}
          >
            <Typography variant="sm" type="semibold">
              Login with ERP Number
            </Typography>
          </Button>
          <Button
            fullWidth
            variant="contained"
            // sx={{ my: 1 }}
            onClick={() => {
              navigate("/login/?tab=0");
            }}
          >
            <Typography variant="sm" type="semibold">
              Login with Mobile Number
            </Typography>
          </Button>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="sm" sx={{ textAlign: "center", mt: 1 }}>
              <span
                style={{
                  color: theme.palette.gray.main,
                }}
              >
                By continuing, you agree to the
              </span>{" "}
              <a
                href="https://orchids.letseduvate.com/terms-condition"
                // underline="none"
              >
                <span
                  style={{
                    color: theme.palette.gray.dark2,
                  }}
                >
                  <b>Terms of Services </b>
                </span>
              </a>
              <span
                style={{
                  color: theme.palette.gray.main,
                }}
              >
                &
              </span>{" "}
              <a
                href="https://orchids.letseduvate.com/privacy-policy"
                // underline="none"
              >
                <span
                  style={{
                    color: theme.palette.gray.dark2,
                  }}
                >
                  <b> Privacy Policy. </b>
                </span>
              </a>
            </Typography>
            {/* </a> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginOptionPage;
