import React, { useEffect, useState } from "react";

// MUI Stuff
import {
  Box,
  Container,
  Fab,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

// Icons
// import {
//   AddRounded as AddRoundedIcon,
// } from "@mui/icons-material";

//Redux
import { connect } from "react-redux";

import AppBarBack from "../../../components/AppBarBack";
import DiaryDrawer from "./DiaryDrawer";
import SimpleSnackbar from "../../../components/Snackbar";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import AllDiary from "./AllDiary";
import DailyDiary from "./DailyDiary";
import GeneralDiary from "./GeneralDiary";
import DiaryFilterDrawer from "./DiaryFilterDrawer";

import { useNavigate, useSearchParams } from "react-router-dom";
import { StyledTab, StyledTabs } from "../../styledComponents";

import dayjs from "dayjs";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";

const floatStyle = {
  margin: 0,
  top: "auto",
  right: 16,
  bottom: 16,
  left: "auto",
  position: "fixed",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Diary(props) {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState({});

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "info",
    message: "",
  });

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(3, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [filtered, setFiltered] = useState(false);

  const applyFilter = () => {
    setFiltered((a) => !a);
    setShowFilterDrawer(false);
  };

  const setAlert = (severity, message = "Something went wrong!") => {
    setOpenSnackbar(true);
    setSnackbarData({
      severity: severity,
      message: message,
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTabParam(newValue);
  };

  const [tabParam, setTabParam] = useSearchParamsState(
    "tab",
    Number(searchParams.get("tab"))
  );

  const [value, setValue] = React.useState(Number(tabParam) ?? 0);

  const navigate = useNavigate();

  useEffect(() => {
    setTabParam(value);
  }, []);

  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ p: 0 }}>
        <AppBarBack title="Diary" onClick={() => navigate("/dashboard/home")} />
        <Box sx={{ width: "100%" }}>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <StyledTab
                style={{ textTransform: "capitalize" }}
                label="All"
                {...a11yProps(0)}
              />
              <StyledTab
                style={{ textTransform: "capitalize" }}
                label="Daily Diary"
                {...a11yProps(1)}
              />
              <StyledTab
                style={{ textTransform: "capitalize" }}
                label="General Diary"
                {...a11yProps(2)}
              />
            </StyledTabs>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              m: 0,
              pb: 0,
              backgroundColor: "whitesmoke",
            }}
          >
            <Typography variant="subtitle2">
              Dates : <b>{dayjs(startDate).format("MMM D")}</b> to{" "}
              <b>{dayjs(endDate).format("MMM D, YYYY")}</b>
            </Typography>
          </Box>
          <TabPanel value={value} index={0}>
            <AllDiary
              setAlert={setAlert}
              setShowDrawer={setShowDrawer}
              setDrawerData={setDrawerData}
              startDate={startDate}
              endDate={endDate}
              filtered={filtered}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DailyDiary
              setAlert={setAlert}
              setShowDrawer={setShowDrawer}
              setDrawerData={setDrawerData}
              startDate={startDate}
              endDate={endDate}
              filtered={filtered}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GeneralDiary
              setAlert={setAlert}
              setShowDrawer={setShowDrawer}
              setDrawerData={setDrawerData}
              startDate={startDate}
              endDate={endDate}
              filtered={filtered}
            />
          </TabPanel>
        </Box>

        <Box
          sx={floatStyle}
          onClick={() => {
            setShowFilterDrawer((value) => !value);
          }}
        >
          <Fab color="primary" aria-label="filter">
            <FilterAltIcon />
          </Fab>
        </Box>

        <DiaryFilterDrawer
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          applyFilter={applyFilter}
          showFilterDrawer={showFilterDrawer}
          setShowFilterDrawer={setShowFilterDrawer}
        />

        <DiaryDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          // drawerData={}
          drawerData={drawerData}
          diaryType={drawerData?.dairy_type}
        />

        <SimpleSnackbar
          message={snackbarData.message}
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          severity={snackbarData.severity}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  moduleId: state.user.moduleId,
});

export default connect(mapStateToProps)(Diary);
