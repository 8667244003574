import axios from "axios";
import jwtDecode from "jwt-decode";
import { API_MASTER } from "../apiConfig";
import store from "../redux/store";
import { SET_USER } from "../redux/types";

const axiosInstance = axios.create({ baseURL: API_MASTER });

axiosInstance.interceptors.request.use(async function (config) {
  const user = JSON.parse(localStorage.getItem("user_details"));
  const requestConfig = config;

  // Check for user details
  if (user) {
    var currentTime = new Date();

    const currentTimeBuffer = new Date(currentTime.getTime() + 15000);

    var accessTokenExpiryTime = new Date(jwtDecode(user.token).exp * 1000);

    // Access token expiry time check
    if (accessTokenExpiryTime < currentTimeBuffer) {
      //Refresh token existance check
      if (user?.refresh_token) {
        var refreshTokenExpiryTime = new Date(
          jwtDecode(user?.refresh_token).exp * 1000
        );

        // Refresh token expiry check
        if (refreshTokenExpiryTime > currentTimeBuffer) {
          // Get new access token
          let data = {
            refresh: user?.refresh_token,
          };

          let config = {
            method: "post",
            url: `${API_MASTER}/erp_user/access-token/`,
            data: data,
          };

          await axios
            .request(config)
            .then((response) => {
              if (response.status === 200 && response.data?.status == 200) {
                // Update access token this axios
                requestConfig.headers.Authorization = `Bearer ${response.data?.data}`;

                const newUpdatedUserInfo = {
                  ...user,
                  token: response.data?.data,
                };

                // Update access token in localstorage
                localStorage.setItem(
                  "user_details",
                  JSON.stringify(newUpdatedUserInfo)
                );

                // Update access token in authToken localstorage
                localStorage.setItem(
                  "authToken",
                  `Bearer ${response.data?.data}`
                );

                // Update access token in redux
                store.dispatch({
                  type: SET_USER,
                  payload: newUpdatedUserInfo,
                });

                // if force_update=true navigate to change password
                if (response.data?.force_update) {
                  window.location.href = `${window.location.origin}/m/profile/changepassword`;
                }
              }
            })
            .catch((error) => {
              console.log(error);
              requestConfig.headers.Authorization = "";
            });
        }
      } else {
        requestConfig.headers.Authorization = "";
      }
    } else {
      requestConfig.headers.Authorization = `Bearer ${user.token}`;
    }
  } else {
    requestConfig.headers.Authorization = "";
  }
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.data?.status === "412") {
      const platform = localStorage.getItem("platform");

      localStorage.clear();
      window.location.href = `${window.location.origin}/m/login`;

      try {
        window.flutter_inappwebview.callHandler("logoutUserHandler", {});
      } catch (err) {
        console.log(err);
      }
    } else if (error.response?.status === 401) {
      const platform = localStorage.getItem("platform");

      localStorage.clear();
      window.location.href = `${window.location.origin}/m/login`;

      try {
        window.flutter_inappwebview.callHandler("logoutUserHandler", {});
      } catch (err) {
        console.log(err);
      }
    } else {
      // something went wrong
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
