import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import CacheBuster from "./util/CacheBuster";
import { Button, Typography } from "@mui/material";
import UpdateVersionImg from "./assets/img/update_version.svg";
import NoInternetImg from "./assets/img/no_internet.svg";
import { Box } from "@mui/system";
import "./util/colors.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./util/timeLine.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (!loading && !isLatestVersion) {
          return (
            <div className="container">
              <div className="text-center h-100">
                <Box pt={30} pb={5}>
                  <div
                    style={{
                      backgroundImage: `url(${UpdateVersionImg})`,
                      height: 200,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </Box>

                <Typography>A new version of the app is available !</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    refreshCacheAndReload();
                  }}
                >
                  Update Version
                </Button>
              </div>
            </div>
          );
        }

        if (navigator.onLine) {
          return <App />;
        } else {
          return (
            <div className="container">
              <div className="text-center h-100">
                <Box pt={30} pb={5}>
                  <div
                    style={{
                      backgroundImage: `url(${NoInternetImg})`,
                      height: 150,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </Box>
                <Typography>No internet connection !</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button>
              </div>
            </div>
          );
        }
      }}
    </CacheBuster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// TODO: Home Page -- Feature Card simplify
// TODO: In-line css check -- Observation Module -- gradient, shadows, border centeralised
