import React, { useEffect, useState } from "react";

// MUI Stuff
import { useNavigate } from "react-router-dom";

import AppBarBack from "components/AppBarBack";
import axiosInstance from "util/axiosInstance";
import { Box, CircularProgress, Typography } from "@mui/material";

function RelationManager(props) {
  const navigate = useNavigate();
  const [rmToken, setRmToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const currentDate = new Date().toDateString();
  const lastCallDate = localStorage.getItem("RmTokenLastCallDate");

  useEffect(() => {
    const rmTokenLocal = localStorage.getItem("RmToken");

    if (lastCallDate !== currentDate) {
      getRmToken();
    } else {
      setRmToken(rmTokenLocal);
    }
  }, [rmToken]);

  const getRmToken = () => {
    setLoading(true);
    axiosInstance
      .get("/vendor/konverse_login/")
      .then((response) => {
        console.log(response.data);
        if (response?.data?.status_code === 200) {
          setRmToken(response?.data?.result?.token);
          localStorage.setItem("RmTokenLastCallDate", currentDate);
          localStorage.setItem("RmToken", response?.data?.result?.token);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <AppBarBack
        title="Relation Management"
        noSettings
        onClick={() => {
          navigate("/dashboard/home");
        }}
      />

      {loading && !rmToken && (
        <Box
          style={{
            height: "calc(100vh - 72px)",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && !rmToken && (
        <Box
          style={{
            height: "calc(100vh - 72px)",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1">
              <b>Something went wrong !!</b>
            </Typography>
            <Typography variant="subtitle2">- Access Denied, or, </Typography>
            <Typography variant="subtitle2">- Access Expired</Typography>
          </Box>
        </Box>
      )}

      {rmToken && (
        <iframe
          style={{
            height: "calc(100vh - 72px)",
            width: "100vw",
            border: "none",
          }}
          src={`https://orchirds-parents-sdk.web.app/432E-844-A194/inbox?token=${rmToken}`}
        />
      )}
    </>
  );
}

export default RelationManager;
