import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

// MUI Stuff
import {
  Box,
  Typography,
  Link,
  Container,
  Tabs,
  Tab,
  Avatar,
} from "@mui/material";

// Icons
import Logo from "assets/img/logo.png";

//Redux
import { connect } from "react-redux";
import { loginUser } from "redux/actions/userActions";
import { loginWithOtp } from "redux/actions/userActions";
import { loginWithToken } from "redux/actions/userActions";
import { loginWithMobileNo } from "redux/actions/userActions";

//Files
import SimpleSnackbar from "components/Snackbar";
import Placeholder from "assets/img/placeholder-img.jpg";

import axiosInstance from "util/axiosInstance";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import LoginWithOtp from "./LoginWithOtp";
import ForgotPassword from "./ForgotPassword";
import SelectProfileDialog from "./SelectProfileDialog";
import LoginWithErpAndPass from "./LoginWithErpAndPass";
import LoginWithMobileNo from "./LoginWithMobileNo";
import {
  LOADING_UI,
  SET_SCHOOL_DETAILS,
  SET_USER_CONTACT,
  SET_USER_LIST,
  STOP_LOADING_UI,
} from "redux/types";
import store from "redux/store";
import axios from "axios";
import { API_CENTRAL, isB2b } from "apiConfig";
import theme from "util/theme";
import AppBarBack from "components/AppBarBack";
import LoginWithERP from "./LoginWithERP";
import { useSearchParamsState } from "hooks/useSearchParamsState";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
      {"."}
    </Typography>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Login(props) {
  const { setAlert } = React.useContext(AlertNotificationContext);
  const [shoolDetails, setShoolDetails] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = useState("warning");

  const [erp, setErp] = useState("");

  const [forgotPassFlag, setForgotPassFlag] = useState(false);

  const [OtpSentofOTP, setOtpSentofOTP] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [sendOtpBtn, setSendOtpBtn] = useState(false);

  const [OtpSentofMobile, setOtpSentofMobile] = useState(false);
  const [secondsOfMobile, setSecondsofMobile] = useState(null);
  const [sendOtpBtnOfMobile, setSendOtpBtnOfMobile] = useState(false);

  const [showProfilesDialog, setShowProfilesDialog] = useState(false);
  const [profilesDataDialog, setProfilesDataDialog] = useState([]);

  const [searchParams] = useSearchParams();

  const [tabParam, setTabParam] = useSearchParamsState(
    "tab",
    Number(searchParams.get("tab"))
  );
  const [value, setValue] = React.useState(Number(tabParam) ?? 0); // tab value

  useEffect(() => {
    setTabParam(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    fetchSchoolDetails();
  }, []);

  const fetchSchoolDetails = () => {
    let splitedUrlAddress = window.location.origin.split(".");
    let subDomain = splitedUrlAddress[0]?.split("//")[1];
    if (subDomain?.includes("localhost")) {
      subDomain = "qa";
    }
    axios
      .get(
        `${API_CENTRAL}/central-admin/school_details/?sub_domain=${subDomain}`,
        {
          headers: {
            "x-api-key": "vikash@12345#1231",
          },
        }
      )
      .then((res) => {
        setShoolDetails(res?.data);
        store.dispatch({
          type: SET_SCHOOL_DETAILS,
          payload: res?.data,
        });
      });
  };

  const handleOTP = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      erp_id: data?.get("erp"),
    };
    if (!OtpSentofOTP) {
      store.dispatch({ type: LOADING_UI });
      axiosInstance
        .post(`erp_user/login-otp/`, { erp_id: data.get("erp") })
        .then((res) => {
          if (res.data.status_code === 200) {
            setOtpSentofOTP(true);
            setSeconds(res?.data?.expiration_in_sec);
            setSendOtpBtn(true);
            store.dispatch({ type: STOP_LOADING_UI });
            setAlert("success", res?.data?.message);
          } else {
            setAlert("error", res?.data?.message);
          }
        });
    } else {
      const data = new FormData(event.currentTarget);
      const userData = {
        erp_id: data.get("erp"),
        otp: data.get("otp"),
      };
      props.loginWithOtp(userData, navigate, setOpenSnackbar, setSeverity);
    }
  };

  const handleMobile = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!OtpSentofMobile) {
      store.dispatch({ type: LOADING_UI });
      axiosInstance
        .post(`erp_user/v2/mobile-login/`, {
          contact: `+91-${data.get("contact")}`,
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            setOtpSentofMobile(true);
            setSecondsofMobile(res?.data?.expiration_in_sec);
            setSendOtpBtnOfMobile(true);
            store.dispatch({ type: STOP_LOADING_UI });
            setAlert("success", res?.data?.message);
          } else {
            setAlert("error", res?.data?.message);
          }
        });
    } else {
      const userData = {
        contact: `+91-${data.get("contact")}`,
        otp: data.get("otp"),
      };
      mobileVerifyOtpFunction(userData);
    }
  };

  const mobileVerifyOtpFunction = (userData) => {
    axiosInstance
      .post(`erp_user/v2/mobile-verify-otp/`, userData)
      .then((res) => {
        if (res.data.status_code === 200) {
          setShowProfilesDialog(true);
          navigate("/select-profile");
          store.dispatch({
            type: SET_USER_LIST,
            payload: res.data.data,
          });
          store.dispatch({
            type: SET_USER_CONTACT,
            payload: userData?.contact,
          });
          setAlert("success", res?.data?.message);

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          setAlert("error", res?.data?.message);
        }
      });
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      username: data.get("erp"),
    };
    store.dispatch({ type: LOADING_UI });
    axiosInstance
      .get(`erp_user/forgot-password/?erp_id=${userData?.username}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          setAlert("success", res?.data?.message);
          setForgotPassFlag(false);
          store.dispatch({ type: STOP_LOADING_UI });
        } else {
          setAlert("error", res?.data?.message);
        }
      });
  };

  useEffect(() => {
    var erpId = searchParams.get("erp_id");
    var password = searchParams.get("password");

    let contact = `+91-${searchParams.get("contact")}`;
    let otp = searchParams.get("otp");
    let token = searchParams.get("token");

    if (erpId && password) {
      const userData = {
        username: erpId,
        password: password,
      };
      props.loginUser(userData, navigate, setOpenSnackbar, setSeverity);
    } else if (erpId && otp) {
      const userData = {
        erp_id: erpId,
        otp: otp,
      };
      props.loginWithOtp(userData, navigate, setOpenSnackbar, setSeverity);
    } else if (contact && otp) {
      const userData = {
        contact: contact,
        otp: otp,
      };
      mobileVerifyOtpFunction(userData);
    } else if (token) {
      const userData = {
        token: token,
      };
      props.loginWithToken(userData, navigate, setOpenSnackbar, setSeverity);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTabParam(newValue);
  };

  const floatStyle = {
    margin: 0,
    width: "100%",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    right: 0,
    // position: "fixed",
    p: 2,
    zIndex: 1000,
  };

  const timerRef = useRef(null);

  useEffect(() => {
    if (isB2b) {
      timerRef.current = setTimeout(() => {
        window?.$zoho?.salesiq?.floatbutton?.visible("show");
      }, 1000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        window?.$zoho?.salesiq?.floatbutton?.visible("hide");
      };
    }
  }, [window.$zoho]);

  return (
    <Container
      type="main"
      component="main"
      sx={{
        p: 0,
        height: "100vh",
      }}
    >
      <AppBarBack
        title="Login"
        onClick={() => navigate("/login-option")}
        noSettings
        logo
        noBackButton
      />
      <Box
        sx={{
          // marginTop: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {shoolDetails?.school_logo?.length > 0 ||
        shoolDetails?.school_name?.length > 0 ? (
          <Box
            className="color-gray-light card-border"
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mb: 3,
            }}
          >
            {shoolDetails?.school_logo?.length > 0 ? (
              <img
                width="42"
                height="48"
                src={shoolDetails?.school_logo}
                alt="school_logo"
                style={{ borderRadius: 4 }}
              />
            ) : (
              <img
                width="42"
                height="48"
                src={Placeholder}
                alt="school_logo"
                style={{ borderRadius: 4 }}
              />
            )}
            <Typography
              variant="subtitle2"
              textAlign="center"
              color={"text.secondary"}
              sx={{
                mb: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              <b>{shoolDetails?.school_name}</b>
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, bgcolor: "none", width: 48, height: 48 }}
              src={Logo}
            />
          </Box>
        )} */}
        {/* <Typography variant="h5" textAlign="left">
          <b>{forgotPassFlag ? "Forgot Password" : "Sign in"}</b>
        </Typography> */}
        {!forgotPassFlag ? (
          <>
            <Box
              sx={{
                width: "100%",
                alignItems: "center",

                backgroundColor: theme.palette.base.white,
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                variant="fullWidth"
                scrollButtons="auto"
              >
                <Tab label="Mobile Number" {...a11yProps(0)} />
                {/* <Tab label="ERP Number" {...a11yProps(1)} /> */}
                <Tab label="ERP Number" {...a11yProps(1)} />
                {/* <Tab label="OTP" {...a11yProps(1)} />
                <Tab label="Password" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TabPanel value={value} index={0}>
                <LoginWithMobileNo
                  handleMobile={handleMobile}
                  secondsOfMobile={secondsOfMobile}
                  setSecondsofMobile={setSecondsofMobile}
                  OtpSentofMobile={OtpSentofMobile}
                  setOtpSentofMobile={setOtpSentofMobile}
                  sendOtpBtnOfMobile={sendOtpBtnOfMobile}
                />
              </TabPanel>
              {/* <TabPanel value={tabValue} index={1}>
                <LoginWithOtp
                  erp={erp}
                  setErp={setErp}
                  seconds={seconds}
                  setSeconds={setSeconds}
                  handleOTP={handleOTP}
                  OtpSentofOTP={OtpSentofOTP}
                  setOtpSentofOTP={setOtpSentofOTP}
                  sendOtpBtn={sendOtpBtn}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <LoginWithErpAndPass
                  erp={erp}
                  setErp={setErp}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  setForgotPassFlag={setForgotPassFlag}
                  handleSubmit={handleSubmit}
                />
              </TabPanel> */}
              <TabPanel value={value} index={1}>
                <LoginWithERP
                  // handleSubmit={handleSubmit}
                  setOtpSentofOTP={setOtpSentofOTP}
                  OtpSentofOTP={OtpSentofOTP}
                  handleOTP={handleOTP}
                />
              </TabPanel>
            </Box>
          </>
        ) : (
          <ForgotPassword
            erp={erp}
            setErp={setErp}
            handleForgotPassword={handleForgotPassword}
            setForgotPassFlag={setForgotPassFlag}
          />
        )}
      </Box>
      <SelectProfileDialog
        showProfilesDialog={showProfilesDialog}
        setShowProfilesDialog={setShowProfilesDialog}
        profilesDataDialog={profilesDataDialog}
      />
      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  loginWithOtp,
  loginWithToken,
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
