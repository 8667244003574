import React, { useEffect, useState } from "react";

import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { connect } from "react-redux";
import { API_REPORT, X_DTS_HOST } from "../../apiConfig";
import axiosInstance from "../../util/axiosInstance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import theme from "../../util/theme";

function Calendar(props) {
  const navigate = useNavigate();

  const [holiday, setHoliday] = useState([]);
  const [holidayLoading, setHolidayLoading] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);

  const [event, setEvent] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);
  const [eventCount, setEventCount] = useState(0);
  useEffect(() => {
    if (props?.current_acad_year?.id) {
      fetchHoliday();
      // fetchEvent();
    }
  }, [props?.current_acad_year, props?.sessionYear?.id, props?.update]);

  const fetchHoliday = () => {
    setHolidayLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/v1/holiday/?start_date=${moment()
          .startOf("month")
          .format("YYYY-MM-DD")}&end_date=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")}&session_year=${
          props?.branchDetail?.id
        }&grade=${props?.gradeDetail?.gradeId}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setHoliday(res?.data?.holiday_detail);
          calculateHolidayCount(res?.data?.holiday_detail);
        }
      })
      .catch((err) => {
        console.log("Holiday fetch error", err);
      })
      .finally(() => {
        setHolidayLoading(false);
      });
  };

  const fetchEvent = () => {
    setEventLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/v1/events/?start_date=${moment()
          .startOf("month")
          .format("YYYY-MM-DD")}&end_date=${moment()
          .endOf("month")
          .format("YYYY-MM-DD")}&session_year=${
          props?.branchDetail?.id
        }&grade=${props?.gradeDetail?.gradeId}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setEvent(res?.data?.events_data);
          calculateEventCount(res?.data?.events_data);
        }
      })
      .catch((err) => {
        console.log("Holiday fetch error", err);
      })
      .finally(() => {
        setEventLoading(false);
      });
  };

  const calculateHolidayCount = (holiday) => {
    let count = 0;
    holiday?.map((each) => {
      count =
        count +
        1 +
        moment(
          moment(each?.holiday_end_date).format("DD.MM.YYYY"),
          "DD.MM.YYYY"
        ).diff(
          moment(
            moment(each?.holiday_start_date).format("DD.MM.YYYY"),
            "DD.MM.YYYY"
          ),
          "days"
        );
    });
    setHolidayCount(count);
  };

  const calculateEventCount = (event) => {
    let count = 0;
    event?.map((each) => {
      count =
        count +
        1 +
        moment(moment(each?.end_time).format("DD.MM.YYYY"), "DD.MM.YYYY").diff(
          moment(moment(each?.start_time).format("DD.MM.YYYY"), "DD.MM.YYYY"),
          "days"
        );
    });
    setEventCount(count);
  };

  return (
    <Box
      className="bg-white"
      sx={{
        p: 2,
        flex: 1,
        // mx: 1,
        // width: "100%",
        // maxWidth: "400px",
        // height: "100%",
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        // justifyContent: "space-between",
        lineHeight: 0,
        backgroundColor: `rgba(0,0,0,0,0.5)`,
        boxShadow: "0px 3px 0px 0px #D1E0FF",
        border: `1px solid ${theme.palette?.blueDark?.light4}`,
      }}
      onClick={() => navigate("/calendar")}
    >
      <Box display="flex">
        <Avatar
          sx={{
            bgcolor: theme.palette?.blueDark.light5,
            width: 56,
            height: 56,
            borderRadius: "12px",
          }}
          variant="rounded"
        >
          <CalendarTodayOutlinedIcon
            sx={{ fontSize: 32, color: theme.palette?.blueDark.main }}
          />
        </Avatar>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // jus,
          gap: 0.1,
        }}
      >
        <Typography
          variant="xs"
          type="regular"
          color={theme.palette.gray.dark1}
        >
          {moment().format("MMMM YYYY")}
        </Typography>
        {holidayLoading ? (
          <Skeleton width={150} height={30} />
        ) : (holiday?.length > 0 || event?.length > 0) &&
          props?.sessionYear?.id === props?.current_acad_year?.id ? (
          <Typography variant="sm" type="medium">
            <span className="color-black">{holidayCount} Holidays</span>
            {/* &{" "}
            <span className="color-black">{eventCount} Events</span> */}
          </Typography>
        ) : (
          <Typography variant="sm" type="medium">
            No Holidays in this Month
            {/* (
                {moment().format("MMM")}
              {moment().format("YYYY")}) */}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,
  sessionYear: state.user?.branch?.session_year,
  branchDetail: state.user?.branch,
  gradeDetail: state.user,
});

export default connect(mapStateToProps)(Calendar);
