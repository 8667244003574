import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { API_REPORT, X_DTS_HOST } from "../../apiConfig";
import axiosInstance from "../../util/axiosInstance";

import { Box, Divider, Skeleton, Typography } from "@mui/material";
import DiaryImg from "../../assets/img/student_dashboard_diary.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "util/theme";

function TodayDiary(props) {
  const navigate = useNavigate();
  const [diaryHwCount, setDiaryHwCount] = useState([]);
  const [diaryHwCountLoading, setDiaryHwCountLoading] = useState(false);

  useEffect(() => {
    if (props?.current_acad_year?.id) {
      fetchDiaryHwCount();
    }
  }, [props?.current_acad_year?.id]);

  const fetchDiaryHwCount = () => {
    setDiaryHwCountLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/diary_homework_count/?session_id=${props?.current_acad_year?.id}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setDiaryHwCount(res?.data?.result);
        }
      })
      .catch((err) => {
        console.log("DiaryHwCount fetch error", err);
      })
      .finally(() => {
        setDiaryHwCountLoading(false);
      });
  };
  return (
    <Box
      className="color-white-bg "
      sx={{
        borderRadius: 4,
        my: 2,
        width: "100%",
        border: `1px solid ${theme.palette.gray.light3}`,
        boxShadow: theme.shadows[1],
      }}
      display="flex"
      justifyContent="space-between"
      onClick={() => navigate("/diary")}
    >
      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        sx={{ gap: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1.5,
          }}
        >
          <img src={DiaryImg} alt="DiaryImg" width="56px" />
        </Box>
        <Box sx={{ py: 1 }}>
          <Box>
            <Typography
              variant="md"
              type="medium"
              color={theme.palette.gray.dark3}
            >
              Today's diary
            </Typography>
          </Box>

          {diaryHwCountLoading ? (
            <Skeleton width={200} />
          ) : diaryHwCount?.diary_count > 0 ? (
            <Box>
              <Box>
                <Typography
                  variant="sm"
                  type="regular"
                  color={theme.palette.gray.main}
                >
                  {diaryHwCount?.diary_count}{" "}
                  {diaryHwCount?.diary_count == 1 ? "Diary " : "Diaries "}•{" "}
                  {diaryHwCount?.homework_count?.homeworks}{" "}
                  {diaryHwCount?.homework_count?.homeworks == 1
                    ? "Homework"
                    : "Homeworks"}{" "}
                </Typography>
              </Box>{" "}
            </Box>
          ) : (
            <Typography
              variant="sm"
              type="regular"
              color={theme.palette.gray.main}
            >
              No diaries assigned today
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{ p: 1, pr: 2 }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ArrowForwardIosIcon
          sx={{ fontSize: 24, color: theme.palette.blueDark.dark1 }}
        />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,

  branchDetail: state.user?.branch,
});

export default connect(mapStateToProps)(TodayDiary);
