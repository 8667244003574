import { isB2b, platform } from "apiConfig";
import mixpanel from "mixpanel-browser";

// const isTrackerChecker = window.location.hostname === "orchids.letseduvate.com";
const isTrackerChecker = !isB2b;

export const mixpanelTracker = (tracker, params = {}) => {
  const userDetails = JSON.parse(
    localStorage.getItem("user_details") &&
      localStorage.getItem("user_details") != "undefined"
      ? localStorage.getItem("user_details")
      : "{}"
  );
  const branch = JSON.parse(
    localStorage.getItem("branch") &&
      localStorage.getItem("branch") != "undefined"
      ? localStorage.getItem("branch")
      : "{}"
  );
  const branchName = branch?.branch?.branch_name || "branch name";
  if (isTrackerChecker) {
    const { user_level, user_id, erp, email, first_name } = userDetails || {};

    const distinctID = erp || mixpanel.get_distinct_id(); // Use ERP or fallback to existing ID

    // Ensure identify is called if ERP is available

    if (
      tracker == "mobile_login" &&
      params?.status === "success" &&
      params?.erp
    ) {
      mixpanel.identify(params?.erp);
      mixpanel.people.set({ name: first_name, $email: email, user_level, erp });
    } else if (distinctID) {
      mixpanel.identify(distinctID);
      mixpanel.people.set({ name: first_name, $email: email, user_level, erp });
    }

    mixpanel.track(tracker, {
      user_level,
      erp,
      email,
      first_name,
      platform: platform || "mb_web",
      branch_name: branchName,
      ...params,
    });
    if (tracker == "mobile_logout" && params?.status === "success") {
      mixpanel.reset();
    }
  }
};
