import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import DiaryCardSkeleton from "./DiaryCardSkeleton";
import DiaryCard from "./DiaryCard";
import NoData from "../../../components/NoData";
import moment from "moment";
import axiosInstance from "../../../util/axiosInstance";
import { connect } from "react-redux";

function DailyDiary(props) {
  const {
    setAlert,
    setShowDrawer,
    setDrawerData,
    startDate,
    endDate,
    filtered,
  } = props;

  const [diaryData, setDiaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moduleId, setModuleId] = useState("");

  // pagination
  const [page, setPage] = useState(1);
  const pageSize = 8;
  const [totalCount, setTotalCount] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = (pageNo = page) => {
    if (pageNo != 1 && diaryData.length >= totalCount) {
      setHasMore(false);
      return;
    }

    const academicYear = props?.academicYearId;
    const pageParam = `&page=${pageNo}`;
    const sessionYearParam = `&session_year=${academicYear}`;

    axiosInstance
      .get(
        `/academic/general-dairy-messages/?module_id=${moduleId}${pageParam}&page_size=${pageSize}&end_date=${endDate}&start_date=${startDate}&dairy_type=2${sessionYearParam}`
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          setTotalCount(res?.data?.result?.count);
          if (pageNo == 1) {
            setDiaryData(res?.data?.result?.results);
          } else {
            setDiaryData([...diaryData, ...res?.data?.result?.results]);
            setPage(page + 1);
          }
        } else {
          setAlert("error", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", err?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (moduleId) {
      fetchMoreData(1);
      setPage(1);
      setHasMore(true);
    }
  }, [moduleId, filtered]);

  useEffect(() => {
    if (props.userNavigationData && props.userNavigationData.length) {
      props.userNavigationData.forEach((item) => {
        if (
          item.parent_modules === "Diary" &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === "Student Diary") {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, [moduleId]);

  return (
    <Box>
      {isLoading ? (
        <Stack>
          <DiaryCardSkeleton />
          <DiaryCardSkeleton />
          <DiaryCardSkeleton />
        </Stack>
      ) : (
        <Box>
          {diaryData.length > 0 ? (
            <InfiniteScroll
              dataLength={diaryData.length}
              next={() => {
                fetchMoreData(page + 1);
              }}
              hasMore={hasMore}
              loader={<DiaryCardSkeleton />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {diaryData.map((diary, index) => (
                <DiaryCard
                  key={diary.id}
                  subjectName={diary?.subject?.subject_name}
                  createdBy={diary?.created_by?.first_name}
                  createdAt={diary?.created_at}
                  files={diary?.documents?.length}
                  diaryData={diary}
                  setShowDrawer={setShowDrawer}
                  setDrawerData={setDrawerData}
                  isLoading={isLoading}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <NoData />
          )}
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  moduleId: state.user.moduleId,
  userNavigationData: state.user.userNavigationData,
  academicYearId: state.user?.branch?.session_year?.id,
});

export default connect(mapStateToProps)(DailyDiary);
