import { connect } from "react-redux";

// MUI Stuff
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";

//Files
import theme from "util/theme";

function ForgotPassword(props) {
  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 3,
        // border: "1px solid green",
      }}
    >
      <Box
        // component="form"
        // onSubmit={props?.handleForgotPassword}
        sx={
          {
            // border: "1px solid red",
          }
        }
      >
        <Box
          sx={{
            my: 0.5,
          }}
        >
          <Typography variant="sm" color={theme.palette.gray.dark2}>
            ERP number
          </Typography>
        </Box>
        <TextField
          required
          fullWidth
          type="text"
          id="erp"
          name="erp"
          value={props?.erpId}
          onChange={(e) => props?.setErpId(e.target.value)}
          autoComplete="erp"
          autoFocus
          InputProps={{
            style: { backgroundColor: theme.palette.base.white },
          }}
        />
      </Box>
      <Box sx={{}}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          size="large"
          disabled={props.UI.loading}
        >
          <Typography
            variant="sm"
            type="semibold"
            onClick={() => props?.handleForgotPassword()}
          >
            Submit
          </Typography>
          {props.UI.loading && <CircularProgress size={20} sx={{ px: 2 }} />}
        </Button>
        <Typography
          variant="sm"
          type="semibold"
          color={theme.palette.blueDark?.dark2}
          onClick={() => {
            props.setForgotPassFlag(false);
          }}
        >
          Sign In
        </Typography>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(ForgotPassword);
