import React from "react";
import NoDataImg from "../assets/img/no_data.svg";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function NoData({ text = "No Data Found!", subText }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "75vh",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box component="img" src={NoDataImg} sx={{ width: "0.4", m: 1 }}></Box>
      <Box>
        <Typography sx={{ textAlign: "center" }}>
          <b>{text}</b>
        </Typography>
        <Typography variant="body2" sx={{ mt: "1", textAlign: "center" }}>
          {subText}
        </Typography>
      </Box>
    </Box>
  );
}

export default NoData;
