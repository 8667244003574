import {
  SET_USER,
  SET_USER_NAVIGATION_DATA,
  SET_USER_APPS_DATA,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  STOP_LOADING_UI,
  SET_MODULE_ID,
  SET_ACAD_YEAR_LIST,
  SET_CURRENT_ACADEMIC_YEAR,
  SET_BRANCH,
  SET_BRANCH_LIST,
  SET_USER_LIST,
} from "../types";
import store from "../store";
import axiosInstance from "../../util/axiosInstance";
import { API_MASTER } from "apiConfig";
import axios from "axios";

//Import Mixpanel Tracker function
import { mixpanelTracker } from "util/MixpanelTracking/Tracker";

export const loginUser =
  (userData, navigate, setOpenSnackbar, setSeverity) => async (dispatch) => {
    dispatch({ type: LOADING_UI });
    await axiosInstance
      .post("/erp_user/login/", userData)
      .then((res) => {
        if (res.data?.status_code == 200) {
          setAuthorizationHeader(res.data?.result?.user_details?.token);

          // mixpanel
          // mixpanel.track("user_login_erpPass", {
          //   status: "success",
          //   email: res.data?.result?.user_details?.email,
          //   first_name: res.data?.result?.user_details?.first_name,
          //   user_level: res.data?.result?.user_details?.user_level,
          //   erp: res.data?.result?.user_details?.erp,
          //   platform: localStorage.getItem("platform")
          //     ? localStorage.getItem("platform")
          //     : "mb_web",
          // });

          mixpanelTracker("mobile_login", {
            status: "success",
            login_type: "password",
            email: res.data?.result?.user_details?.email,
            first_name: res.data?.result?.user_details?.first_name,
            user_level: res.data?.result?.user_details?.user_level,
            erp: res.data?.result?.user_details?.erp,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
          });

          setDefaultAcadYearAndBranch();

          localStorage.setItem(
            "user_details",
            JSON.stringify(res.data?.result?.user_details)
          );
          localStorage.setItem(
            "user_navigation_data",
            JSON.stringify(res.data?.result?.navigation_data)
          );
          localStorage.setItem(
            "user_apps_data",
            JSON.stringify(res.data?.result?.apps)
          );

          if (res.data?.result?.siblings_data) {
            store.dispatch({
              type: SET_USER_LIST,
              payload: res.data?.result?.siblings_data,
            });
          }
          // dispatch(getUserData());
          dispatch({ type: CLEAR_ERRORS });
          dispatch({
            type: SET_USER,
            payload: res.data?.result?.user_details,
          });

          dispatch({
            type: SET_USER_NAVIGATION_DATA,
            payload: res.data?.result?.navigation_data,
          });

          dispatch({
            type: SET_USER_APPS_DATA,
            payload: res.data?.result?.apps,
          });

          dispatch({
            type: SET_MODULE_ID,
            payload: res.data?.result?.navigation_data
              ?.find((o) => o.parent_modules === "Ebook")
              ?.child_module.find((o) => o.child_name === "Ebook View")
              ?.child_id,
          });

          setOpenSnackbar(true);
          setSeverity("success");
          navigate("/dashboard/home");
          // TODO: navigate back to the previous url
          // navigate(-1);
        } else {
          // console.log(userData);
          // mixpanel
          // mixpanel.track("user_login_erpPass", {
          //   status: "failed",
          //   erp: userData?.username,
          //   platform: localStorage.getItem("platform")
          //     ? localStorage.getItem("platform")
          //     : "mb_web",
          //   error: res.data?.message,
          // });

          mixpanelTracker("mobile_login", {
            status: "failed",
            login_type: "password",
            erp: userData?.username,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
            error: res.data?.message,
          });

          dispatch({
            type: SET_ERRORS,
            payload: res.data?.message,
          });
          dispatch({ type: STOP_LOADING_UI });

          setOpenSnackbar(true);
          setSeverity("error");
        }

        dispatch({ type: STOP_LOADING_UI });
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setSeverity("error");
        console.log("login catch err", err);
        if (err.response?.status === 500) {
          dispatch({
            type: SET_ERRORS,
            payload: "Something went wrong. Please try again later.",
          });
          dispatch({ type: STOP_LOADING_UI });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err?.response?.data,
          });
          dispatch({ type: STOP_LOADING_UI });
        }
        dispatch({ type: STOP_LOADING_UI });

        mixpanelTracker("mobile_login", {
          status: "failed",
          login_type: "password",
          erp: userData?.username,
          platform: localStorage.getItem("platform")
            ? localStorage.getItem("platform")
            : "mb_web",
          // error: res.data?.message,
        });
      });

    const platform = localStorage.getItem("platform");

    try {
      window.flutter_inappwebview.callHandler("appLoadedHandler", {});
    } catch (err) {
      console.log(err);
    }
  };

export const loginWithOtp =
  (userData, navigate, setOpenSnackbar, setSeverity) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axiosInstance
      .post("erp_user/verify-otp/", userData)
      .then((res) => {
        if (res.data?.status_code == 200) {
          setAuthorizationHeader(
            res.data?.login_response?.result?.user_details?.token
          );

          setDefaultAcadYearAndBranch();

          localStorage.setItem(
            "user_details",
            JSON.stringify(res.data?.login_response?.result?.user_details)
          );
          localStorage.setItem(
            "user_navigation_data",
            JSON.stringify(res.data?.login_response?.result?.navigation_data)
          );
          localStorage.setItem(
            "user_apps_data",
            JSON.stringify(res.data?.login_response?.result?.apps)
          );

          dispatch({ type: CLEAR_ERRORS });
          dispatch({
            type: SET_USER,
            payload: res.data?.login_response?.result?.user_details,
          });

          dispatch({
            type: SET_USER_NAVIGATION_DATA,
            payload: res.data?.login_response?.result?.navigation_data,
          });

          dispatch({
            type: SET_USER_APPS_DATA,
            payload: res.data?.login_response?.result?.apps,
          });

          if (res.data?.login_response?.result?.siblings_data?.length > 0) {
            dispatch({
              type: SET_USER_LIST,
              payload: res.data?.login_response?.result?.siblings_data,
            });
          }

          dispatch({
            type: SET_MODULE_ID,
            payload: res.data?.login_response?.result?.navigation_data
              ?.find((o) => o.parent_modules === "Ebook")
              ?.child_module.find((o) => o.child_name === "Ebook View")
              ?.child_id,
          });

          setOpenSnackbar(true);
          setSeverity("success");
          navigate("/dashboard/home");
          // TODO: navigate back to the previous url
          // navigate(-1);

          const platform = localStorage.getItem("platform");

          mixpanelTracker("mobile_login", {
            status: "success",
            login_type: "otp",
            email: res.data?.login_response?.result?.user_details?.email,
            first_name:
              res.data?.login_response?.result?.user_details?.first_name,
            user_level:
              res.data?.login_response?.result?.user_details?.user_level,
            erp: res.data?.login_response?.result?.user_details?.erp,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
          });

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: res.data?.message,
          });
          dispatch({ type: STOP_LOADING_UI });

          setOpenSnackbar(true);
          setSeverity("error");

          mixpanelTracker("mobile_login", {
            status: "failed",
            login_type: "otp",
            erp: userData?.erp_id,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
            error: res.data?.message,
          });
        }

        dispatch({ type: STOP_LOADING_UI });
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setSeverity("error");
        console.log("login catch err", err);
        if (err.response?.status === 500) {
          dispatch({
            type: SET_ERRORS,
            payload: "Something went wrong. Please try again later.",
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err?.response?.data,
          });
        }
        dispatch({ type: STOP_LOADING_UI });

        mixpanelTracker("mobile_login", {
          status: "failed",
          login_type: "otp",
          erp: userData?.erp_id,
          platform: localStorage.getItem("platform")
            ? localStorage.getItem("platform")
            : "mb_web",
          error: err?.response?.data,
        });
      });
  };

export const loginWithToken =
  (userData, navigate, setOpenSnackbar, setSeverity) => async (dispatch) => {
    dispatch({ type: LOADING_UI });
    await axios({
      method: "post",
      url: `${API_MASTER}/erp_user/finance-login/`,
      headers: { Authorization: `Bearer ${userData?.token}` },
      data: { finance_login: true },
    })
      .then((res) => {
        if (res.data?.status_code == 200) {
          setAuthorizationHeader(res.data?.result?.user_details?.token);

          setDefaultAcadYearAndBranch();

          localStorage.setItem(
            "user_details",
            JSON.stringify(res.data?.result?.user_details)
          );
          localStorage.setItem(
            "user_navigation_data",
            JSON.stringify(res.data?.result?.navigation_data)
          );

          if (res?.data?.result?.apps) {
            localStorage.setItem(
              "user_apps_data",
              JSON.stringify(res.data?.result?.apps)
            );
          }

          dispatch({ type: CLEAR_ERRORS });
          dispatch({
            type: SET_USER,
            payload: res.data?.result?.user_details,
          });

          dispatch({
            type: SET_USER_NAVIGATION_DATA,
            payload: res.data?.result?.navigation_data,
          });

          if (res.data?.result?.apps) {
            dispatch({
              type: SET_USER_APPS_DATA,
              payload: res.data?.result?.apps,
            });
          }

          if (res.data?.result?.siblings_data) {
            dispatch({
              type: SET_USER_LIST,
              payload: res.data?.result?.siblings_data,
            });
          }

          dispatch({
            type: SET_MODULE_ID,
            payload: res.data?.result?.navigation_data
              ?.find((o) => o.parent_modules === "Ebook")
              ?.child_module.find((o) => o.child_name === "Ebook View")
              ?.child_id,
          });

          setOpenSnackbar(true);
          setSeverity("success");
          navigate("/dashboard/home");
          const platform = localStorage.getItem("platform");

          mixpanelTracker("mobile_login", {
            status: "success",
            login_type: "token",
            email: res.data?.result?.user_details?.email,
            first_name: res.data?.result?.user_details?.first_name,
            user_level: res.data?.result?.user_details?.user_level,
            erp: res.data?.result?.user_details?.erp,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
          });

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: res.data?.message,
          });
          dispatch({ type: STOP_LOADING_UI });

          setOpenSnackbar(true);
          setSeverity("error");

          mixpanelTracker("mobile_login", {
            status: "failed",
            login_type: "token",
            token: userData?.token,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
            error: res.data?.message,
          });
        }

        dispatch({ type: STOP_LOADING_UI });
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setSeverity("error");
        console.log("login catch err", err);
        if (err.response?.status === 500) {
          dispatch({
            type: SET_ERRORS,
            payload: "Something went wrong. Please try again later.",
          });
          dispatch({ type: STOP_LOADING_UI });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err?.response?.data?.toString(),
          });
          dispatch({ type: STOP_LOADING_UI });
        }
        dispatch({ type: STOP_LOADING_UI });
        mixpanelTracker("mobile_login", {
          status: "failed",
          login_type: "token",
          token: userData?.token,
          platform: localStorage.getItem("platform")
            ? localStorage.getItem("platform")
            : "mb_web",
          error: err?.response?.data,
        });
      });
  };

export const loginWithMobileNo =
  (userData, navigate, setOpenSnackbar, setSeverity) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axiosInstance
      .post("erp_user/erp-contact-login/", userData)
      .then((res) => {
        if (res.data?.status_code == 200) {
          setAuthorizationHeader(
            res.data?.login_response?.result?.user_details?.token
          );

          setDefaultAcadYearAndBranch();

          localStorage.setItem(
            "user_details",
            JSON.stringify(res.data?.login_response?.result?.user_details)
          );
          localStorage.setItem(
            "user_navigation_data",
            JSON.stringify(res.data?.login_response?.result?.navigation_data)
          );
          localStorage.setItem(
            "user_apps_data",
            JSON.stringify(res.data?.login_response?.result?.apps)
          );

          // dispatch(getUserData());
          dispatch({ type: CLEAR_ERRORS });
          dispatch({
            type: SET_USER,
            payload: res.data?.login_response?.result?.user_details,
          });

          dispatch({
            type: SET_USER_NAVIGATION_DATA,
            payload: res.data?.login_response?.result?.navigation_data,
          });

          dispatch({
            type: SET_USER_APPS_DATA,
            payload: res.data?.login_response?.result?.apps,
          });

          dispatch({
            type: SET_MODULE_ID,
            payload: res.data?.login_response?.result?.navigation_data
              ?.find((o) => o.parent_modules === "Ebook")
              ?.child_module.find((o) => o.child_name === "Ebook View")
              ?.child_id,
          });

          setOpenSnackbar(true);
          setSeverity("success");
          navigate("/dashboard/home");
          // TODO: navigate back to the previous url
          // navigate(-1);

          const platform = localStorage.getItem("platform");

          mixpanelTracker("mobile_login", {
            status: "success",
            login_type: "contact/hmac",
            email: res.data?.login_response?.result?.user_details?.email,
            first_name:
              res.data?.login_response?.result?.user_details?.first_name,
            user_level:
              res.data?.login_response?.result?.user_details?.user_level,
            erp: res.data?.login_response?.result?.user_details?.erp,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
          });

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: res.data?.message,
          });
          setOpenSnackbar(true);
          setSeverity("error");

          mixpanelTracker("mobile_login", {
            status: "failed",
            login_type: "contact/hmac",
            erp: userData?.erp_id,
            contact: userData?.contact,
            platform: localStorage.getItem("platform")
              ? localStorage.getItem("platform")
              : "mb_web",
            error: res.data?.message,
          });
        }

        dispatch({ type: STOP_LOADING_UI });
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setSeverity("error");
        console.log("login catch err", err);
        if (err.response?.status === 500) {
          dispatch({
            type: SET_ERRORS,
            payload: "Something went wrong. Please try again later.",
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err?.response?.data,
          });
        }
        dispatch({ type: STOP_LOADING_UI });
        mixpanelTracker("mobile_login", {
          status: "failed",
          login_type: "contact/hmac",
          erp: userData?.erp_id,
          contact: userData?.contact,
          platform: localStorage.getItem("platform")
            ? localStorage.getItem("platform")
            : "mb_web",
          error: err?.response?.data,
        });
      });
  };

export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axiosInstance
    .post("/signup", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = (navigate) => async (dispatch) => {
  const platform = localStorage.getItem("platform");

  mixpanelTracker("mobile_logout", {
    status: "success",
  });

  localStorage.clear();
  delete axiosInstance.defaults.headers.common["Authorization"];
  await dispatch({ type: SET_UNAUTHENTICATED });

  if (platform == "mb_droid" || platform == "mb_ios") {
    try {
      window.flutter_inappwebview.callHandler("logoutUserHandler", {});
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  } else {
    navigate("/login");
  }
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axiosInstance
    .get("/user")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axiosInstance
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axiosInstance
    .post("/user", userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axiosInstance
    .post("/notifications", notificationIds)
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ,
      });
    })
    .catch((err) => console.log(err));
};

const setAuthorizationHeader = (token) => {
  const authToken = `Bearer ${token}`;
  localStorage.setItem("authToken", authToken);
  axiosInstance.defaults.headers.common["Authorization"] = authToken;
};

export const setDefaultAcadYearAndBranch = () => {
  // default behaviour
  // set current acad year to the one with current:true
  // set the first branch as the current branch

  axiosInstance
    .get(`/erp_user/list-academic_year/`)
    .then((res) => {
      let currentAcademicYear = res.data?.data.find(
        (i) => i.is_current_session == true
      );

      store.dispatch({
        type: SET_ACAD_YEAR_LIST,
        payload: res.data?.data,
      });

      store.dispatch({
        type: SET_CURRENT_ACADEMIC_YEAR,
        payload: currentAcademicYear ?? res?.data?.data[0],
      });

      getAndSetDefaultBranch(currentAcademicYear?.id ?? res?.data?.data[0]?.id);

      localStorage.setItem(
        "acadYearList",
        JSON.stringify(
          res.data?.data.filter((i) => i.is_current_session == true)
        )
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAndSetDefaultBranch = (academicYearId, moduleId) => {
  // Give me the academic year id
  // I will set the first branch from the branch list for that acad year
  axiosInstance
    .get(
      `/erp_user/branch/?session_year=${academicYearId}&module_id=${moduleId}`
    )
    .then((res) => {
      if (res?.data?.status_code === 200) {
        store.dispatch({
          type: SET_BRANCH_LIST,
          payload: res?.data?.data?.results,
        });
        store.dispatch({
          type: SET_BRANCH,
          payload: res?.data?.data?.results[0],
        });
      } else {
        console.log("Error fetching branch list", res);
      }
    })
    .catch((err) => {
      console.log("Error fetching branch list", err);
    });
};
