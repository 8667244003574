import React, { useEffect, useState } from "react";

// MUI Stuff
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import theme from "util/theme";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
// import LoginWithERP from "./LoginWithERP";
import axiosInstance from "util/axiosInstance";

//Redux
import store from "redux/store";
import {
  LOADING_UI,
  SET_SCHOOL_DETAILS,
  SET_USER_CONTACT,
  SET_USER_LIST,
  STOP_LOADING_UI,
} from "redux/types";
import { connect } from "react-redux";
import { loginUser } from "redux/actions/userActions";
import { loginWithOtp } from "redux/actions/userActions";
import { loginWithMobileNo } from "redux/actions/userActions";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import { useNavigate } from "react-router-dom";
import SimpleSnackbar from "components/Snackbar";
import ForgotPassword from "./ForgotPassword";

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: theme.palette.blueDark.dark2,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.blueDark.dark2,
  },
});

// Inspired by blueprintjs
export function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        width: "24px",
        height: "24px",
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

function LoginWithERP(props) {
  const navigate = useNavigate();
  const { setAlert } = React.useContext(AlertNotificationContext);

  const textFieldStyle = {
    backgroundColor: theme.palette.base.white,
  };
  const [showPasswordArea, setShowPasswordArea] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassFlag, setForgotPassFlag] = useState(false);

  const [showOtpArea, setShowOtpArea] = useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = useState("warning");

  const [OtpSentofOTP, setOtpSentofOTP] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [sendOtpBtn, setSendOtpBtn] = useState(false);

  const [resent, setResent] = useState(false);

  useEffect(() => {
    let timer = 0;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      setSeconds(0);
      clearInterval(timer);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (resent) {
      handleOTP();
    }
  }, [resent]);

  const handleForgotPassword = () => {
    // event.preventDefault();
    // const data = new FormData(event.currentTarget);
    const userData = {
      username: erpId,
    };
    store.dispatch({ type: LOADING_UI });
    axiosInstance
      .get(`erp_user/forgot-password/?erp_id=${userData?.username}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          setAlert("success", res?.data?.message);
          setForgotPassFlag(false);
          store.dispatch({ type: STOP_LOADING_UI });
        } else {
          setAlert("error", res?.data?.message);
        }
      });
  };

  const handleOTP = () => {
    if (!OtpSentofOTP || resent) {
      store.dispatch({ type: LOADING_UI });
      axiosInstance
        .post(`erp_user/login-otp/`, { erp_id: erpId })
        .then((res) => {
          if (res.data.status_code === 200) {
            setOtpSentofOTP(true);
            setResent(false);
            setSeconds(res?.data?.expiration_in_sec);
            setSendOtpBtn(true);
            store.dispatch({ type: STOP_LOADING_UI });
            setAlert("success", res?.data?.message);
          } else {
            store.dispatch({ type: STOP_LOADING_UI });

            setAlert("error", res?.data?.message);
          }
        });
    } else {
      // const data = new FormData(event.currentTarget);
      // const userData = {
      //   erp_id: data.get("erp"),
      //   otp: data.get("otp"),
      // };
      const userData = {
        erp_id: erpId,
        otp: otp,
      };
      props?.loginWithOtp(userData, navigate, setOpenSnackbar, setSeverity);
    }
  };
  const handleErpPasswordLogin = () => {
    const userData = {
      username: erpId,
      password: password,
    };

    props?.loginUser(userData, navigate, setOpenSnackbar, setSeverity);
  };

  const [erpId, setErpId] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(null);

  return (
    <Box
      sx={{
        mt: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 3,
        height: "calc(100vh - 140px)",
      }}
    >
      {!forgotPassFlag ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            <Box>
              <Box sx={{ my: 0.5 }}>
                <Typography variant="sm" color={theme.palette.gray.dark2}>
                  ERP number
                </Typography>
              </Box>
              <TextField
                required
                fullWidth
                type="text"
                placeholder="Enter ERP Number"
                id="erp"
                value={erpId}
                onChange={(e) => setErpId(e.target.value)}
                name="erp"
                // autoFocus
                InputProps={{
                  style: { backgroundColor: theme.palette.base.white },
                }}
              />
            </Box>
            {showPasswordArea ? (
              <Box>
                <Box
                  sx={{
                    my: 0.5,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="sm" color={theme.palette.gray.dark2}>
                    Password
                  </Typography>
                  <Typography
                    variant="sm"
                    type="semibold"
                    color={theme.palette.blueDark?.dark2}
                    onClick={() => setForgotPassFlag(true)}
                  >
                    Forget password ?
                  </Typography>
                </Box>
                <TextField
                  required
                  fullWidth
                  name="password"
                  placeholder="Your Password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword((value) => !value);
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { backgroundColor: theme.palette.base.white },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    //  p: 0,
                    mt: 1,
                    // border: "1px solid red",
                  }}
                >
                  <BpCheckbox
                    sx={{
                      p: 0,
                    }}
                  />
                  <Typography variant="sm" color={theme.palette.gray.dark2}>
                    Remember me
                  </Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}

            {OtpSentofOTP && (
              <Box>
                <Box>
                  <Box
                    sx={{
                      my: 0.5,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="sm" color={theme.palette.gray.dark2}>
                      OTP
                    </Typography>

                    {sendOtpBtn &&
                      (seconds ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="sm"
                            color={theme.palette.gray.dark2}
                          >
                            <b>{seconds}</b> Sec Remaining
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          variant="outlined"
                          // sx={{ mt: 3, mb: 2 }}
                          // size="large"
                          type="number"
                          disabled={props.UI.loading}
                          onClick={(e) => {
                            // setOtpSentofOTP(false);
                            // handleOTP(e);
                            setResent(true);
                          }}
                        >
                          <Typography
                            variant="sm"
                            color={theme.palette.gray.dark2}
                          >
                            Resend OTP
                          </Typography>
                          {props.UI.loading && (
                            <CircularProgress size={20} sx={{ px: 2 }} />
                          )}
                        </Box>
                      ))}
                    {/* </Box> */}
                  </Box>

                  <TextField
                    required
                    fullWidth
                    placeholder="Your 6 digit OTP"
                    id="otp"
                    name="otp"
                    value={otp}
                    onChange={(e) => {
                      if (e.target.value.length > 6) {
                        return;
                      }
                      setOtp(e.target.value);
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Password color="primary" />
                        </InputAdornment>
                      ),
                      style: { backgroundColor: theme.palette.base.white },
                    }}
                    autoFocus
                  />
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      //  p: 0,
                      mt: 1,
                      // border: "1px solid red",
                    }}
                  >
                    <BpCheckbox
                      sx={{
                        p: 0,
                      }}
                    />
                    <Typography variant="sm" color={theme.palette.gray.dark2}>
                      Remember me
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            )}
          </Box>

          <Box sx={{ display: "grid", gap: 2 }}>
            {OtpSentofOTP || showPasswordArea ? (
              <Button
                fullWidth
                // type="submit"
                variant="contained"
                // disabled={error}
                onClick={(e) => {
                  if (showPasswordArea) {
                    if (erpId?.length === 0) {
                      setAlert("error", "Please enter ERP");
                      return;
                    }
                    if (password?.length === 0) {
                      setAlert("error", "Please enter Password");
                      return;
                    }
                    handleErpPasswordLogin(e);
                  }

                  if (showOtpArea) {
                    if (erpId?.length === 0) {
                      setAlert("error", "Please enter ERP");
                      return;
                    }
                    handleOTP(e);
                  }
                }}
                disabled={props.UI.loading}
              >
                <Typography variant="sm" type="semibold">
                  Login
                </Typography>
              </Button>
            ) : (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  // disabled={error}
                  onClick={() => {
                    setShowPasswordArea(true);
                    setShowOtpArea(false);
                  }}
                >
                  <Typography variant="sm" type="semibold">
                    Continue with Password
                  </Typography>
                </Button>
                <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  disabled={erpId?.length === 0}
                  onClick={(e) => {
                    if (erpId?.length > 0) {
                      setShowOtpArea(true);
                      setShowPasswordArea(false);
                      handleOTP(e);
                    }
                  }}
                >
                  <Typography variant="sm" type="semibold">
                    Continue with OTP
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        </>
      ) : (
        <ForgotPassword
          erpId={erpId}
          setErpId={setErpId}
          handleForgotPassword={handleForgotPassword}
          setForgotPassFlag={setForgotPassFlag}
        />
      )}
      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Box>

    // </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  loginWithOtp,
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(LoginWithERP);
