import React, { useEffect, useState } from "react";

// MUI Stuff
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";

//Redux

import { connect } from "react-redux";
import { loginUser } from "redux/actions/userActions";
import { loginWithOtp } from "redux/actions/userActions";
import { loginWithMobileNo } from "redux/actions/userActions";
import {
  LOADING_UI,
  SET_SCHOOL_DETAILS,
  SET_USER_CONTACT,
  SET_USER_LIST,
  STOP_LOADING_UI,
} from "redux/types";
import theme from "util/theme";
import { Password } from "@mui/icons-material";
import SimpleSnackbar from "components/Snackbar";
import { useNavigate } from "react-router-dom";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import store from "redux/store";
import axiosInstance from "util/axiosInstance";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: theme.palette.blueDark.dark2,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.blueDark.dark2,
  },
});

// Inspired by blueprintjs

function LoginWithMobileNo(props) {
  let navigate = useNavigate();
  const { setAlert } = React.useContext(AlertNotificationContext);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [severity, setSeverity] = useState("warning");

  const [contact, setContact] = useState(null);
  const [otp, setOtp] = useState(null);
  const [resent, setResent] = useState(false);

  const [OtpSentofMobile, setOtpSentofMobile] = useState(false);
  const [secondsOfMobile, setSecondsofMobile] = useState(null);
  const [sendOtpBtnOfMobile, setSendOtpBtnOfMobile] = useState(false);
  useEffect(() => {
    let timer = 0;
    if (secondsOfMobile > 0) {
      timer = setInterval(() => {
        setSecondsofMobile((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      setSecondsofMobile(0);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [secondsOfMobile]);

  useEffect(() => {
    if (resent) {
      handleMobile();
    }
  }, [resent]);

  const handleMobile = () => {
    if (!OtpSentofMobile || resent) {
      store.dispatch({ type: LOADING_UI });
      axiosInstance
        .post(`erp_user/v2/mobile-login/`, {
          contact: `+91-${contact}`,
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            setOtpSentofMobile(true);
            setResent(false);
            setSecondsofMobile(res?.data?.expiration_in_sec);
            setSendOtpBtnOfMobile(true);
            store.dispatch({ type: STOP_LOADING_UI });
            setAlert("success", res?.data?.message);
          } else {
            store.dispatch({ type: STOP_LOADING_UI });
            setAlert("error", res?.data?.message);
          }
        });
    } else {
      const userData = {
        contact: `+91-${contact}`,
        otp: otp,
      };
      mobileVerifyOtpFunction(userData);
    }
  };

  const mobileVerifyOtpFunction = (userData) => {
    axiosInstance
      .post(`erp_user/v2/mobile-verify-otp/`, userData)
      .then((res) => {
        if (res.data.status_code === 200) {
          // setShowProfilesDialog(true);
          navigate("/select-profile");
          store.dispatch({
            type: SET_USER_LIST,
            payload: res.data.data,
          });
          store.dispatch({
            type: SET_USER_CONTACT,
            payload: userData?.contact,
          });
          setAlert("success", res?.data?.message);

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          setAlert("error", res?.data?.message);
        }
      });
  };

  return (
    // <Container type="main" maxWidth="sm" sx={{ p: 0 }}>

    <Box
      // component="form"
      // onSubmit={props?.handleMobile}
      sx={{
        mt: 1,
        p: 3,
        height: "calc(100vh - 140px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 3,
        // border: "1px solid red",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 3,
        }}
      >
        <Box>
          <Box sx={{ my: 0.5 }}>
            <Typography variant="sm" color={theme.palette.gray.dark2}>
              Mobile number
            </Typography>
          </Box>
          <TextField
            type="number"
            required
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            fullWidth
            placeholder="Enter Mobile Number"
            // id="contact"
            // name="contact"
            // autoFocus
            // inputProps={{ maxLength: 30 }}
            error={contact?.length > 10}
            // helperText={"Max 30 characters"}
            InputProps={{
              style: { backgroundColor: theme.palette.base.white },
            }}
          />
        </Box>
        {OtpSentofMobile && (
          <Box>
            <Box>
              <Box
                sx={{
                  my: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // border: "1px solid red",
                }}
              >
                <Typography variant="sm" color={theme.palette.gray.dark2}>
                  OTP
                </Typography>
                {/* <Box> */}
                {sendOtpBtnOfMobile &&
                  (secondsOfMobile ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="sm" color={theme.palette.gray.dark2}>
                        <b>{secondsOfMobile}</b> Sec Remaining
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      type="submit"
                      variant="outlined"
                      // sx={{ mt: 3, mb: 2 }}
                      // size="large"
                      disabled={props.UI.loading}
                      onClick={(e) => {
                        // setOtpSentofMobile(false);
                        setResent(true);
                        // handleMobile(e);
                      }}
                    >
                      <Typography variant="sm" color={theme.palette.gray.dark2}>
                        Resend OTP
                      </Typography>
                      {props.UI.loading && (
                        <CircularProgress size={20} sx={{ px: 2 }} />
                      )}
                    </Box>
                  ))}
                {/* </Box> */}
              </Box>
              <TextField
                required
                fullWidth
                placeholder="Your 6 digit OTP"
                id="otp"
                value={otp}
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    return;
                  }
                  setOtp(e.target.value);
                }}
                name="otp"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Password color="primary" />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: theme.palette.base.white },
                }}
                autoFocus
              />
            </Box>
          </Box>
        )}
      </Box>

      {/* <Box sx={{ height: 100 }} /> */}
      <Button
        // type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        size="large"
        disabled={props.UI.loading || contact?.length != 10}
        onClick={(e) => {
          if (contact?.length === 10) {
            handleMobile(e);
          }
        }}
      >
        <Typography variant="sm" type="semibold">
          {OtpSentofMobile ? "Sign In" : "Send OTP"}
        </Typography>

        {props.UI.loading && <CircularProgress size={20} sx={{ px: 2 }} />}
      </Button>
      {/* {props?.sendOtpBtnOfMobile &&
        (props?.secondsOfMobile ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="subtitle2">
              <b>{props?.secondsOfMobile}</b> Sec Remaining
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              // fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              disabled={props.UI.loading}
              onClick={() => {
                props?.setOtpSentofMobile(false);
                props?.handleMobile();
              }}
            >
              Resend OTP
              {props.UI.loading && (
                <CircularProgress size={20} sx={{ px: 2 }} />
              )}
            </Button>
          </Box>
        ))} */}

      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Box>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  loginWithOtp,
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(LoginWithMobileNo);
