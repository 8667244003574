import React, { useEffect, useRef, useState } from "react";

// MUI Stuff
import { Box, Avatar, Typography, Paper, Skeleton } from "@mui/material";

//Redux
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";

//Files
import SimpleSnackbar from "../../components/Snackbar";

// Icons
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import theme from "../../util/theme";
import SelectProfileDrawer from "screens/Login/SelectProfileDrawer";
import ProfileDrawer from "screens/Home/ProfileDrawer";
import Refresh from "../../assets/img/refresh.svg";
import { isB2b } from "apiConfig";
function Profile(props) {
  // const [userData, setUserData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [userData, setUserData] = useState(null);
  const [gradeName, setGradeName] = useState(null);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "info",
    message: "",
  });

  const [showProfilesDialog, setShowProfilesDialog] = useState(false);

  const timerRef = useRef(null);
  useEffect(() => {
    const grade = userData?.mapping_bgs.filter(
      (each) => each?.session_year[0]?.is_current_session === true
    );
    if (grade?.length === 0) {
      setGradeName(userData?.mapping_bgs[0]);
    } else if (userData?.id) {
      setGradeName(grade[0]);
    }
  }, [userData]);

  useEffect(() => {
    if (isB2b) {
      timerRef.current = setTimeout(() => {
        window?.$zoho?.salesiq?.floatbutton?.visible("show");
      }, 1000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        window?.$zoho?.salesiq?.floatbutton?.visible("hide");
      };
    }
  }, [window.$zoho]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 56px)",
        pt: 2,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          background: theme.palette.gradient.main,
          top: 0,
          left: 0,
          height: "50vh",
          width: "100vw",
          zIndex: -10,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          background: theme.palette.background.paper,
          bottom: 0,
          left: 0,
          height: "50vh",
          width: "100vw",
          zIndex: -10,
        }}
      />

      <Box sx={{}}>
        <Paper
          sx={{
            borderRadius: 4,
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 16px",
            m: 2,
            mt: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Avatar src={props?.userDetails?.user_profile} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: 2,
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <Typography
                  color={theme.palette.gray.dark4}
                  variant="sm"
                  type="semibold"
                  sx={{ wordBreak: "break-all", pr: 0.5 }}
                >
                  {props?.userDetails?.name}
                </Typography>
                <Typography color={theme.palette.gray.main} variant="sm">
                  (
                  {props?.user?.userData?.user_level === 13
                    ? "Student"
                    : props?.user?.userData?.user_level === 11
                    ? "Teacher"
                    : props?.user?.userData?.user_level === 8
                    ? "Principal"
                    : "Other"}
                  )
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  overflowWrap: "anywhere",
                }}
              >
                {!gradeName ? (
                  <Skeleton width={100} />
                ) : (
                  <Typography variant="sm" color={theme.palette.gray.main}>
                    {props?.user?.userData?.user_level === 13 &&
                      `(${gradeName?.grade[0]?.grade__grade_name})`}{" "}
                    {props?.user?.erpId}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          {props?.userList?.length > 0 ? (
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => setShowProfilesDialog(true)}
            >
              <Box
                sx={{
                  backgroundImage: `url(${Refresh})`,
                  backgroundSize: "cover",
                  p: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{ width: 16, height: 16 }}
                  src={props?.userDetails?.user_profile}
                />
              </Box>

              <KeyboardArrowDownRounded sx={{ ml: 1 }} />
            </Box>
          ) : (
            ""
          )}
        </Paper>
        <Box sx={{ height: 80 }} />
        <ProfileDrawer
          setLoading={setLoading}
          loading={loading}
          userData={userData}
          setUserData={setUserData}
        />
      </Box>

      <SelectProfileDrawer
        showProfilesDialog={showProfilesDialog}
        setShowProfilesDialog={setShowProfilesDialog}
        profilesDataDialog={props?.userList}
      />
      <SimpleSnackbar
        message={snackbarData.message}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarData.severity}
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  userList: state?.user?.userList,
  userDetails: state.user?.userData?.role_details,
  academicYearId: state?.user?.academicYear?.academicYear?.academicYearId,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Profile);
