import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React from "react";

function DiaryCardSkeleton() {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ p: "16px !important" }}>
        <Box sx={{ mt: 0.5, mb: 0.5 }}>
          <Typography variant="caption" color="text.secondary">
            <Skeleton variant="text" width={150} />
          </Typography>

          <Typography variant="caption" color="text.secondary">
            <Skeleton variant="text" width={100} />
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Skeleton height={50} width={80} />
          <Skeleton height={50} width={80} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default DiaryCardSkeleton;
