import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_USER_NAVIGATION_DATA,
  SET_USER_APPS_DATA,
  SET_MODULE_ID,
  SET_ACAD_YEAR_LIST,
  SET_BRANCH,
  SET_BRANCH_LIST,
  SET_CURRENT_ACADEMIC_YEAR,
  SET_USER_LIST,
  SET_USER_CONTACT,
  SET_SCHOOL_DETAILS,
  SET_SYSTEM_CONFIG,
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
  notifications: [],
  userData: {},
  userNavigationData: [],
  userAppsData: {},
  moduleId: null,
  branchId: null,
  branchName: null,
  classId: null,
  sectionId: null,
  erpId: null,
  selectedYear: null,
  acadYearList: [],
  currentAcadYear: null,
  academicYear: null,
  currentBranchList: [],
  userList: [],
  userContact: "",
  schoolDetails: null,
  systemConfig: {
    "tt-enabled-v2": [],
    "hw-video-format": [".mp4", ".MP4"],
    hw_auto_asgn: [],
    anncmt_cumctn_config: [
      {
        is_email: false,
        is_whatsapp: false,
        is_sms: false,
        enbl_brnches: [],
      },
    ],
    diary_branches_new_flow: [],
    doodle_availability: false,
    is_referral: false,
    is_observation_enabled: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        userData: action.payload,
        branchId: state.branchId ?? action.payload?.role_details?.branch[0]?.id,
        branchName:
          state.branchName ??
          action.payload?.role_details?.branch[0]?.branch_name,
        gradeId: action.payload?.role_details?.grades[0]?.grade_id,
        gradeName: action.payload?.role_details?.grades[0]?.grade__grade_name,
        erpId: action.payload?.erp,
      };
    case SET_USER_NAVIGATION_DATA:
      return {
        ...state,
        userNavigationData: action.payload,
      };

    case SET_USER_APPS_DATA:
      return {
        ...state,
        userAppsData: action.payload,
      };

    case SET_MODULE_ID:
      return {
        ...state,
        moduleId: action.payload,
      };

    case SET_ACAD_YEAR_LIST:
      localStorage.setItem("acad_year_list", JSON.stringify(action.payload));
      return {
        ...state,
        acadYearList: action.payload,
      };

    case SET_CURRENT_ACADEMIC_YEAR:
      localStorage.setItem("current_acad_year", JSON.stringify(action.payload));
      return {
        ...state,
        currentAcadYear: action.payload,
      };

    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };

    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state,
      };

    case SET_BRANCH:
      localStorage.setItem("branch", JSON.stringify(action.payload));
      return {
        ...state,
        branch: action.payload,
        academicYear: {
          academicYear: {
            academicYearId: action.payload?.session_year?.id,
          },
        },
      };

    case SET_BRANCH_LIST:
      localStorage.setItem("branch_list", JSON.stringify(action.payload));
      return {
        ...state,
        currentBranchList: action.payload,
      };

    case SET_USER_LIST:
      localStorage.setItem("user_list", JSON.stringify(action.payload));
      return {
        ...state,
        userList: action.payload,
      };

    case SET_USER_CONTACT:
      localStorage.setItem("user_contact", JSON.stringify(action.payload));
      return {
        ...state,
        userContact: action.payload,
      };

    case SET_SCHOOL_DETAILS:
      localStorage.setItem("school_details", JSON.stringify(action.payload));
      return {
        ...state,
        schoolDetails: action.payload,
      };

    case SET_SYSTEM_CONFIG:
      localStorage.setItem("system_config", JSON.stringify(action.payload));
      return {
        ...state,
        systemConfig: action.payload,
      };
    default:
      return state;
  }
}
