import React, { useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// Icons
import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  SettingsSuggestRounded as SettingsSuggestRoundedIcon,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import Logo from "assets/img/logo.png";
import Placeholder from "assets/img/placeholder-img.jpg";

// redux
import { connect } from "react-redux";

import SettingsDrawer from "./SettingsDrawer";
import theme from "util/theme";
import {
  LOADING_UI,
  SET_SCHOOL_DETAILS,
  SET_USER_CONTACT,
  SET_USER_LIST,
  STOP_LOADING_UI,
} from "redux/types";
import store from "redux/store";
import { API_CENTRAL } from "apiConfig";

import axios from "axios";

function AppBarBack(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);
  const [shoolDetails, setShoolDetails] = useState([]);
  useEffect(() => {
    if (window.location.pathname.includes("/login")) {
      fetchSchoolDetails();
    }
  }, []);

  const fetchSchoolDetails = () => {
    let splitedUrlAddress = window.location.origin.split(".");
    let subDomain = splitedUrlAddress[0]?.split("//")[1];
    if (subDomain?.includes("localhost")) {
      subDomain = "qa";
    }
    axios
      .get(
        `${API_CENTRAL}/central-admin/school_details/?sub_domain=${subDomain}`,
        {
          headers: {
            "x-api-key": "vikash@12345#1231",
          },
        }
      )
      .then((res) => {
        setShoolDetails(res?.data);
        store.dispatch({
          type: SET_SCHOOL_DETAILS,
          payload: res?.data,
        });
      });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          height: "64px",
          backgroundImage: props?.backgroundImage ?? theme.palette.primary.dark,
          backgroundColor: props?.backgroundColor ?? theme.palette.base.white,
          boxShadow: "none",
          color: props?.color ? props?.color : theme.palette.base.black,
          my: 0,
        }}
      >
        <Toolbar
          sx={{
            marginY: "auto",
          }}
        >
          {props?.noBackButton ? null : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                if (props.onClick) {
                  props.onClick();
                } else {
                  if (window.history?.length == 1) {
                    navigate("/dashboard/home");
                  } else {
                    navigate(-1);
                  }
                }
              }}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                maxWidth: "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="lg"
                type="semibold"
                noWrap
                sx={{ maxWidth: "100%" }}
              >
                {props?.title}
              </Typography>
              <Typography variant="subtitle2" noWrap>
                {props?.subtitle}
              </Typography>
            </Box>
            {props?.showSettings ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 2 }}
                onClick={() => {
                  setShowSettingsDrawer(true);
                }}
              >
                <SettingsSuggestRoundedIcon />
              </IconButton>
            ) : null}
            {props.children}
          </Box>
          {props?.showSettings ? (
            <SettingsDrawer
              showSettingsDrawer={showSettingsDrawer}
              setShowSettingsDrawer={setShowSettingsDrawer}
              onUpdate={props?.onUpdate}
            />
          ) : null}
          <Box>
            {props?.logo ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{
                    bgcolor: "none",
                    width: 24,
                    height: 24,
                  }}
                  src={Logo}
                />
                <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {shoolDetails?.school_logo?.length > 0 ? (
                    <img
                      width="30"
                      height="30"
                      src={shoolDetails?.school_logo}
                      alt="school_logo"
                      style={{ borderRadius: 4, objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      width="30"
                      height="30"
                      src={Placeholder}
                      alt="school_logo"
                      style={{ borderRadius: 4 }}
                    />
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}
            {/* {props?.schoolLogo ? (
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  mb: 3,
                }}
              >
                {shoolDetails?.school_logo?.length > 0 ? (
                  <img
                    width="42"
                    height="48"
                    src={shoolDetails?.school_logo}
                    alt="school_logo"
                    style={{ borderRadius: 4 }}
                  />
                ) : (
                  <img
                    width="42"
                    height="48"
                    src={Placeholder}
                    alt="school_logo"
                    style={{ borderRadius: 4 }}
                  />
                )}
              </Box>
            ) : (
              ""
            )} */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: 64 }}></Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  branch: state.user.branch,
  acadYearList: state.user.acadYearList,
  currentAcadYear: state.user.currentAcadYear,
  moduleId: state.user.moduleId,
  currentBranchList: state.user?.currentBranchList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppBarBack);
