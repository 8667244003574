import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  styled,
  SwipeableDrawer,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppBarBack from "../../../components/AppBarBack";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";

import PropTypes from "prop-types";
import DailyDiary from "./DailyDiary";
import GeneralDiary from "./GeneralDiary";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AlertNotificationContext } from "../../../context-api/alert-context/alert-state";
import { CalendarTodayOutlined as CalendarTodayOutlinedIcon } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import theme from "util/theme";
import Puller from "components/Drawer/Puller";
import { StaticDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { mixpanelTracker } from "util/MixpanelTracking/Tracker";

const floatStyle = {
  margin: 0,
  top: "auto",
  right: 16,
  bottom: 16,
  left: "auto",
  position: "fixed",
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    sx={{
      background: "#fff",
      px: 2,
      borderRadius: "20px 20px 0px 0px",
      py: 2,
      pt: 3,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  // fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 500,
  "&.MuiTab-root": {
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: 16,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.blueDark.main} `,
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.main,
  },
  variant: "contained",
}));

const CustomTab = styled((props) => <Tab {...props} />)(
  ({ theme, disabled }) => ({
    textTransform: "none",
    // width: window.innerWidth / 25,
    // minWidth: window.innerWidth / 30,
    color: theme.palette.base.white,
    fontWeight: 500,
    border: "1px solid var(--blue-dark-400, #528BFF)",
    borderRadius: 16,
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 100%)",
    // backgroundColor: "pink",

    "&.MuiTab-root": {
      marginRight: 6,
      marginLeft: 6,
      width: 60,
      height: 70,
    },
    "&.Mui-selected": {
      backgroundColor: "#fff",
      color: theme.palette.blueDark.dark1,
      border: "none",
      borderBottom: `4px solid ${theme.palette.blueDark.light1}`,
    },
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      // backgroundColor: "red",
      opacity: 0.5,
      // color: "#fff",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, width: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DiaryNew() {
  const location = useLocation();
  const { setAlert } = React.useContext(AlertNotificationContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTabParam(newValue);
  };

  const handleDateTabChange = (e, newValue) => {
    setDateTabValue(newValue);
    handleDateChange(dayListInThisMonth[newValue]?.date);
  };

  const [tabParam, setTabParam] = useSearchParamsState(
    "tab",
    Number(searchParams.get("tab"))
  );
  const [dateParam, setDateParam] = useSearchParamsState(
    "date",
    searchParams.get("date")
  );
  const [value, setValue] = React.useState(Number(tabParam) ?? 0);

  const [dateTabValue, setDateTabValue] = useState(0);

  const [date, setDate] = useState(dateParam ? dayjs(dateParam) : dayjs());
  const [tempDate, setTempDate] = useState(dayjs());

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [scrollY, setScrollY] = React.useState(0);

  function handleScroll() {
    setScrollY(window.pageYOffset);
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTabParam(value);
    mixpanelTracker("diary_open");
  }, []);

  const getDaysArray = (function () {
    const names = Object.freeze([
      "sun",
      "mon",
      "tue",
      "wed",
      "thu",
      "fri",
      "sat",
    ]);
    return (year, month) => {
      const monthIndex = month - 1;
      const date = new Date(year, monthIndex, 1);
      const result = [];
      while (date.getMonth() == monthIndex) {
        let a = {};
        a["date"] = date.getDate();
        a["day"] = names[date.getDay()];
        result.push(a);
        date.setDate(date.getDate() + 1);
      }
      return result;
    };
  })();

  const dayListInThisMonth = getDaysArray(
    dayjs(date).year(),
    dayjs(date).month() + 1
  );

  function handleDateChange(newDate) {
    setDate(dayjs(date).date(newDate));
    handleDateParam(dayjs(date).date(newDate));
  }

  function handleMonthIncrease() {
    let thisMonth = dayjs(date).month();
    setDate(
      dayjs(date)
        .set("month", thisMonth + 1)
        .set("date", 1)
    );
    handleDateParam(
      dayjs(date)
        .set("month", thisMonth + 1)
        .set("date", 1)
    );
    setDateTabValue(0);
  }
  function handleMonthDecrease() {
    let thisMonth = dayjs(date).month();
    setDate(
      dayjs(date)
        .set("month", thisMonth - 1)
        .set("date", 1)
    );
    handleDateParam(
      dayjs(date)
        .set("month", thisMonth - 1)
        .set("date", 1)
    );
    setDateTabValue(0);
  }
  const handleDateParam = (currDate) => {
    searchParams.set("date", currDate.format("YYYY-MM-DD"));
    setSearchParams(searchParams, { replace: true });
  };
  useEffect(() => {
    let todaysDate = dayjs(date).date();
    setDateTabValue(
      dayListInThisMonth.findIndex((i) => i?.date === todaysDate)
    );
  }, []);

  const handleApplyButtonClick = () => {
    setDate(tempDate); // Apply the selected date from the temporary state
    handleDateParam(tempDate);
    setShowDatePicker(false);
  };

  const handleCancelButtonClick = () => {
    setTempDate(date); // Restore the original selected date from the state
    setShowDatePicker(false);
  };

  const handleMonthDateChange = (date) => {
    setTempDate(date); // Update the temporary state when the date is changed
  };

  useEffect(() => {
    let todaysDate = dayjs(date).date();
    let dateToSet = todaysDate;
    if (searchParams?.get("date")) {
      setDate(dayjs(moment(searchParams?.get("date"))));
      handleDateParam(dayjs(moment(searchParams?.get("date"))));
      dateToSet = dayjs(searchParams?.get("date")).date();
    }

    setDateTabValue(dayListInThisMonth.findIndex((i) => i?.date == dateToSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDatePickerFooter = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        gap: 2,
      }}
    >
      <Button onClick={handleCancelButtonClick} variant="outlined" fullWidth>
        Cancel
      </Button>
      <Button onClick={handleApplyButtonClick} variant="contained" fullWidth>
        Apply
      </Button>
    </Box>
  );

  return (
    <Container
      maxWidth="sm"
      sx={{ p: 0, background: theme.palette.gradient.main, height: "100%" }}
    >
      <AppBarBack
        title="Diary"
        noSettings
        backgroundImage={
          scrollY > 40 || window.innerWidth >= 600
            ? theme.palette.gradient.main
            : null
        }
        backgroundColor={scrollY > 40 ? null : "rgba(255, 255, 255, 0)"}
        color={theme.palette.base.white}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="upload picture"
            component="label"
            onClick={handleMonthDecrease}
          >
            <KeyboardArrowLeftIcon
              sx={{
                color: theme.palette.blueDark.light6,
              }}
            />
          </IconButton>
          <Button
            startIcon={<CalendarTodayOutlinedIcon />}
            onClick={() => {
              setShowDatePicker((value) => !value);
            }}
            sx={{
              color: theme.palette.blueDark.light6,
            }}
          >
            <Typography variant="sm" type="regular">
              {dayjs(date).format("MMMM")} {dayjs(date)?.format("YYYY")}
            </Typography>
          </Button>
          <IconButton
            aria-label="upload picture"
            component="label"
            onClick={handleMonthIncrease}
          >
            <KeyboardArrowRightIcon
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>
      </AppBarBack>
      <Box sx={{ width: "100%", my: 0, py: 0 }}>
        {/* new date picker with horizonal scroll */}

        <Box
          sx={{
            pt: 4,
            // mb: 0.5,
            mt: -2,
            // backgroundColor: theme.palette.primary.dark,
            // backgroundImage: theme.palette.gradient.main,
            color: "#fff",
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "none",
              // position: "fixed",
            }}
          >
            <SwipeableDrawer
              onOpen={() => {}}
              anchor="bottom"
              open={showDatePicker}
              onClose={handleCancelButtonClick}
              PaperProps={{
                elevation: 0,
                style: { backgroundColor: "transparent" },
              }}
            >
              <Card
                sx={{
                  minWidth: 275,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  overflow: "auto",
                  p: 0,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      p: 1,
                      position: "relative",
                    }}
                  >
                    <Puller />
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      className="diary-static-datepicker"
                      views={["year", "month"]}
                      label="Year and Month"
                      minDate={dayjs().subtract(4, "year")}
                      maxDate={dayjs().add(7, "year")}
                      open={showDatePicker}
                      openTo="month"
                      onOpen={() => {
                        setShowDatePicker(true);
                      }}
                      onClose={() => {
                        setShowDatePicker(false);
                      }}
                      // value={date}
                      value={tempDate} // Use the temporary date in the date picker
                      // onChange={(newValue) => {
                      //   setDate(newValue);
                      // }}
                      onChange={handleMonthDateChange} // Handle date change to update the temporary date
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Divider sx={{ width: "90%" }} />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      position: "relative",
                    }}
                  >
                    {renderDatePickerFooter()}
                  </Box>
                </CardContent>
              </Card>
            </SwipeableDrawer>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Tabs
              value={dateTabValue}
              onChange={handleDateTabChange}
              aria-label="basic tabs example"
              variant="scrollable"
              // scrollButtons
              // allowScrollButtonsMobile
              TabIndicatorProps={{ style: { display: "none" } }} // Remove the indicator
            >
              {dayListInThisMonth?.map((day, index) => {
                let dateForThisTab = dayjs(date).set("date", day?.date);
                return (
                  <CustomTab
                    key={day?.date}
                    style={{
                      textTransform: "capitalize",
                      width: window.innerWidth / 6.5,
                      minWidth: Math.min(window.innerWidth / 6.5, 60),
                      maxWidth: Math.min(window.innerWidth / 6.5, 80),
                    }}
                    disabled={dayjs(dateForThisTab).isAfter(dayjs(), "date")}
                    label={
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="xs" type="regular">
                          {day?.day}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="xl" type="medium" family="inter">
                            {day?.date}
                          </Typography>
                        </Box>
                        {day.date == dayjs().date() &&
                          date.month() == dayjs().month() && (
                            <HorizontalRuleIcon
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                              }}
                            />
                          )}
                      </Box>
                    }
                    {...a11yProps(day?.date)}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Box>

        <Box>
          <StyledTabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { display: "none" } }} // Remove the indicator
          >
            <StyledTab
              style={{ textTransform: "capitalize" }}
              label="Daily Diary"
              {...a11yProps(0)}
            />
            <StyledTab
              style={{ textTransform: "capitalize" }}
              label="General Diary"
              {...a11yProps(1)}
            />
          </StyledTabs>
        </Box>

        <Box sx={{ px: 0, background: "#fff", mt: -1, height: "100%" }}>
          <TabPanel value={value} index={0}>
            <DailyDiary date={date} setAlert={setAlert} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GeneralDiary date={date} setAlert={setAlert} />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
}

export default DiaryNew;
