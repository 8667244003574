// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_USER_NAVIGATION_DATA = "SET_USER_NAVIGATION_DATA";
export const SET_USER_APPS_DATA = "SET_USER_APPS_DATA";
export const SET_MODULE_ID = "SET_MODULE_ID";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const SET_BRANCH = "SET_BRANCH";
export const SET_ACAD_YEAR_LIST = "SET_ACAD_YEAR_LIST";
export const SET_CURRENT_ACADEMIC_YEAR = "SET_CURRENT_ACADEMIC_YEAR";
export const SET_BRANCH_LIST = "SET_BRANCH_LIST";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_CONTACT = "SET_USER_CONTACT";
export const SET_SCHOOL_DETAILS = "SET_SCHOOL_DETAILS";

// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

// Academic Year reducer types
export const SET_ACADEMIC_YEAR = "SET_ACADEMIC_YEAR";

// System Config reducer types`
export const SET_SYSTEM_CONFIG = "SET_SYSTEM_CONFIG";
