import { Box, Dialog, Typography, Avatar, Paper } from "@mui/material";
import { AMAZON_S3 } from "apiConfig";
import React, { useState } from "react";
import { connect } from "react-redux";
import { loginWithMobileNo } from "redux/actions/userActions";

import theme from "util/theme";
import { useNavigate } from "react-router-dom";
import SimpleSnackbar from "components/Snackbar";

function SelectProfileDialog(props) {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("warning");
  return (
    <>
      <Dialog
        open={props?.showProfilesDialog}
        onClose={() => props?.setShowProfilesDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ zIndex: 10000 }}
        fullWidth
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">
            <b>Welcome,</b>
          </Typography>
          <Typography variant="subtitle2" sx={{ pb: 2, pt: 1 }}>
            <b>Select Profile to explore account</b>
          </Typography>
          {props?.userList?.map((each) => (
            <Paper
              className="card-border"
              sx={{ p: 1, my: 1, borderRadius: 1 }}
              key={each?.erp_id}
              onClick={() => {
                const userData = {
                  contact: props?.contact,
                  erp_id: each?.erp_id,
                  hmac: each?.hmac,
                };
                props.loginWithMobileNo(
                  userData,
                  navigate,
                  setOpenSnackbar,
                  setSeverity
                );
                localStorage.setItem(
                  "selectProfileDetails",
                  JSON.stringify(each)
                );
              }}
            >
              <Box className="color-gray" sx={{ p: 1, mb: 1 }}>
                <Typography>
                  <b>{each?.branch_name}</b>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", py: 1 }}>
                {window.location.href.slice(8, 10) == "qa" ||
                window.location.href.slice(7, 12) == "local" ? (
                  <Avatar
                    alt={each?.name}
                    src={`${AMAZON_S3.ERP_BUCKET}/dev/media/${each?.profile}`}
                  />
                ) : (
                  <Avatar
                    alt={each?.name}
                    src={`${AMAZON_S3.ERP_BUCKET}/prod/media/${each?.profile}`}
                  />
                )}
                <Box sx={{ ml: 1 }}>
                  <Typography variant="subtile2">
                    <b>{each?.name}</b>
                  </Typography>{" "}
                  <Box
                    sx={{ display: "flex", color: theme.palette.success.main }}
                  >
                    <Typography variant="subtile2">
                      {each?.grade_name}
                    </Typography>{" "}
                    <Typography sx={{ pl: 1 }} variant="subtile2">
                      {each?.section_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </Dialog>

      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  userList: state.user.userList,
  contact: state.user?.userContact,
});
const mapActionsToProps = {
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(SelectProfileDialog);
