import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  FileDownloadOutlined as DownloadIcon,
  ArticleOutlined as ArticleIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import theme from "../../../util/theme";
import moment from "moment";
import { AMAZON_S3 } from "../../../apiConfig";

import { AttachmentPreviewerContext } from "../../../components/attachment-previewer/attachment-previewer-contexts";
import { truncate } from "lodash";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 0,
  left: "calc(50% - 15px)",
}));

function DiaryDrawer({ showDrawer, setShowDrawer, drawerData, diaryType }) {
  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showDrawer}
      onClose={() => {
        setShowDrawer(false);
      }}
      onOpen={() => {
        setShowDrawer(true);
      }}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Card
        sx={{
          minWidth: 275,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,

          overflow: "auto",
        }}
      >
        <CardContent>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              p: 1,
              position: "relative",
            }}
          >
            <Puller />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: theme.palette.gray.light4,
                p: 2,
                borderRadius: 4,
                boxShadow: theme.shadows[1],
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography
                  variant="lg"
                  type="bold"
                  color={theme.palette.gray.dark3}
                  sx={{ textTransform: "capitalize" }}
                >
                  {drawerData?.title}
                </Typography>

                <Typography
                  variant="sm"
                  type="regular"
                  color={theme.palette.gray.dark1}
                  sx={{ textTransform: "capitalize" }}
                >
                  {drawerData?.message}
                </Typography>
              </Box>
            </Box>
            {drawerData?.documents?.length > 0 && (
              <Box sx={{ py: 1 }}>
                {drawerData?.documents?.map((attachment, index) => {
                  let fullName =
                    attachment?.split("/")[attachment?.split("/").length - 1];
                  if (fullName?.split("/").length <= 0) {
                    fullName = attachment;
                  }
                  const fileName =
                    fullName.split(".")[fullName?.split(".").length - 2];
                  const extension =
                    fullName.split(".")[fullName?.split(".").length - 1];
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        border: `1px solid ${theme.palette.border.main}`,
                        borderRadius: 4,
                        boxShadow: theme.shadows[1],
                      }}
                      key={index}
                      onClick={() => {
                        let resource = attachment;
                        openPreview({
                          currentAttachmentIndex: 0,
                          attachmentsArray: [
                            {
                              src: `${AMAZON_S3.ERP_BUCKET}/${attachment}`,
                              name: resource,
                              extension:
                                "." +
                                resource.split(".")[
                                  resource.split(".").length - 1
                                ],
                            },
                          ],
                        });
                      }}
                    >
                      <ButtonBase
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <ArticleIcon sx={{ color: theme.palette.gray.dark3 }} />
                        <Typography
                          variant="sm"
                          type="regular"
                          color={theme.palette.gray.main}
                          sx={{
                            wordBreak: "break-word",
                          }}
                          textAlign="center"
                        >
                          {truncate(fullName, {
                            length: 35,
                            omission: "...",
                          })}
                        </Typography>
                      </ButtonBase>
                      <IconButton
                        aria-label="download"
                        onClick={(e) => {
                          e.stopPropagation();
                          const link = document.createElement("a");
                          link.href = `${AMAZON_S3.ERP_BUCKET}/${attachment}`;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      >
                        <DownloadIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box sx={{ py: 1 }}>
              <Divider />
            </Box>
            <Box>
              <Typography
                variant="xs"
                type="regular"
                color={theme.palette.gray.main}
              >
                Posted on{" "}
                {moment(drawerData?.created_at).format("DD MMM YYYY, HH:mm A")}{" "}
                by {drawerData?.created_by?.first_name}{" "}
                {drawerData?.created_by?.last_name}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </SwipeableDrawer>
  );
}

export default DiaryDrawer;
