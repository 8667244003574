import {
  Box,
  Button,
  Container,
  Typography,
  AppBar,
  IconButton,
  Toolbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import QrReader from "react-qr-scanner";
// Icons
// import {
//   QrCodeScannerRounded as QrCodeScannerRoundedIcon,
//   CropFreeRounded as CropFreeRoundedIcon,
//   ArrowBackIosRounded as ArrowBackIosRoundedIcon,
// } from "@mui/icons-material";
import AppBarBack from "components/AppBarBack";
import theme from "util/theme";
import { AttachmentPreviewerContext } from "components/attachment-previewer/attachment-previewer-contexts";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import CameraError from "../../assets/img/camera_error.svg";

const QRContent = () => {
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const [showCam, setShowCam] = useState(true);
  const [newUrl, setNewUrl] = useState(null);

  const [selectedCamera, setSelectedCamera] = useState(null);

  const navigate = useNavigate();
  const { setAlert } = React.useContext(AlertNotificationContext);

  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};

  const onCloseFn = function () {
    setError(null);
    setResult(null);
    setShowCam(true);
  };

  const handleScan = (data) => {
    console.log(data);
    if (data === null) {
      return;
    }
    setResult(data);
    setShowCam(false);

    const text = data?.text;
    const extension = data?.text?.split(".")?.pop();
    if (text?.includes("https://letseduvate.s3.amazonaws.com")) {
      setAlert("success", "QR scanned!!");

      setShowCam(false);
      openPreview({
        onClose: onCloseFn,
        currentAttachmentIndex: 0,
        attachmentsArray: [
          {
            src: data?.text,
            name: "qr resource",
            extension: extension,
          },
        ],
      });
      const textArr = text.split("/");
      const fileName = textArr[textArr.length - 1];
      console.log(fileName);

      setNewUrl(
        "https://events-tracking.s3.ap-south-1.amazonaws.com/prod/events/" +
          fileName
      );
    } else {
      setAlert("warning", "Please scan QR from letseduvate only.");
      setResult(null);
    }
  };

  const handleError = (err) => {
    setError(err);
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ p: 0, overflow: "hidden" }}>
      <AppBarBack
        title="QR Content"
        onClick={() => {
          navigate("/dashboard/home");
        }}
        noSettings
      />

      {/* {error ? (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            p: 2,
            textAlign: "center",
          }}
        >
          <Box my={15}>
            <div
              style={{
                backgroundImage: `url(${CameraError})`,
                height: 150,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </Box>
          <Typography variant="subtitle2">
            <b>There was an error opening your camera</b>
          </Typography>

          <Typography variant="caption">{error.message}</Typography>
          <Box
            sx={{
              background: theme.palette.background.default,
              p: 2,
              mt: 2,
              borderRadius: 2,
              textAlign: "left",
            }}
          >
            <Typography variant="caption">
              <b>Try with following:</b>
            </Typography>
            <br />
            <Typography variant="caption">
              - Please check app's camera and mic permission.
            </Typography>
            <br />
            <Typography variant="caption">
              - Or try with the latest version of the app.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box style={{ position: "relative", overflow: "hidden" }}>
          <Box
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              top: "50%",
            }}
          >
            <CropFreeRoundedIcon sx={{ fontSize: 400, opacity: 0.5 }} />
          </Box>
          {!result && showCam ? (
            <QrReader
              delay={delay}
              style={{
                height:
                  window.innerHeight > window.innerWidth ? "150vw" : "50vh",
                width:
                  window.innerHeight > window.innerWidth ? "100vw" : "75vh",
                objectFit: "cover",
              }}
              constraints={{
                audio: false,
                video: { facingMode: "environment" },
              }}
              onError={handleError}
              onScan={handleScan}
            />
          ) : (
            <Box
              sx={{
                height:
                  window.innerHeight > window.innerWidth ? "150vw" : "50vh",
                width:
                  window.innerHeight > window.innerWidth ? "100vw" : "75vh",
              }}
            >
              <Button
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={() => {
                  setShowCam(true);
                  setResult(null);
                }}
              >
                Retry
              </Button>
            </Box>
          )}
        </Box>
      )}

      {result === null ? (
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              p: 1,
            }}
          >
            <Typography variant="subtitle2">
              <b>Instructions:</b>
            </Typography>

            <Typography variant="caption">
              <li>Use this scanner to scan the QR code printed on books.</li>
              <li>Place the QR in the defined space.</li>
            </Typography>
          </Box>
        </Box>
      ) : null} */}

      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          p: 2,
          m: 1,
        }}
      >
        <Typography variant="subtitle2" textAlign={"center"} pb={5} pt={2}>
          <b>Coming soon!!</b>
        </Typography>
        <div
          style={{
            backgroundImage: `url(${CameraError})`,
            height: 100,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <Typography variant="subtitle2" pt={3}>
          <b>What's coming:</b>
        </Typography>
        <Typography variant="subtitle2">
          Seamless Scanning: Effortlessly scan all QR codes from letseduvate in
          a snap! Unlock a world of interactive content, and surprises at your
          fingertips.{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          p: 2,
          m: 1,
        }}
      >
        <Typography variant="subtitle2">
          <b>Instructions for current use:</b>
        </Typography>
        <Typography variant="caption">
          <li>
            Use any scanner(suggested google qr scanner) to scan the QR code
            printed on books.
          </li>
          <li>Place the QR in the defined space.</li>
        </Typography>
      </Box>
    </Container>
  );
};

export default QRContent;
