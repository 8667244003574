import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import theme from "../../../util/theme";
import moment from "moment";
import { AMAZON_S3 } from "../../../apiConfig";

import { AttachmentPreviewerContext } from "../../../components/attachment-previewer/attachment-previewer-contexts";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 0,
  left: "calc(50% - 15px)",
}));

function DiaryDrawer({ showDrawer, setShowDrawer, drawerData, diaryType }) {
  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};
  return (
    <>
      <SwipeableDrawer
        onOpen={() => {}}
        anchor="bottom"
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Card
          sx={{
            minWidth: 275,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,

            overflow: "auto",
          }}
        >
          <CardContent>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                p: 1,
                position: "relative",
              }}
            >
              <Puller />
            </Box>
            <Typography variant="h6" component="div">
              <b>{drawerData?.subject?.subject_name}</b>
            </Typography>
            <Typography sx={{ mb: 1, mt: 1 }} color="text.primary">
              <b>Created by:</b> {drawerData?.created_by?.first_name}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.primary">
              <b>Created on:</b>{" "}
              {moment(drawerData?.created_at).format("DD MMM YYYY")}
            </Typography>
            {diaryType == 1 && (
              <>
                {drawerData?.title && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Title: </b>
                    {drawerData?.title}
                  </Typography>
                )}
                {drawerData?.message && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Message: </b>
                    {drawerData?.message}
                  </Typography>
                )}
              </>
            )}
            {diaryType == 2 && (
              <>
                {drawerData?.title && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Title: </b>
                    {drawerData?.title}
                  </Typography>
                )}

                {drawerData?.teacher_report?.previous_class && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Recap of previous class: </b>
                    {drawerData?.teacher_report?.previous_class}
                  </Typography>
                )}

                {drawerData?.teacher_report?.class_work && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Details of classwork: </b>
                    {drawerData?.teacher_report?.class_work}
                  </Typography>
                )}

                {drawerData?.teacher_report?.summary && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Summary: </b>
                    {drawerData?.teacher_report?.summary}
                  </Typography>
                )}

                {drawerData?.teacher_report?.tools_used && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Tools Used: </b>
                    {drawerData?.teacher_report?.tools_used}
                  </Typography>
                )}

                {drawerData?.hw_description && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Description: </b>
                    {drawerData?.hw_description}
                  </Typography>
                )}

                {drawerData?.teacher_report?.homework && (
                  <Typography sx={{ mb: 1 }} color="text.primary">
                    <b>Homework: </b>
                    {drawerData?.teacher_report?.homework}
                  </Typography>
                )}
              </>
            )}

            <Box display="flex" justifyContent="space-between">
              {drawerData?.documents?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minWidth: "100%",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1" color="text.primary">
                      <b>Attachments:</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ my: 1 }}>
                    {drawerData?.documents?.map((attachment, index) => (
                      <ButtonBase
                        onClick={() => {
                          let resource = attachment;
                          openPreview({
                            currentAttachmentIndex: 0,
                            attachmentsArray: [
                              {
                                src: `${AMAZON_S3.ERP_BUCKET}/${attachment}`,
                                name: resource,
                                extension:
                                  "." +
                                  resource.split(".")[
                                    resource.split(".").length - 1
                                  ],
                              },
                            ],
                          });
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "95vw",
                          }}
                          key={index}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap={true}
                          >
                            {`Diary ${index + 1}.${attachment
                              .split(".")
                              .pop()}`}
                          </Typography>
                          <IconButton
                            aria-label="download"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              const link = document.createElement("a");
                              link.href = `${AMAZON_S3.ERP_BUCKET}/${attachment}`;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      </ButtonBase>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </SwipeableDrawer>
    </>
  );
}

export default DiaryDrawer;
