import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { API_REPORT, X_DTS_HOST } from "../../apiConfig";

import { styled } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import axiosInstance from "../../util/axiosInstance";

import theme from "../../util/theme";
import moment from "moment";

function AssesmentDetail(props) {
  const navigate = useNavigate();
  const [performanceData, setPerformanceData] = useState([]);
  const [performanceLoading, setPerformanceLoading] = useState(false);

  const [upcomingExam, setUpcomingExam] = useState([]);
  const [upcomingXmLoading, setUpcomingXmLoading] = useState(false);

  useEffect(() => {
    if (props?.current_acad_year?.id) {
      setPerformanceData([]);
      fetchPerformance();
      fetchUpcomingExam();
    }
  }, [props?.current_acad_year?.id]);

  const fetchPerformance = () => {
    setPerformanceLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/student_performance/?session_year=${props?.current_acad_year?.id}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setPerformanceData(res?.data?.result);
        }
      })
      .catch((err) => {
        console.log("Performance fetch error", err);
      })
      .finally(() => {
        setPerformanceLoading(false);
      });
  };

  const fetchUpcomingExam = () => {
    setUpcomingXmLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/upcoming_exam/?session_year=${props?.current_acad_year?.id}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setUpcomingExam(res?.data?.result);
        }
      })
      .catch((err) => {
        console.log("upcoming Exam fetch error", err);
      })
      .finally(() => {
        setUpcomingXmLoading(false);
      });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        performanceData?.overall_performance?.overall_performance <= 50
          ? theme.palette.error.main
          : performanceData?.overall_performance?.overall_performance <= 75
          ? theme.palette.secondary.main
          : performanceData?.overall_performance?.overall_performance <= 90
          ? theme.palette.success.light
          : theme.palette.success.main,
    },
  }));

  return (
    <Box
      className="color-white-bg"
      sx={{
        width: "100%",
        p: 1,
        my: 2,
        // borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ p: 0.5, pb: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ alignItems: "center" }}
        >
          <Typography variant="xl" type="medium">
            Assessment
          </Typography>
          <ArrowForwardIosIcon
            sx={{ fontSize: 16, color: "text.secondary" }}
            onClick={() => navigate("/assessment-list")}
          />
        </Box>
      </Box>
      <Box
        className=" bg-white"
        sx={{
          p: 1,
          // mx: 1,
          flex: 1,
          // flexBasis: "40vw",
          // maxWidth: "200px",
          // height: "100%",
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          gap: 1,
          justifyContent: "space-around",
          lineHeight: 0,
          backgroundColor: `rgba(0,0,0,0,0.5)`,
          border: "1px solid #F0F0F0",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
          // border: "2px solid red",
        }}
        // onClick={() => navigate("/calendar")}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="sm" type="regular" color="text.secondary">
              Overall performance
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              px: 1,
              py: 0.5,
              borderRadius: 4,
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Box sx={{ position: "relative" }}>
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    disableShrink
                    value={
                      performanceData?.overall_performance?.overall_performance
                    }
                    color="error"
                    sx={{
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                  />
                </Box>
                <Typography variant="md" type="semibold">
                  {performanceData?.overall_performance?.overall_performance}%
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#F2F4F7",
                  p: 0.5,
                  borderRadius: 4,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#667085",
                  }}
                  variant="xs"
                  type="regular"
                >
                  {performanceData?.subjects_performance?.[0]?.agg_scored}/
                  {performanceData?.subjects_performance?.[0]?.agg_total_marks}
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>

      {upcomingExam?.length > 0 ||
        (performanceData?.test_evaluated?.length > 0 && (
          <Box
            sx={{
              my: 1,
              px: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="sm"
                type="medium"
                color={"text.secondary"}
                sx={{ my: 1 }}
              >
                Test Evaluated
              </Typography>
              <ArrowForwardIosIcon
                sx={{ fontSize: 16, color: "text.secondary" }}
                onClick={() => navigate("/homework")}
              />
            </Box>
            <Box
              className="scrollBar-hide"
              sx={{
                display: "flex",
                overflowX: "auto",
                // width: "100%",
                p: 1,
                px: 0,
                pb: 2,
                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "nowrap",
                  gap: 1,
                  // border: "1px solid green",
                }}
              >
                {performanceData?.test_evaluated?.map((each, index) => {
                  return (
                    <Box
                      className="bg-white"
                      sx={{
                        px: 2,
                        py: 1,
                        ml: 0.5,
                        width: "60vw",
                        maxWidth: "400px",
                        borderRadius: 4,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                        lineHeight: 0,
                        backgroundColor: `rgba(0,0,0,0,0.5)`,
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        border: "1px solid #EAECF0",
                      }}
                      onClick={() => navigate("/assessment-list?tab=1")}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          // jus,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography variant="md" type="medium" noWrap>
                            {each?.test_name}
                          </Typography>
                          <Typography
                            variant="xs"
                            type="regular"
                            // noWrap
                            color="text.secondary"
                          >
                            {parseFloat(each?.get_marks?.toFixed(1))}/
                            {each.total_marks}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            variant="sm"
                            type="regular"
                            color="text.secondary"
                          >
                            {each?.test_type?.length > 15
                              ? each?.test_type?.slice(0, 15) + "..."
                              : each?.test_type}{" "}
                            •{" "}
                            {each?.subject?.length > 10
                              ? each?.subject?.slice(0, 10) + "..."
                              : each?.subject}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        ))}

      {upcomingExam?.length > 0 && (
        <Box
          sx={{
            my: 1,
            px: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="sm"
              type="medium"
              color={theme.palette.success.main}
              sx={{ my: 1 }}
            >
              Upcoming tests
            </Typography>
            <ArrowForwardIosIcon
              sx={{ fontSize: 16, color: "text.secondary" }}
              onClick={() => navigate("/assessment-list")}
            />
          </Box>
          <Box
            className="scrollBar-hide"
            sx={{
              display: "flex",
              overflowX: "auto",
              // width: "100%",
              p: 1,
              px: 0,
              pb: 2,
              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
                flexWrap: "nowrap",
                gap: 1,
                // border: "1px solid green",
              }}
            >
              {upcomingExam?.map((each, index) => {
                return (
                  <Box
                    // className=" bg-white"
                    sx={{
                      px: 2,
                      py: 1,
                      ml: 0.5,
                      width: "60vw",
                      maxWidth: "400px",
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      lineHeight: 0,
                      backgroundColor: theme.palette.success.light6,
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      border: `1px solid ${theme.palette.success.light3}`,
                    }}
                    onClick={() => navigate("/assessment-list")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        // jus,
                        width: "100%",
                      }}
                    >
                      <Typography variant="md" type="medium" noWrap>
                        {each?.test_name}
                      </Typography>

                      <Box>
                        <Typography
                          variant="sm"
                          type="regular"
                          color="text.secondary"
                        >
                          {each?.subject?.length > 10
                            ? each?.subject?.slice(0, 10) + "..."
                            : each?.subject}{" "}
                          • Scheduled on{" "}
                          {moment(each?.date).format("DD MMM'YY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,

  branchDetail: state.user?.branch,
});
export default connect(mapStateToProps)(AssesmentDetail);
