import React from "react";
import moment from "moment";
import { Avatar, Box, Card, Divider, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import theme from "util/theme";
import { mixpanelTracker } from "util/MixpanelTracking/Tracker";

function DiaryCard({ diaryData, subject, date }) {
  const navigate = useNavigate();
  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}`,
    };
  }
  return (
    <Card
      sx={{
        // minWidth: 275,
        width: "100%",
        mb: 2,
        border: `1px solid ${theme.palette.border.main}`,
        backgroundColor: theme.palette.base.white,
        borderRadius: 4,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
      onClick={() => {
        mixpanelTracker("diary_view", {
          data: {
            type: "daily_diary",
            subject: subject,
          },
        });
        navigate(
          {
            pathname: "/diary/detail",
            search: `?${createSearchParams({
              subject: subject?.subject_id,
              section: diaryData?.section_mapping_id,
              section_id: diaryData?.section_id,
              date: date,
            })}`,
          },
          {
            state: {
              diaryData: diaryData,
              subject: subject,
            },
          }
        );
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Typography variant="md" type="medium" color={theme.palette.gray.dark2}>
          {subject?.subject_name}
        </Typography>
        <Typography
          variant="xs"
          type="regular"
          color={theme.palette.gray.dark2}
        >
          {diaryData?.periods_data?.map((item) => item?.period_name).toString()}
        </Typography>
      </Box>

      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: theme.palette.gray.light4,
            p: 2,
            borderRadius: 4,
          }}
        >
          {diaryData?.periods_data?.length > 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Typography
                variant="md"
                type="medium"
                color={theme.palette.gray.dark3}
                sx={{ textTransform: "capitalize" }}
              >
                {diaryData?.periods_data
                  ? diaryData?.periods_data[0].key_concept__topic_name
                  : ""}
              </Typography>

              <Typography
                variant="sm"
                type="regular"
                color={theme.palette.gray.main}
                sx={{ textTransform: "capitalize" }}
              >
                Chapter:{" "}
                {diaryData?.periods_data
                  ? diaryData?.periods_data[0]?.chapter__chapter_name
                  : ""}
              </Typography>

              {(diaryData?.hw_due_date || diaryData?.documents?.length > 0) && (
                <Box sx={{ pt: 1 }}>
                  <Divider sx={{ background: theme.palette.gray.light3 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    {diaryData?.hw_due_date && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.dark1,
                            fontSize: 14,
                          }}
                        />
                        <Typography variant="sm" type="regular">
                          Homework assigned
                        </Typography>
                      </Box>
                    )}
                    {diaryData?.documents?.length > 0 && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.dark1,
                            fontSize: 14,
                          }}
                        />
                        <Typography variant="sm" type="regular">
                          {diaryData?.documents?.length}{" "}
                          {diaryData?.documents?.length === 1
                            ? "file"
                            : "files"}{" "}
                          attached
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              {diaryData?.hw_description ? (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                >
                  <Typography
                    variant="md"
                    type="medium"
                    color={theme.palette.gray.dark3}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {diaryData?.teacher_report?.homework}
                  </Typography>

                  {diaryData?.hw_due_date && (
                    <Typography
                      variant="sm"
                      type="regular"
                      color={theme.palette.gray.main}
                      sx={{ textTransform: "capitalize" }}
                    >
                      Due date:{" "}
                      {moment(diaryData?.hw_due_date).format("DD/MM/YYYY")}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                >
                  {" "}
                  <Typography
                    variant="md"
                    type="medium"
                    color={theme.palette.gray.dark3}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Notes: {diaryData?.teacher_report?.summary}
                  </Typography>
                </Box>
              )}

              {(diaryData?.hw_due_date || diaryData?.documents?.length > 0) && (
                <Box sx={{ pt: 1 }}>
                  <Divider sx={{ background: theme.palette.gray.light3 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    {diaryData?.hw_due_date && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.dark1,
                            fontSize: 14,
                          }}
                        />
                        <Typography variant="sm" type="regular">
                          Homework assigned
                        </Typography>
                      </Box>
                    )}
                    {diaryData?.documents?.length > 0 && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CheckCircleRoundedIcon
                          sx={{
                            color: theme.palette.success.dark1,
                            fontSize: 14,
                          }}
                        />
                        <Typography variant="sm" type="regular">
                          {diaryData?.documents?.length}{" "}
                          {diaryData?.documents?.length === 1
                            ? "file"
                            : "files"}{" "}
                          attached
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 2, width: "100%" }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Avatar
              {...stringAvatar(`${diaryData?.teacher_name}`)}
              sx={{ width: 32, height: 32 }}
            />
            <Box display="flex" flexDirection="column">
              <Typography
                variant="xs"
                type="regular"
                color={theme.palette.gray.dark2}
              >
                {diaryData?.teacher_name}
              </Typography>
              <Typography
                variant="xs"
                type="regular"
                color={theme.palette.gray.light1}
              >
                {moment(diaryData?.created_at).format("DD MMM'YY, HH:mm A")}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              background: theme.palette.gray.light5,
              px: 1.5,
              py: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <Typography
              variant="xs"
              type="regular"
              color={theme.palette.gray.dark2}
            >
              {diaryData?.substitute ? "Substitute diary" : "Daily diary"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DiaryCard;
