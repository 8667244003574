import React, { useState } from "react";

// MUI Stuff
import {
  Paper,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from "@mui/material";

import { Link, NavLink, Outlet } from "react-router-dom";

// Icons
import {
  HomeRounded as HomeRoundedIcon,
  PersonRounded as PersonRoundedIcon,
  LocalLibraryRounded as LocalLibraryRoundedIcon,
} from "@mui/icons-material";
import theme from "../util/theme";
import { connect } from "react-redux";
import ProfileDrawer from "./Home/ProfileDrawer";

//Files

function Dashboard(props) {
  const [value, setValue] = React.useState(0);
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);
  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ p: 0 }}>
        <Outlet />
        <Box sx={{ height: "56px" }} />
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: "0px -2px 36px 0px rgba(21, 94, 239, 0.16)",
          }}
          elevation={3}
        >
          <BottomNavigation showLabels value={value}>
            <BottomNavigationAction
              component={NavLink}
              to="/dashboard/home"
              label="Home"
              value="home"
              icon={<HomeRoundedIcon />}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "none",
                      color: theme.palette.primary.main,
                    }
                  : { textDecoration: "none" }
              }
            />
            {props.user?.user_level == 13 ? (
              props.userNavigationData
                ?.find((o) => o.parent_modules === "Diary")
                ?.child_module.find((o) => o.child_name === "Student Diary") ? (
                <BottomNavigationAction
                  component={NavLink}
                  to="/dashboard/diary"
                  label="Diary"
                  value="diary"
                  icon={<LocalLibraryRoundedIcon />}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: "none",
                          color: theme.palette.primary.main,
                        }
                      : { textDecoration: "none" }
                  }
                />
              ) : null
            ) : null}

            {props.user?.user_level == 11 ? (
              props.userNavigationData
                ?.find((o) => o.parent_modules === "Time Table")
                ?.child_module.find(
                  (o) => o.child_name === "Teacher Time Table"
                ) ? (
                <BottomNavigationAction
                  component={NavLink}
                  to="/dashboard/timetable-teacher"
                  label="Time Table"
                  value="timetable"
                  icon={<LocalLibraryRoundedIcon />}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: "none",
                          color: theme.palette.primary.main,
                        }
                      : { textDecoration: "none" }
                  }
                />
              ) : null
            ) : null}

            <BottomNavigationAction
              component={NavLink}
              to="/dashboard/profile"
              label="Profile"
              value="profile"
              icon={<PersonRoundedIcon />}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "none",
                      color: theme.palette.primary.main,
                    }
                  : { textDecoration: "none" }
              }
            />
          </BottomNavigation>
        </Paper>
      </Container>
      {showProfileDrawer && (
        <ProfileDrawer
          showProfileDrawer={showProfileDrawer}
          setShowProfileDrawer={setShowProfileDrawer}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userNavigationData: state.user.userNavigationData,
  moduleId: state.user.moduleId,
});

export default connect(mapStateToProps)(Dashboard);
