import React, { useEffect, useState } from "react";

// MUI Stuff
import {
  Avatar,
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

//Redux
import { connect } from "react-redux";

import axiosInstance from "util/axiosInstance";
import { API_FINANCIAL, API_MASTER } from "apiConfig";
import theme from "util/theme";
import moment from "moment";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
function PopUp(props) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [todayDate, setTodaydate] = useState(moment().format("DD-MM-YYYY"));
  const [userData, setUserData] = useState();
  const [studentFeeData, setStudentFeeData] = useState([]);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const lastPopUpDetails = localStorage.getItem("last_due_pop_details")
    ? JSON.parse(localStorage.getItem("last_due_pop_details"))
    : {};
  const financeSessionId = localStorage.getItem("financeSessionId");
  const { setAlert } = React.useContext(AlertNotificationContext);

  const [popUpDetails, setPopUpDetails] = useState();

  const financeBranchId = props?.financeBranchId;

  useEffect(() => {
    if (
      props?.user?.erp &&
      props?.academicYear?.session_year &&
      props?.branch?.branch?.id
    ) {
      fetchPopUpDetails();
    }
  }, [
    props?.user?.erp,
    props?.academicYear?.session_year,
    props?.branch?.branch?.id,
  ]);

  useEffect(() => {
    if (studentFeeData?.length <= 0) {
      setShowPopUp(false);
    } else if (
      studentFeeData?.reduce((acc, obj) => acc + (obj?.balance || 0), 0) == 0
    ) {
      setShowPopUp(false);
    } else {
      setShowPopUp(true);
    }
  }, [studentFeeData]);

  const fetchPopUpDetails = () => {
    const params = {
      branch_id: financeBranchId,
      finance_session_year: props?.academicYear?.session_year,
      erp_id: props?.user?.erp,
    };
    axiosInstance
      .get(`${API_FINANCIAL}/apiV1/popup-details/`, { params })
      .then((res) => {
        const arr = res?.data?.filter((item, ind) => item?.type == "due");
        if (
          arr?.length > 0 &&
          ((arr[0]?.id == lastPopUpDetails?.id &&
            (arr[0]?.is_compulsory || lastPopUpDetails?.date != todayDate)) ||
            arr[0]?.id != lastPopUpDetails?.id)
        ) {
          fetchUserData();
          fetchStudentFeeData();
          setPopUpDetails(arr[0]);
        } else {
          const params = {
            id: lastPopUpDetails?.id ?? null,
            date: lastPopUpDetails?.date ?? todayDate,
          };
          localStorage.setItem("last_due_pop_details", JSON.stringify(params));
        }
      })
      .catch((err) => {
        console.log(err, "popUp error");
      })
      .finally(() => {});
  };
  const fetchUserData = () => {
    const params = {
      branch: props?.branch?.branch?.id,
      session_year: props?.academicYear?.session_year,
      erp_id: props?.user?.erp,
    };
    axiosInstance
      .get(`${API_MASTER + "/erp_user/user-information/"}`, {
        params: { ...params },
      })
      .then((res) => {
        setUserData(res.data.result.results[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const fetchStudentFeeData = () => {
    const params = {
      branch_id: financeBranchId,
      session_year: props?.academicYear?.session_year,
      erp_id: props?.user?.erp,
    };
    axiosInstance
      .get(`${API_FINANCIAL + "/apiV1/student-acad-fee-tilldate/"}`, {
        params: { ...params },
      })
      .then((res) => {
        setStudentFeeData(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const generatePaymentLink = (userDetails = userData) => {
    if (studentFeeData?.length > 0) {
      setIsGeneratingLink(true);
      let academicList = studentFeeData
        ?.filter((each) => each?.fee_type === "academic")
        .map((each) => each.installments)
        .flat(Infinity);
      let transportList = studentFeeData
        ?.filter((each) => each?.fee_type === "transport")
        .map((each) => each.installments)
        .flat(Infinity);

      let obj = {};
      obj.branch_id = financeBranchId;
      obj.erp_id = userDetails?.erp_id;
      obj.grade_id = userDetails?.mapping_bgs?.grade?.id;
      obj.section_id = userDetails?.mapping_bgs?.section?.id;
      obj.finance_session_year = financeSessionId;
      obj.amount_total = studentFeeData?.reduce(
        (acc, obj) => acc + (obj?.balance || 0),
        0
      );
      obj.amount_paid = studentFeeData?.reduce(
        (acc, obj) => acc + (obj?.balance || 0),
        0
      );
      obj.is_pwa = 1;
      obj.amount_discount = 0;
      obj.collected_by = userDetails?.erp_id;
      obj.payment_method = 4;
      obj.wallet_amount = 0;
      obj.branch_name = props?.branch?.branch?.branch_name;
      obj.student_name = userDetails?.name;
      obj.father_name = userDetails?.father_name;
      obj.is_student_paying = true;

      var renamedCollectedDataTransport = [];

      for (let i = 0; i < transportList?.length; i++) {
        var renamedObj = {};
        renamedObj.termName = transportList[i].fee_group__term_fee__term_name;
        renamedObj.fee = transportList[i].fee;
        renamedObj.typeName = transportList[i].fee_type__fee_type_name;
        renamedObj.typeId = transportList[i].fee_type__id;
        renamedObj.feePaid = transportList[i].fee_type_paid_amount;
        renamedObj.fineAmount = transportList[i].fine_amount;
        renamedObj.balance = transportList[i].fee_balance;

        renamedObj.payingAmount = transportList[i].fee_balance;
        renamedObj.discountValue = 0;
        renamedObj.collectId = transportList[i].id;
        renamedObj.isPartial = transportList[i].fee_type__partial_payment;
        renamedObj.isChecked = transportList[i]?.is_backdue ? true : false;
        renamedObj.feeId = transportList[i].id;

        renamedObj.finance_session_name =
          transportList[i].finance_session_year__session_year;
        renamedObj.is_manual = transportList[i].is_manual;
        renamedObj.finance_session_year = transportList[i].finance_session_year;
        renamedObj.grade_id = userDetails?.mapping_bgs?.grade?.id;
        renamedObj.section_id = userDetails?.mapping_bgs?.section?.id;
        renamedObj.feeSegment = transportList[i]?.type;
        renamedObj.due_date = transportList[i]?.due_date;
        renamedCollectedDataTransport.push(renamedObj);
      }

      var renamedCollectedDataAcad = [];

      for (let i = 0; i < academicList?.length; i++) {
        var renamedObj = {};
        renamedObj.termName = academicList[i].fee_group__term_fee__term_name;
        renamedObj.fee = academicList[i].fee;
        renamedObj.typeName = academicList[i].fee_type__fee_type_name;
        renamedObj.typeId = academicList[i].fee_type__id;
        renamedObj.feePaid = academicList[i].fee_type_paid_amount;
        renamedObj.fineAmount = academicList[i].fine_amount;
        renamedObj.balance = academicList[i].fee_balance;

        renamedObj.payingAmount = academicList[i].fee_balance;
        renamedObj.discountValue = 0;
        renamedObj.collectId = academicList[i].id;
        renamedObj.isPartial = academicList[i].fee_type__partial_payment;
        renamedObj.isChecked = academicList[i]?.is_backdue ? true : false;
        renamedObj.feeId = academicList[i].id;

        renamedObj.finance_session_name =
          academicList[i].finance_session_year__session_year;
        renamedObj.is_manual = academicList[i].is_manual;
        renamedObj.finance_session_year = academicList[i].finance_session_year;
        renamedObj.grade_id = userDetails?.mapping_bgs?.grade?.id;
        renamedObj.section_id = userDetails?.mapping_bgs?.section?.id;
        renamedObj.feeSegment = academicList[i]?.type;
        renamedObj.due_date = academicList[i]?.due_date;
        renamedCollectedDataAcad.push(renamedObj);
      }
      obj.fee_info =
        academicList?.length > 0
          ? JSON.stringify(renamedCollectedDataAcad)
          : "";
      obj.transport_fee_details =
        transportList?.length > 0
          ? JSON.stringify(renamedCollectedDataTransport)
          : "";

      axiosInstance
        .post(`${API_FINANCIAL}/apiV1/generate-multifee-payment-link/`, obj)
        .then((res) => {
          setAlert("success", "Payment Link Generated!");
          if (res?.data?.payment_link) {
            window.open(`${res?.data?.payment_link}`, "_self");
          }
        })
        .catch((err) => {
          console.log("payment link", err);
          setAlert("error", "Failed to generate payment link!");
        })
        .finally(() => {
          setIsGeneratingLink(false);
        });
    }
  };
  const handleNext = (close = false) => {
    const params = {
      id: popUpDetails?.id,
      date: todayDate,
    };
    localStorage.setItem("last_due_pop_details", JSON.stringify(params));
    if (popUpDetails?.type == "due" && !close) {
      generatePaymentLink();
    } else {
      setShowPopUp(false);
    }
  };
  return (
    <>
      <Drawer
        anchor="bottom"
        open={showPopUp}
        onClose={() => {
          if (!popUpDetails?.is_compulsory) {
            handleNext(true);
          }
        }}
        className="th-drawer-white"
      >
        {!popUpDetails?.is_compulsory ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "transparent",
              p: 1,
              position: "relative",
              height: "5",
            }}
            onClick={() => {
              handleNext(true);
            }}
          >
            <IconButton
              aria-label="close drawer"
              size="large"
              sx={{ mt: 1, p: 1.5 }}
            >
              <CancelRoundedIcon fontSize="large" />
            </IconButton>
          </Box>
        ) : null}
        <Card
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              pb: 2,
              backgroundColor: "#ffffff",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.gray.light2,
              }}
            >
              <img
                src={popUpDetails?.background_image ?? ""}
                style={{
                  maxHeight: "40vh",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="Pop-up"
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  transform: "translateY(50%)",
                  alignSelf: "center",
                  marginTop: "auto",
                }}
              >
                <Avatar
                  src={popUpDetails?.feature_image ?? ""}
                  sx={{
                    border: 2,
                    bgcolor: theme.palette.gray.light2,
                    borderColor: theme.palette.base.white,
                    height: 64,
                    width: 64,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: 4,
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="xl" type="medium" component="div">
                {popUpDetails?.heading ?? "Heading"}
              </Typography>
              <Typography variant="md" type="semibold" component="div">
                {popUpDetails?.sub_heading ?? "Sub Heading"}
              </Typography>
              <Typography variant="md" component="div" sx={{ mt: 1 }}>
                {popUpDetails?.description ?? "Description"}
              </Typography>
            </Box>
            <Box width={"100%"} sx={{ px: 2 }}>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={() => {
                  handleNext();
                }}
                disabled={isGeneratingLink}
              >
                Pay ₹{" "}
                {studentFeeData?.reduce(
                  (acc, obj) => acc + (obj?.balance || 0),
                  0
                )}
              </Button>
            </Box>
          </Box>
        </Card>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state?.user?.userData,
  academicYear: state?.user?.currentAcadYear,
  branch: state?.user?.branch,
});

export default connect(mapStateToProps)(PopUp);
