import {
  Box,
  Typography,
  Avatar,
  Paper,
  Container,
  CardContent,
  Card,
  Divider,
  Badge,
  Chip,
} from "@mui/material";
import { AMAZON_S3 } from "apiConfig";
import React, { useState } from "react";
import { connect } from "react-redux";
import { loginWithMobileNo } from "redux/actions/userActions";

import theme from "util/theme";
import AppBarBack from "components/AppBarBack";

import { useNavigate } from "react-router-dom";
import SimpleSnackbar from "components/Snackbar";
import {
  ArrowBackIosNewRounded,
  KeyboardArrowRight,
} from "@mui/icons-material";

function SelectProfileDialog(props) {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("warning");

  return (
    <Container
      type="main"
      sx={{
        height: "100vh",
        p: 0,
        m: 0,
      }}
    >
      <Box
        sx={{
          height: "200px",
          py: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Typography sx={{ textAlign: "center" }} variant="heading1">
          👋 Welcome Back
        </Typography>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: "auto",
          height: "calc(100vh - 200px)",
          overflowY: "scroll",
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            {/* <ArrowBackIosNewRounded
              sx={{ p: 0.3 }}
              onClick={() => navigate(-1)}
            /> */}
            <Typography
              variant="xl"
              type="semibold"
              sx={{
                ml: 1,
              }}
            >
              Select profile
            </Typography>
          </Box>
          <Box
            sx={{
              py: 2,
              px: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              borderRadius: 4,
            }}
          >
            {props?.userList?.map((each) => (
              <Box
                sx={{
                  borderRadius: 4,
                  border: theme.border.main,
                  backgroundColor: theme.palette.base.white,
                  position: "relative",
                  pt:
                    (each?.roles__role_name || each?.role) &&
                    each?.user__level__user_level != 13
                      ? 1.5
                      : 0.5,
                }}
                key={each?.erp_id}
                onClick={() => {
                  const userData = {
                    contact: props?.contact,
                    erp_id: each?.erp_id,
                    hmac: each?.hmac,
                  };
                  props.loginWithMobileNo(
                    userData,
                    navigate,
                    setOpenSnackbar,
                    setSeverity
                  );
                  localStorage.setItem(
                    "selectProfileDetails",
                    JSON.stringify(each)
                  );
                  // props?.setShowProfilesDialog(false);
                }}
                boxShadow={theme.shadows[3]}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    // border: "1px solid red",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // border: "1px solid green",
                    }}
                  >
                    {window.location.href.slice(8, 10) == "qa" ||
                    window.location.href.slice(7, 12) == "local" ? (
                      <Avatar
                        alt={each?.name}
                        src={`${AMAZON_S3.ERP_BUCKET}/dev/media/${each?.profile}`}
                        sx={{ width: 42, height: 42 }}
                      />
                    ) : (
                      <Avatar
                        alt={each?.name}
                        src={`${AMAZON_S3.ERP_BUCKET}/prod/media/${each?.profile}`}
                        sx={{ width: 42, height: 42 }}
                      />
                    )}
                    <Box sx={{ ml: 1.5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          color={theme.palette.gray.dark2}
                          variant="md"
                          type="medium"
                          sx={{
                            wordBreak: "break-all",
                          }}
                        >
                          {each?.name}
                        </Typography>
                        {(each?.roles__role_name || each?.role) &&
                        each?.user__level__user_level != 13 ? (
                          <Box
                            color={theme.palette.gray.main}
                            variant="sm"
                            type="medium"
                            sx={{
                              position: "absolute",
                              top: "2px",
                              right: "4px",
                            }}
                          >
                            <Chip
                              label={each?.roles__role_name || each?.role}
                              sx={{
                                height: "auto",
                                "& .MuiChip-label": {
                                  // fontWeight: "bold",
                                  textTransform: "capitalize",
                                },
                              }}
                            />
                          </Box>
                        ) : null}
                      </Box>
                      <Typography
                        variant="sm"
                        type="medium"
                        color="text.secondary"
                        component={"div"}
                      >
                        {each?.erp_id}
                      </Typography>

                      {each?.user__level__user_level != 13 ? null : (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Typography variant="sm" color="text.secondary">
                            {each?.grade_name}
                          </Typography>
                          <Typography
                            sx={{ pl: 1 }}
                            variant="sm"
                            color="text.secondary"
                          >
                            {each?.section_name}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <KeyboardArrowRight sx={{ p: 0.1 }} />
                </Box>
                <Divider />
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: theme.palette.gray.light4,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt={"scl_logo"}
                    src={props?.schoolDetails?.school_logo}
                    sx={{ width: 28, height: 24 }}
                  />
                  <Typography
                    variant="md"
                    color={theme.palette.gray.dark3}
                    sx={{ pl: 1 }}
                  >
                    {each?.branch_name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
      {/* </Box> */}

      {/* <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      /> */}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state?.user,
  UI: state?.UI,
  schoolDetails: state?.user?.schoolDetails,
  userList: state?.user?.userList,
  contact: state?.user?.userContact,
});
const mapActionsToProps = {
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(SelectProfileDialog);
