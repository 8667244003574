import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import theme from "util/theme";

function LoginWithOtp(props) {
  useEffect(() => {
    let timer = 0;
    if (props?.seconds > 0) {
      timer = setInterval(() => {
        props?.setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      props?.setSeconds(0);
      clearInterval(timer);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, [props?.seconds]);

  return (
    <Box
      component="form"
      onSubmit={props?.handleOTP}
      sx={{
        mt: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="erp"
        label="ERP ID"
        name="erp"
        autoComplete="erp"
        value={props?.erp}
        InputProps={{
          style: { backgroundColor: theme.palette.base.white },
        }}
        onChange={(e) => props?.setErp(e.target.value)}
        autoFocus
      />
      {props?.OtpSentofOTP && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="otp"
          label="OTP"
          name="otp"
          // autoComplete="erp"
          autoFocus
        />
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        size="large"
        disabled={props.UI.loading}
      >
        {props?.OtpSentofOTP ? "Sign In" : "Send OTP"}
        {props.UI.loading && <CircularProgress size={20} sx={{ px: 2 }} />}
      </Button>
      {props?.sendOtpBtn &&
        (props?.seconds ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="subtitle2">
              <b>{props?.seconds}</b> Sec Remaining
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              // fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              disabled={props.UI.loading}
              onClick={() => {
                props?.setOtpSentofOTP(false);
                props?.handleOTP();
              }}
            >
              Resend OTP
              {props.UI.loading && (
                <CircularProgress size={20} sx={{ px: 2 }} />
              )}
            </Button>
          </Box>
        ))}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(LoginWithOtp);
