import React from "react";

// MUI Stuff
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import NotAuthImg from "../assets/img/not_auth.svg";

function NotAuth(props) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          borderRadius: 2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
            borderRadius: 2,
            backgroundColor: "#fff",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Box pt={30} pb={5}>
              <div
                style={{
                  backgroundImage: `url(${NotAuthImg})`,
                  height: 150,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </Box>
            <Typography variant="subtitle2">
              <b>Unauthorized</b>
            </Typography>
            <Typography variant="caption">
              You don't have the access to this url !
            </Typography>
            <Box p={2} />
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NotAuth;
