import { useSearchParams } from "react-router-dom";

export function useSearchParamsState(searchParamName, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();
  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState =
    acquiredSearchParam !== null && acquiredSearchParam !== void 0
      ? acquiredSearchParam
      : defaultValue;
  const setSearchParamsState = (newState) => {
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce(
        (o, [key, value]) =>
          Object.assign(Object.assign({}, o), { [key]: value }),
        {}
      ),
      { [searchParamName]: newState }
    );
    setSearchParams(next, { replace: true });
  };
  return [searchParamsState, setSearchParamsState];
}
