import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  SwipeableDrawer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import theme from "../../../util/theme";

import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 0,
  left: "calc(50% - 15px)",
}));

function DiaryFilterDrawer(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    applyFilter,
    showFilterDrawer,
    setShowFilterDrawer,
  } = props;
  return (
    <>
      <SwipeableDrawer
        onOpen={() => {}}
        anchor="bottom"
        open={showFilterDrawer}
        onClose={() => {
          setShowFilterDrawer(false);
        }}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <Card
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            overflow: "auto",
          }}
        >
          <CardContent>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                p: 1,
                position: "relative",
              }}
            >
              <Puller />
            </Box>
            <Typography sx={{ py: 1, color: "text.secondary" }}>
              Filter by:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                pb: 2,
                m: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ minWidth: "100%", maxWidth: "100%", m: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            // display="none"
                            label="Select Start Date"
                            minDate={dayjs()
                              .subtract(1, "year")
                              .format("YYYY-MM-DD")}
                            disableFuture={true}
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue.format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            // display="none"
                            label="Select End Date"
                            minDate={startDate}
                            disableFuture={true}
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue.format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  applyFilter();
                }}
                sx={{ width: "100%" }}
                size="large"
              >
                Filter
              </Button>
            </Box>
          </CardContent>
        </Card>
      </SwipeableDrawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  moduleId: state.user.moduleId,
  user: state.user.userData,
  branchId: state.user.branchId,
  academicYear: state.user?.branch?.session_year,
  userNavigationData: state.user.userNavigationData,
});
export default connect(mapStateToProps)(DiaryFilterDrawer);
