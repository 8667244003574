import React from "react";

// MUI Stuff
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";

//Redux
import { connect } from "react-redux";

//Icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

//Files
import theme from "util/theme";

function LoginWithErpAndPass(props) {
  return (
    <Box
      component="form"
      onSubmit={props?.handleSubmit}
      sx={{ mt: 1, p: 3, display: "flex", flexDirection: "column", gap: 3 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        value={props?.erp}
        onChange={(e) => props?.setErp(e.target.value)}
        id="erp"
        label="ERP ID"
        name="erp"
        InputProps={{
          style: { backgroundColor: theme.palette.base.white },
        }}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        id="password"
        autoComplete="current-password"
        type={props?.showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={props?.handleClickShowPassword}
                onMouseDown={props?.handleMouseDownPassword}
                edge="end"
              >
                {props?.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          style: { backgroundColor: theme.palette.base.white },
        }}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <br />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        size="large"
        disabled={props.UI.loading}
      >
        Login
        {props.UI.loading && <CircularProgress size={20} sx={{ px: 2 }} />}
      </Button>

      <Typography
        sx={{ color: theme.palette.primary.main }}
        onClick={() => {
          props?.setForgotPassFlag(true);
        }}
      >
        Forgot password?
      </Typography>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(LoginWithErpAndPass);
