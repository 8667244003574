import React, { useEffect, useState } from "react";
import { API_REPORT, X_DTS_HOST } from "../../apiConfig";
import {
  Box,
  Typography,
  LinearProgress,
  linearProgressClasses,
  CircularProgress,
  Skeleton,
  circularProgressClasses,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../util/axiosInstance";
import theme from "../../util/theme";
import { connect } from "react-redux";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Attendance(props) {
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState();
  const [attendanceLoading, setAttendanceLoading] = useState(false);

  useEffect(() => {
    fetchAttendance();
  }, []);

  const fetchAttendance = () => {
    setAttendanceLoading(true);
    axiosInstance
      .get(`${API_REPORT}/reports/v1/stu-annual-attreport/`, {
        headers: {
          "X-DTS-Host": X_DTS_HOST,
        },
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setAttendanceData(res?.data?.result);
        }
      })
      .catch((err) => {
        console.log("Attendance fetch error", err);
      })
      .finally(() => {
        setAttendanceLoading(false);
      });
  };

  const AttendanceLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.error.main,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.success.main,
    },
  }));

  return (
    props?.sessionYear?.id === props?.current_acad_year?.id && (
      <Box
        className="color-white-bg "
        sx={{
          mt: 2,
          width: "100%",
          // borderRadius: 2,
          overflow: "hidden",
          p: 1,
          px: 1.5,
        }}
      >
        <Box sx={{ py: 0.5, pb: 2, px: 0.5 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ alignItems: "center" }}
          >
            <Typography variant="xl" type="medium">
              My attendance
            </Typography>
            <ArrowForwardIosIcon
              sx={{ fontSize: 16, color: theme.palette?.gray?.main }}
              onClick={() => navigate("/attendance")}
            />
          </Box>
        </Box>

        <Box
          className="bg-white"
          sx={{
            p: 1,
            // mx: 1,
            flex: 1,
            // flexBasis: "40vw",
            // maxWidth: "200px",
            // height: "100%",
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            gap: 1,
            justifyContent: "space-around",
            lineHeight: 0,
            backgroundColor: `rgba(0,0,0,0,0.5)`,
            border: "1px solid #F0F0F0",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
            // border: "2px solid red",
          }}
          // onClick={() => navigate("/calendar")}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Box sx={{ position: "relative" }}>
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    }}
                    size={40}
                    thickness={6}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    disableShrink
                    value={attendanceData?.att_ptnge?.split("%")[0]}
                    color="error"
                    sx={{
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={40}
                    thickness={6}
                    {...props}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="md" type="semibold">
                  {attendanceData?.att_ptnge}
                </Typography>
                <Typography
                  variant="xs"
                  type="regular"
                  color={theme.palette?.gray?.main}
                >
                  Overall attendance
                </Typography>
              </Box>
            </Box>

            {/* <Box>
              <Divider orientation="vertical" />
            </Box> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                  margin: "auto 0",
                }}
              >
                <EventAvailableOutlinedIcon
                  sx={{
                    color: theme.palette?.gray?.main,
                    fontSize: 16,
                  }}
                />
                <Typography
                  variant="xs"
                  type="regular"
                  color={theme.palette?.gray?.main}
                >
                  {attendanceData?.presents} Days Present
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                <EventBusyOutlinedIcon
                  sx={{
                    color: theme.palette?.gray?.main,
                    fontSize: 16,
                  }}
                />
                <Typography
                  variant="xs"
                  type="regular"
                  color={theme.palette?.gray?.main}
                >
                  {attendanceData?.absents} Days Absent
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            my: 1,
            px: 0,
          }}
        >
          <Box
            className="scrollBar-hide"
            sx={{
              display: "flex",
              overflowX: "auto",
              // width: "100%",
              // p: 1,
              pr: 1,
              py: 2,
              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
                flexWrap: "nowrap",
                gap: 1,
              }}
            >
              {attendanceLoading && (
                <Box display="flex">
                  <Skeleton width={100} height={100} sx={{ mr: 1 }} />
                  <Skeleton width={100} height={100} sx={{ mr: 1 }} />
                  <Skeleton width={100} height={100} />
                </Box>
              )}
              {attendanceData?.montly_attendance
                ?.slice(-3)
                ?.map((each, index) => {
                  return (
                    <Box
                      key={index}
                      className="bg-white"
                      sx={{
                        px: 2,
                        py: 1,
                        ml: 0.5,
                        width: "50vw",
                        maxWidth: "400px",
                        borderRadius: 4,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                        lineHeight: 0,
                        backgroundColor: `rgba(0,0,0,0,0.5)`,
                        border: "1px solid #EAECF0",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          // jus,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="md" type="medium" noWrap>
                            {each?.month_name} {each?.year}
                          </Typography>
                          <Box sx={{ position: "relative" }}>
                            <CircularProgress
                              variant="determinate"
                              sx={{
                                color: (theme) =>
                                  theme.palette.grey[
                                    theme.palette.mode === "light" ? 200 : 800
                                  ],
                              }}
                              size={20}
                              thickness={6}
                              {...props}
                              value={100}
                            />
                            <CircularProgress
                              variant="determinate"
                              disableShrink
                              value={
                                each?.attendance_details?.present_percentage
                              }
                              color="success"
                              sx={{
                                animationDuration: "550ms",
                                position: "absolute",
                                left: 0,
                                [`& .${circularProgressClasses.circle}`]: {
                                  strokeLinecap: "round",
                                },
                              }}
                              size={20}
                              thickness={6}
                              {...props}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <EventAvailableOutlinedIcon
                              sx={{
                                color: theme.palette?.gray?.main,
                                fontSize: 16,
                              }}
                            />
                            <Typography
                              variant="sm"
                              type="regular"
                              color={theme.palette?.gray?.main}
                            >
                              {parseFloat(
                                each?.attendance_details?.present_percentage?.toFixed(
                                  2
                                )
                              )}
                              %
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Divider
                              orientation="vertical"
                              sx={{ height: "70%" }}
                            />
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <EventBusyOutlinedIcon
                              sx={{
                                color: theme.palette?.gray?.main,
                                fontSize: 16,
                              }}
                            />
                            <Typography
                              variant="sm"
                              type="regular"
                              color={theme.palette?.gray?.main}
                            >
                              {(
                                100 -
                                each?.attendance_details?.present_percentage
                              ).toFixed(2)}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,
  sessionYear: state.user?.branch?.session_year,
  branchDetail: state.user?.branch,
  gradeDetail: state.user,
});

export default connect(mapStateToProps)(Attendance);
