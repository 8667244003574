import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoutes = ({ authenticated, children, ...rest }) => {
  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};
const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

PrivateRoutes.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(PrivateRoutes);
