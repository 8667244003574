import { HIDE_ALERT, SHOW_ALERT } from "./alert-types";

const alertReducer = (state, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
        isShown: true,
        showCloseIcon: action.payload.showCloseIcon,
        moreAction: action.payload.moreAction,
      };

    case HIDE_ALERT:
      return {
        ...state,
        isShown: false,
      };

    default:
      return { ...state };
  }
};

export default alertReducer;
