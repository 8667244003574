import { SET_ACADEMIC_YEAR } from "../types";

const initialState = {
  academicYear: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACADEMIC_YEAR:
      return {
        ...state,
        academicYear: action.payload,
      };
    default:
      return state;
  }
}
