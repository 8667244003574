import React from "react";
import moment from "moment";
import { Avatar, Box, Card, Divider, Typography } from "@mui/material";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import theme from "util/theme";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { mixpanelTracker } from "util/MixpanelTracking/Tracker";

function GeneralDiaryCard({ diaryData, setShowDrawer, setDrawerData }) {
  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}`,
    };
  }
  return (
    <Card
      sx={{
        // minWidth: 275,
        width: "100%",
        mb: 2,
        border: `1px solid ${theme.palette.border.main}`,
        backgroundColor: theme.palette.base.white,
        borderRadius: 4,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
      onClick={() => {
        setShowDrawer(true);
        setDrawerData(diaryData);
        mixpanelTracker("diary_view", {
          type: "general_diary",
          title: diaryData?.title,
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Typography
          variant="md"
          type="medium"
          color={theme.palette.gray.dark2}
          sx={{ textTransform: "capitalize" }}
        >
          {diaryData?.title}
        </Typography>
      </Box>
      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: theme.palette.gray.light4,
            p: 2,
            borderRadius: 4,
            boxShadow: theme.shadows[1],
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {/* <Typography
                variant="md"
                type="medium"
                color={theme.palette.gray.dark3}
                sx={{ textTransform: "capitalize" }}
              >
                {diaryData?.title}
              </Typography> */}

            <Typography
              variant="sm"
              type="regular"
              color={theme.palette.gray.main}
              sx={{ textTransform: "capitalize" }}
            >
              {diaryData?.message}
            </Typography>

            {(diaryData?.hw_due_date || diaryData?.documents?.length > 0) && (
              <Box sx={{ pt: 1 }}>
                <Divider sx={{ background: theme.palette.gray.light3 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {diaryData?.hw_due_date && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          color: theme.palette.success.dark1,
                          fontSize: 14,
                        }}
                      />
                      <Typography variant="sm" type="regular">
                        Homework assigned
                      </Typography>
                    </Box>
                  )}
                  {diaryData?.documents?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          color: theme.palette.success.dark1,
                          fontSize: 14,
                        }}
                      />
                      <Typography variant="sm" type="regular">
                        {diaryData?.documents?.length}{" "}
                        {diaryData?.documents?.length === 1 ? "file" : "files"}{" "}
                        attached
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 2, width: "100%" }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Avatar
            {...stringAvatar(`${diaryData?.teacher_name}`)}
            sx={{ width: 32, height: 32 }}
          />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="xs"
              type="regular"
              color={theme.palette.gray.dark2}
            >
              {diaryData?.created_by?.first_name}{" "}
              {diaryData?.created_by?.last_name}
            </Typography>
            <Typography
              variant="xs"
              type="regular"
              color={theme.palette.gray.light1}
            >
              {moment(diaryData?.created_at).format("DD MMM'YY, HH:mm A")}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: theme.palette.gray.light5,
            px: 1.5,
            py: 1,
            display: "flex",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <Typography
            variant="xs"
            type="regular"
            color={theme.palette.gray.dark2}
          >
            {diaryData?.is_substitute_diary
              ? "Substitute dairy"
              : "General diary"}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default GeneralDiaryCard;
