import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PublicRoutes = ({ authenticated, children, ...rest }) => {
  return authenticated ? <Navigate to="/dashboard/home" /> : <Outlet />;
};
const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

PublicRoutes.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(PublicRoutes);
