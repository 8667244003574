import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { API_REPORT, X_DTS_HOST } from "apiConfig";
import axiosInstance from "util/axiosInstance";
import theme from "util/theme";
import LunchDiningOutlinedIcon from "@mui/icons-material/LunchDiningOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import moment from "moment/moment";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { useNavigate } from "react-router-dom";

let urlAddress = window.location.href;
var splitedUrlAddress = urlAddress.split(".");

const isB2b =
  urlAddress.includes("letseduvate.com") &&
  splitedUrlAddress[0]?.split("//")[1] !== "qa" &&
  splitedUrlAddress[0]?.split("//")[1] !== "dev" &&
  splitedUrlAddress[0]?.split("//")[1] !== "orchids"
    ? true
    : false;

function TodayClass(props) {
  const myRef = useRef();

  const [diaryPeriod, setDiaryPeriod] = useState([]);
  const [diaryPeriodLoading, setDiaryPeriodLoading] = useState(false);

  let periodNumber = 0;

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.current_acad_year?.id) {
      fetchDiaryPeriod(
        String(
          20 +
            props?.current_acad_year?.session_year.slice(5, 7) +
            `-${moment().format("MM")}-01`
        )
      );
    }
  }, [props?.current_acad_year?.id]);

  const executeScroll = () => {
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
      alignToTop: "true",
    });
  };

  useEffect(() => {
    if (myRef.current) executeScroll();
  }, [myRef.current]);

  const fetchDiaryPeriod = (date) => {
    setDiaryPeriodLoading(true);
    if (props?.newTimeTable) {
      return;
    }
    axiosInstance
      .get(
        `${API_REPORT}/reports/retrieve_daily_periods/?date=${
          //   moment().format(
          //   "YYYY-MM-DD"
          // )
          date
        }&session_id=${props?.current_acad_year?.id}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setDiaryPeriod(res?.data?.result);
        }
      })
      .catch((err) => {
        console.log("Diary Period fetch error", err);
      })
      .finally(() => {
        setDiaryPeriodLoading(false);
      });
  };

  const getPeriodStatus = (period) => {
    const format = "HH:mm:ss";
    const time = moment(moment(), format);
    const start_time = moment(period?.start_time, format);
    const end_time = moment(period?.end_time, format);
    if (time.isSameOrAfter(end_time)) {
      return "completed";
    } else if (time.isBetween(start_time, end_time)) {
      return "ongoing";
    } else {
      return "upcoming";
    }
  };

  return (
    <>
      <Box
        className="color-white-bg th-timeline"
        sx={{
          width: "100%",
          borderRadius: 3,
          mt: 2,
          p: 1,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        }}
      >
        <Box
          display="flex"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Typography variant="lg" type="semibold">
            Today's class
          </Typography>
          {/* <Box display="flex" alignItems="center">
              <Typography>
                <b>{moment().format("dddd")}</b>
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {moment().format("Do MMM  YYYY")}
              </Typography>
            </Box> */}

          {props?.newTimeTable ? null : (
            <>
              {diaryPeriod?.todays_attendance &&
                diaryPeriod?.todays_attendance !== "N/A" && (
                  <Box
                    sx={{
                      padding: "7px 8px",
                      borderRadius: 3,
                      backgroundColor:
                        diaryPeriod?.todays_attendance === "absent"
                          ? theme.palette.error.main
                          : theme.palette.success.main,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      className="color-white"
                      variant="xs"
                      type="medium"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {/* <CheckBoxRoundedIcon /> */}
                      {diaryPeriod?.todays_attendance &&
                        diaryPeriod?.todays_attendance + " today"}
                    </Typography>
                  </Box>
                )}
            </>
          )}
        </Box>

        {props?.newTimeTable ? (
          <>
            <Box
              className="scrollBar-hide"
              sx={{
                maxHeight: 220,
                overflowY: "scroll",
                py: 1,
                px: 1,
                borderRadius: 2,
                border: `1px solid ${theme.palette?.blueDark.light5}`,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background: theme.palette.gray.light4,
                  p: 2,
                  borderRadius: 4,
                  boxShadow: theme.shadows[1],
                }}
              >
                <Typography
                  variant="sm"
                  color={theme.palette.gray.main}
                  textAlign="center"
                >
                  Timetable coming soon !
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              className="scrollBar-hide"
              sx={{
                maxHeight: 220,
                overflowY: "scroll",
                py: 1,
                px: 1,
                borderRadius: 2,
                border: `1px solid ${theme.palette?.blueDark.light5}`,
              }}
            >
              {diaryPeriodLoading ? (
                <Box sx={{ mx: 1 }}>
                  <Skeleton height={100} />
                  <Skeleton height={100} />
                </Box>
              ) : !diaryPeriod?.classes?.length > 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    background: theme.palette.gray.light4,
                    p: 2,
                    borderRadius: 4,
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <Typography
                    variant="sm"
                    color={theme.palette.gray.main}
                    textAlign="center"
                  >
                    {props?.newTimeTable
                      ? "Timetable coming soon !"
                      : "No Classes for Today"}
                  </Typography>
                </Box>
              ) : (
                diaryPeriod?.classes?.map((each, index) => {
                  if (each?.type !== "Break") {
                    periodNumber += 1;
                  }
                  return (
                    <Box
                      ref={getPeriodStatus(each) == "ongoing" ? myRef : null}
                    >
                      <Box
                        sx={{
                          my: 1,
                        }}
                        className="abcd"
                        key={each?.id}
                      >
                        {each?.type == "Break" ? (
                          <Box
                            display="flex"
                            sx={{
                              justifyContent: "space-between",
                              p: 1,
                              borderRadius: 2,
                              alignItems: "center",
                              backgroundColor:
                                getPeriodStatus(each) == "ongoing"
                                  ? theme.palette.blueDark.light4
                                  : theme.palette.blueDark.light6,
                            }}
                          >
                            <Box
                              display="flex"
                              sx={{
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "space-around",
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <Typography
                                  color={`${
                                    getPeriodStatus(each) == "ongoing"
                                      ? theme.palette.primary.main
                                      : "text.secondary"
                                  } `}
                                  variant="sm"
                                  type="medium"
                                  // color="text.secondary"
                                  sx={{ mr: 1 }}
                                >
                                  Break
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  // border: "2px solid blue",
                                }}
                              >
                                <Typography
                                  variant="sm"
                                  type="regular"
                                  // color="text.secondary"
                                >
                                  {moment(each?.start_time, "hh:mm").format(
                                    "hh:mm"
                                  )}{" "}
                                  -{" "}
                                  {moment(each?.end_time, "hh:mm A").format(
                                    "hh:mm A"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              sx={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "#fff",
                                  width: 56,
                                  height: 56,
                                  borderRadius: "12px",
                                }}
                                variant="rounded"
                              >
                                <LunchDiningOutlinedIcon
                                  sx={{
                                    fontSize: 32,
                                    color: theme.palette?.blueDark.main,
                                  }}
                                />
                              </Avatar>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            //   className={
                            //     moment()
                            //       .format("hh:mm:ss")
                            //       .isBetween(each?.start_time, each?.end_time)
                            //       ? "color1bg"
                            //       : "color-gray-light"
                            //   }
                            display="flex"
                            sx={{
                              justifyContent: "space-between",
                              p: 1,
                              borderRadius: 2,
                              backgroundColor:
                                getPeriodStatus(each) == "ongoing"
                                  ? theme.palette.blueDark.light4
                                  : theme.palette.blueDark.light6,
                            }}
                          >
                            <Box
                              display="flex"
                              sx={{
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "space-around",
                              }}
                            >
                              <Typography
                                color={`${
                                  getPeriodStatus(each) == "ongoing"
                                    ? theme.palette.primary.main
                                    : "text.secondary"
                                } `}
                                variant="sm"
                                type="medium"
                                // color="text.secondary"
                                sx={{ mr: 1 }}
                              >
                                Period {periodNumber}
                              </Typography>

                              <Typography
                                variant="sm"
                                type="regular"
                                // color="text.secondary"
                              >
                                {moment(each?.start_time, "hh:mm").format(
                                  "hh:mm"
                                )}{" "}
                                -{" "}
                                {moment(each?.end_time, "hh:mm A").format(
                                  "hh:mm A"
                                )}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              sx={{
                                flexDirection: "column",
                                alignItems: "end",
                                justifyContent: "space-around",
                              }}
                            >
                              <Typography
                                sx={
                                  {
                                    // color:
                                    //   getPeriodStatus(each) === "ongoing"
                                    //     ? ""
                                    //     : "text.secondary",
                                    // pt: 1,
                                  }
                                }
                                variant="sm"
                                type="medium"
                              >
                                {each?.subject_name?.length > 10
                                  ? each?.subject_name?.slice(0, 10) + "..."
                                  : each?.subject_name}
                              </Typography>
                              {/* <Typography variant="caption" color="text.secondary">
                    <b>{each?.type + " Period"}</b>
                  </Typography> */}
                              {isB2b ? null : (
                                <Typography
                                  sx={{
                                    color:
                                      getPeriodStatus(each) === "ongoing"
                                        ? ""
                                        : "text.secondary",
                                    pt: 1,
                                  }}
                                  variant="sm"
                                  type="regular"
                                >
                                  {each?.teacher_name?.length > 15
                                    ? each?.teacher_name?.slice(0, 15) + ".."
                                    : each?.teacher_name}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                  return (
                    <Timeline
                      className="timeline-item"
                      position="right"
                      key={index}
                    >
                      <TimelineItem
                        // sx={{ m: "0px", py: "0px", pl: "8px" }}
                        sx={{ border: "2px solid red" }}
                        ref={getPeriodStatus(each) == "ongoing" ? myRef : null}
                      >
                        <TimelineOppositeContent
                          sx={{ display: "none" }}
                        ></TimelineOppositeContent>

                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            sx={{
                              backgroundColor:
                                getPeriodStatus(each) === "ongoing"
                                  ? theme.palette.primary.main
                                  : getPeriodStatus(each) === "completed"
                                  ? theme.palette.success.main
                                  : theme.palette.secondary.main,
                            }}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>

                        <TimelineContent
                          className="timeline-content"
                          sx={{ py: "12px", px: 2 }}
                        >
                          <Box
                            sx={{
                              m: 1,
                            }}
                            className="abcd"
                            key={each?.id}
                          >
                            {each?.type == "Break" ? (
                              <Box
                                className={`${
                                  getPeriodStatus(each) == "ongoing"
                                    ? "color1bg"
                                    : "color-gray-light"
                                } `}
                                display="flex"
                                sx={{
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  p: 1,
                                  borderRadius: 2,
                                  height: "3.2rem",
                                }}
                              >
                                <Box
                                  display="flex"
                                  sx={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    <b>
                                      {moment(each?.start_time, "hh:mm").format(
                                        "hh:mm"
                                      )}{" "}
                                      -{" "}
                                      {moment(each?.end_time, "hh:mm A").format(
                                        "hh:mm A"
                                      )}
                                    </b>
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  sx={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ mr: 1 }}
                                  >
                                    <b>BREAK</b>
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                className={`${
                                  getPeriodStatus(each) == "ongoing"
                                    ? "color1bg"
                                    : "color-gray-light"
                                } `}
                                //   className={
                                //     moment()
                                //       .format("hh:mm:ss")
                                //       .isBetween(each?.start_time, each?.end_time)
                                //       ? "color1bg"
                                //       : "color-gray-light"
                                //   }
                                display="flex"
                                sx={{
                                  justifyContent: "space-between",
                                  p: 1,
                                  borderRadius: 2,
                                  // border: "1px solid red",
                                }}
                              >
                                <Box>
                                  <Box display="flex" alignItems="center">
                                    <Typography
                                      color={`${
                                        getPeriodStatus(each) == "ongoing"
                                          ? theme.palette.primary.main
                                          : "text.secondary"
                                      } `}
                                      variant="subtitle2"
                                      // color="text.secondary"
                                      sx={{ mr: 1 }}
                                    >
                                      <b>Period {periodNumber}</b>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      <b>
                                        {moment(
                                          each?.start_time,
                                          "hh:mm"
                                        ).format("hh:mm")}{" "}
                                        -{" "}
                                        {moment(
                                          each?.end_time,
                                          "hh:mm A"
                                        ).format("hh:mm A")}
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          getPeriodStatus(each) === "ongoing"
                                            ? ""
                                            : "text.secondary",
                                        pt: 1,
                                      }}
                                      variant="subtitle2"
                                    >
                                      <b>
                                        {each?.subject_name?.length > 10
                                          ? each?.subject_name?.slice(0, 10) +
                                            "..."
                                          : each?.subject_name}
                                      </b>
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  sx={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    <b>{each?.type + " Period"}</b>
                                  </Typography>
                                  {isB2b ? null : (
                                    <Typography
                                      sx={{
                                        color:
                                          getPeriodStatus(each) === "ongoing"
                                            ? ""
                                            : "text.secondary",
                                        pt: 1,
                                      }}
                                      variant="subtitle2"
                                    >
                                      <b>
                                        {each?.teacher_name?.length > 10
                                          ? each?.teacher_name?.slice(0, 10) +
                                            "..."
                                          : each?.teacher_name}
                                      </b>
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  );
                })
              )}
            </Box>

            {diaryPeriod?.classes?.length > 0 && (
              <Box
                display="flex"
                sx={{
                  justifyContent: "center",
                  p: 1,
                  pt: 2,
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="sm"
                  type="medium"
                  color={theme.palette.gray.dark3}
                >
                  View all class
                </Typography>
                <ArrowForwardIosIcon
                  sx={{ fontSize: 16, color: theme.palette.gray.main }}
                  onClick={() => navigate("/timetable")}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,
  branchDetail: state.user?.branch,
});

export default connect(mapStateToProps)(TodayClass);
