import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";

import CampaignIcon from "@mui/icons-material/Campaign";
import axiosInstance from "../../util/axiosInstance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import theme from "util/theme";

function Announcements(props) {
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState([]);
  const [announcementLoading, setAnnouncementLoading] = useState(false);

  useEffect(() => {
    if (props?.current_acad_year?.id) {
      fetchAnnouncement();
    }
  }, [props?.current_acad_year?.id, props?.sessionYear?.id]);

  const fetchAnnouncement = () => {
    setAnnouncementLoading(true);
    axiosInstance
      .get(
        `announcement/v2/inbox/?session_year=${props?.current_acad_year?.id}&branch_id=${props?.branchDetail?.branch?.id}`
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setAnnouncement(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("Announcement fetch error", err);
      })
      .finally(() => {
        setAnnouncementLoading(false);
      });
  };
  return (
    <Box
      className=" bg-white"
      sx={{
        p: 2,
        // mx: 1,
        width: "70vw",
        maxWidth: "400px",
        // height: "100%",
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        // justifyContent: "space-between",
        lineHeight: 0,
        backgroundColor: `rgba(0,0,0,0,0.5)`,
        boxShadow: "0px 3px 0px 0px #D1E0FF",
        border: `1px solid ${theme.palette?.blueDark?.light4}`,
      }}
      onClick={() => navigate("/announcements")}
    >
      <Box display="flex">
        <Avatar
          sx={{
            bgcolor: theme.palette?.blueDark.light5,
            width: 56,
            height: 56,
            borderRadius: "12px",
          }}
          variant="rounded"
        >
          <CampaignIcon
            sx={{ fontSize: 32, color: theme.palette?.blueDark.main }}
          />
        </Avatar>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {announcementLoading ? (
          <Skeleton width={150} height={30} />
        ) : announcement?.length > 0 ? (
          <>
            {/* <Typography variant="subtitle2" textTransform="upperCase">
                <b>{announcement[0]?.category__category_name}</b>
              </Typography> */}
            <Typography variant="md" type="medium">
              {announcement[0]?.title}
            </Typography>
            <br />
            <Typography variant="sm" color={theme.palette.gray.dark1}>
              {announcement[0]?.content?.length > 15
                ? announcement[0]?.content?.slice(0, 20) + "..."
                : announcement[0]?.content}
            </Typography>
            {/* <Typography variant="caption">
                {moment(announcement[0]?.created_time).format("DD MMM YYYY")}
              </Typography> */}
          </>
        ) : (
          <Typography variant="caption" color="text.secondary">
            No Announcement
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,
  sessionYear: state.user?.branch?.session_year,
  branchDetail: state.user?.branch,
});

export default connect(mapStateToProps)(Announcements);
