import React, { useEffect, useState } from "react";

// MUI Stuff
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Card,
  Paper,
  IconButton,
  Button,
  Skeleton,
  SwipeableDrawer,
  CardContent,
  Divider,
  Container,
  ListItem,
  ListItemText,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

//Redux
import { connect, useSelector } from "react-redux";
import { setAcademicYear } from "../../redux/actions/academicYearActions";
import store from "../../redux/store";
import {
  SET_BRANCH,
  SET_BRANCH_LIST,
  SET_SYSTEM_CONFIG,
} from "../../redux/types";

// Icons
import {
  InfoOutlined as InfoOutlinedIcon,
  AssessmentRounded as AssessmentRoundedIcon,
  SummarizeRounded as SummarizeRoundedIcon,
  LocalLibraryRounded as LocalLibraryRoundedIcon,
  AnnouncementRounded as AnnouncementRoundedIcon,
  CurrencyRupeeRounded as CurrencyRupeeRoundedIcon,
  ImportContactsOutlined as ImportContactsOutlinedIcon,
  HomeWorkRounded as HomeWorkRoundedIcon,
  CalendarMonthRounded as CalendarMonthRoundedIcon,
  AccessTime as AccessTimeIcon,
  ShowChartRounded as ShowChartRoundedIcon,
  ContactSupportRounded as ContactSupportRoundedIcon,
  SettingsSuggestRounded as SettingsSuggestRoundedIcon,
  // Notifications as NotificationsIcon,
  CardMembershipRounded as CardMembershipRoundedIcon,
  DvrRounded as DvrRoundedIcon,
  BlurCircularRounded as BlurCircularRoundedIcon,
  GppGoodRounded as GppGoodRoundedIcon,
  DirectionsBusRounded as DirectionsBusRoundedIcon,
  BusAlertRounded as BusAlertRoundedIcon,
  QrCodeScannerRounded as QrCodeScannerRoundedIcon,
  HubRounded as HubRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  // ScreenSearchDesktopRounded as ScreenSearchDesktopRoundedIcon,
  DashboardRounded as DashboardRoundedIcon,
  PlayCircleOutlined as PlayCircleOutlinedIcon,
  PeopleRounded as PeopleRoundedIcon,
  CallRounded as CallRoundedIcon,
  EmailRounded as EmailRoundedIcon,
  SupportRounded as SupportRoundedIcon,
  Work as WorkIcon,
} from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Files
import LogoName from "../../assets/img/logo.png";
import Ornament from "../../assets/img/Ornament.svg";
import theme from "../../util/theme";
import axiosInstance from "../../util/axiosInstance";
import { AMAZON_S3, API_FINANCIAL, isB2b } from "../../apiConfig";
import SettingsDrawer from "../../components/SettingsDrawer";
import ComingSoonImg from "../../assets/img/coming_soon.svg";
// import myschoolgate from "../../assets/img/schoolgatelogo.png";
// import HBD1 from "../../assets/img/hbd-1.png";

// import ExploreAllComponent from "../StudentDashboard/ExploreAllComponent";
import SimpleSnackbar from "components/Snackbar";
import moment from "moment";
import ProfileDrawer from "./ProfileDrawer";
import Puller from "components/Drawer/Puller";
import StudentDashboard from "screens/StudentDashboard";
import PopUp from "screens/FeePayment/PopUp/PopUp";
import UpcomingCompetition from "screens/Announcements/UpcomingCompetition";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import { truncate } from "lodash";

function Home(props) {
  const navigate = useNavigate();
  // const [academicYearList, setAcademicYearList] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const [doodleLoading, setDoodleLoading] = useState(true);
  const [showDoodle, setShowDoodle] = useState(false);
  const [doodleData, setDoodleData] = useState({});
  const [openDoodleDetails, setOpenDoodleDetails] = React.useState(false);

  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);

  const [showProfileDrawer, setShowProfileDrawer] = useState(false);

  // const [isOptedTransport, setIsOptedTransport] = useState(true);

  const [financeBranchId, setFinanceBranchId] = useState("");

  const [isObservationEnabled, setIsObservationEnabled] = useState(false);

  const [update, setUpdate] = useState(false);

  const onUpdate = () => {
    setUpdate((p) => !p);
  };

  const acadSession = useSelector((store) => store?.user?.branch?.id);

  const [branchStaffs, setBranchStaffs] = useState([]);
  const [branchStaffsLoading, setBranchStaffsLoading] = useState(false);

  useEffect(() => {
    // setIsLoading(true);

    getSystemConfig();

    if (!props?.currentAcadYear) {
      axiosInstance
        .get("/erp_user/list-academic_year/")
        .then((res) => {
          fetchFinancialYearList(res.data?.data[0].session_year);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setIsLoading(false);
        });
    } else {
      fetchFinancialYearList(props?.academicYear?.session_year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBranchStaffDetails = (acadSession) => {
    setBranchStaffsLoading(true);
    axiosInstance
      .get(`erp_user/branch-staff-details/?acad_session=${acadSession}`)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          setBranchStaffs(res?.data?.result);
        } else {
          console.log("error fetching branch staff details", res);
        }
      })
      .catch((err) => {
        console.log("error fetching branch staff details", err);
      })
      .finally(() => {
        setBranchStaffsLoading(false);
      });
  };

  useEffect(() => {
    if (!acadSession || isB2b || props.user?.user_level !== 13) return;
    getBranchStaffDetails(acadSession);
  }, [acadSession]);

  useEffect(() => {
    if (!!props.currentAcadYear?.id) {
      fetchBranchList(props?.currentAcadYear?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBranchList = (
    acadId = props?.currentAcadYear?.id,
    moduleId = props?.moduleId
  ) => {
    axiosInstance
      .get(`/erp_user/branch/?session_year=${acadId}&module_id=${moduleId}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          store.dispatch({
            type: SET_BRANCH_LIST,
            payload: res?.data?.data?.results,
          });
          if (!props?.branch?.branch?.id) {
            store.dispatch({
              type: SET_BRANCH,
              payload: res?.data?.data?.results[0],
            });
          }
        }
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };

  // useEffect(() => {
  //   if (props?.branch && props?.user) {
  //     fetchDoodleAvailability();
  //   }
  // }, [props?.branch, props?.user]);

  const defaultSystemConfig = {
    "tt-enabled-v2": [],
    "hw-video-format": [".mp4", ".MP4"],
    hw_auto_asgn: [],
    anncmt_cumctn_config: [
      {
        is_email: false,
        is_whatsapp: false,
        is_sms: false,
        enbl_brnches: [],
      },
    ],
    diary_branches_new_flow: [],
    doodle_availability: false,
    is_referral: false,
    is_ibook_blocked: false,
    is_observation_enabled: false,
  };

  const getSystemConfig = () => {
    axiosInstance
      .get(`/erp_user/get-system-config/`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          store.dispatch({
            type: SET_SYSTEM_CONFIG,
            payload: res?.data?.result,
          });
          setIsObservationEnabled(res?.data?.result?.is_observation_enabled);
          if (res?.data?.result?.doodle_availability) {
            setShowDoodle(true);
            fetchDoodle();
          } else {
            setDoodleLoading(false);
          }
        } else {
          console.log("Error (get-system-config) : ", res);
          setDoodleLoading(false);
          store.dispatch({
            type: SET_SYSTEM_CONFIG,
            payload: defaultSystemConfig,
          });
        }
      })
      .catch((err) => {
        console.log("Error (get-system-config) : ", err);
        setDoodleLoading(false);
        store.dispatch({
          type: SET_SYSTEM_CONFIG,
          payload: defaultSystemConfig,
        });
      })
      .finally(() => {
        setDoodleLoading(false);
      });
  };

  const fetchFinancialYearList = (sessionYear) => {
    axiosInstance
      .get(`${API_FINANCIAL + "/apiV1/finance-session-year-list/"}`)
      .then((res) => {
        let financialSessionId = res.data?.filter((item) => {
          return item?.session_year === sessionYear;
        })[0]?.id;

        let financialBranchId =
          res.data?.find((item) => {
            return item?.is_current_session;
          })?.branches?.[0]?.branch_id ?? "";

        localStorage.setItem(
          "financeSessionYearList",
          JSON.stringify(res?.data)
        );
        localStorage.setItem("financeSessionId", financialSessionId);
        localStorage.setItem("financeBranchId", financialBranchId);
        setFinanceBranchId(financialBranchId);

        // checkOptedTransport(financialSessionId);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };

  // const checkOptedTransport = (financialSessionId) => {
  //   axiosInstance
  //     .get(
  //       `${API_FINANCIAL}/apiV1/transport-opted/?finanance_session_year_id=${financialSessionId}&branch_id=${props?.branchId}&erp_id=${props?.user?.erp}`
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsOptedTransport(res.data?.is_opted_transport);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error: ", err);
  //     });
  // };

  // const fetchDoodleAvailability = () => {
  //   axiosInstance
  //     .get(`/assessment/check-sys-config/?config_key=doodle_availability`)
  //     .then((res) => {
  //       if (res?.data?.status_code === 200) {
  //         if (res?.data?.result[0] == "True") {
  //           setShowDoodle(true);
  //           fetchDoodle();
  //         } else {
  //           setDoodleLoading(false);
  //         }
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };

  const fetchDoodle = () => {
    axiosInstance
      .get(`/erp_user/fetch-doodle/`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setDoodleData(res?.data?.data);
          if (res?.data?.data?.enable_branches?.length > 0) {
            if (
              (res?.data?.data?.enable_branches || []).some(
                (branchId) => branchId === props?.branch?.id
              )
            ) {
              if (props?.user?.user_level === 13) {
                setShowDoodle(true);
              } else {
                setShowDoodle(false);
              }
            } else {
              setShowDoodle(false);
            }
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setDoodleLoading(false));
  };

  const handleFinance = () => {
    navigate("/fee-payment?tab=0", {
      state: {
        fromDoodle: true,
      },
    });
  };

  const { setAlert } = React.useContext(AlertNotificationContext);

  const platform = localStorage.getItem("platform");

  const copyToClipboard = (text, type) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setAlert("success", `${type} copied to clipboard!`);
    } catch (error) {
      setAlert("error", "Failed to copy text to clipboard.");
      console.log("error - Failed to copy text to clipboard.", error);
    }
  };

  const copyToClipboardUsingNavigator = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      setAlert("success", `${type} copied to clipboard!`);
    } catch (error) {
      setAlert("error", "Failed to copy text to clipboard using navigator");
      console.log("error - Failed to copy text to clipboard.", error);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#fff",
        minHeight: "calc(100vh - 56px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <img
            src={LogoName}
            style={{ height: 32, marginY: "auto" }}
            alt="Eduvate"
          ></img>
          <Box sx={{ display: "flex", alignItems: "center", height: "80%" }}>
            <Divider orientation="vertical" />
          </Box>
          <Box sx={{ pr: 2 }}>
            {props.schoolDetails?.school_logo?.length > 0 && (
              <img
                src={props.schoolDetails?.school_logo}
                height="32px"
                width="32px"
                style={{
                  borderRadius: 2,
                  objectFit: "contain",
                }}
                alt="Sch_logo"
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid var(--blue-dark-100, #D1E0FF)",
            borderRadius: 40,
            p: 0.5,
          }}
        >
          <Box
            color="inherit"
            aria-label="delete"
            size="small"
            onClick={() => {
              setShowSettingsDrawer(true);
            }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Avatar
              sizes="small"
              sx={{
                bgcolor: theme.palette.background.paper,
                height: 30,
                width: 30,
                textTransform: "capitalize",
                color: theme.palette.primary.dark,
              }}
            >
              <SettingsSuggestRoundedIcon fontSize="small" />
            </Avatar>
            <Box px={0.25} />

            <Typography variant="sm">
              AY: <b>{props.currentAcadYear?.session_year}</b>
            </Typography>
          </Box>

          <Box px={0.5} />
          <Box sx={{ display: "flex", alignItems: "center", height: "80%" }}>
            <Divider orientation="vertical" />
          </Box>
          <Box px={0.5} />

          <Avatar
            sizes="small"
            src={props.user?.role_details?.user_profile}
            sx={{
              bgcolor: theme.palette.primary.light,
              height: 30,
              width: 30,
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/dashboard/profile")}
          >
            {props.user?.role_details?.name[0]}
          </Avatar>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "baseline",
          px: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ pr: 1, fontWeight: "bold" }}
          color={"text.secondary"}
        >
          Hi,
        </Typography>
        <Typography
          variant="h6"
          sx={{ pr: 1, textTransform: "capitalize", fontWeight: "bold" }}
        >
          {props.user?.role_details?.name
            .split(" ")
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            })
            ?.map((item, index) => `${item} `)}{" "}
        </Typography>
        {/* <Typography variant="caption" color={"text.secondary"}>
          ({props.user?.personal_info?.role})
        </Typography> */}
      </Box>
      <Box>
        <Typography
          variant="body2"
          color={"text.secondary"}
          sx={{
            px: 2,
            textTransform: "capitalize",
          }}
        >
          {props?.schoolDetails?.school_name}
        </Typography>
      </Box>

      {/* Help Desk */}

      {branchStaffsLoading ||
      (!branchStaffsLoading && branchStaffs?.length > 0) ? (
        <Box sx={{ backgroundColor: theme.palette.background.default, my: 1 }}>
          <Box sx={{ p: 2, pb: 0, alignItems: "center", pt: 1 }}>
            <Typography
              sx={{ whiteSpace: "noWrap" }}
              variant="sm"
              fontWeight="bold"
              color="text.secondary"
            >
              <SupportRoundedIcon sx={{ height: 16 }} /> Help Desk
            </Typography>
          </Box>
          <Box
            sx={{ px: 2, display: "flex", overflowX: "scroll", gap: 1, pb: 1 }}
          >
            {branchStaffsLoading ? (
              [1, 2, 3].map((index) => (
                <Paper
                  key={index}
                  sx={{
                    border: `0.25px solid grey`,
                    borderRadius: 3,
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <>
                          <Skeleton variant="text" width={160} height={30} />
                        </>
                      }
                      secondary={
                        <>
                          <Skeleton variant="text" width={100} height={20} />
                          <Skeleton variant="text" width={100} height={20} />
                          <Skeleton variant="text" width={100} height={20} />
                        </>
                      }
                    />
                    <Box p={1}></Box>
                    {/* <IconButton aria-label="call" color="success">
                      <Skeleton variant="circular" width={40} height={40} />
                    </IconButton>
                    <IconButton aria-label="mail" color="warning">
                      <Skeleton variant="circular" width={40} height={40} />
                    </IconButton> */}
                  </ListItem>
                </Paper>
              ))
            ) : (
              <>
                {branchStaffs.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: theme.palette.base.white,
                      border: `0.25px solid ${theme.palette.divider}`,
                      borderRadius: 3,
                      display: "flex",
                      flexDirection: "column",
                      p: 2,
                      gap: 0.5,
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{ whiteSpace: "noWrap" }}
                        variant="sm"
                        fontWeight="bold"
                        color="text.primary"
                      >
                        {truncate(item?.name)}
                      </Typography>

                      <Typography
                        sx={{ whiteSpace: "noWrap" }}
                        variant="sm"
                        color="text.primary"
                      >
                        {item?.level_name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      onClick={() => {
                        copyToClipboard(item?.contact, "Contact number");
                      }}
                    >
                      <CallRoundedIcon
                        color="success"
                        sx={{ fontSize: "12px" }}
                      />
                      <Typography sx={{ whiteSpace: "nowrap" }} variant="xs">
                        {item?.contact}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      onClick={() => {
                        copyToClipboardUsingNavigator(
                          item?.email,
                          "Email address"
                        );
                      }}
                    >
                      <EmailRoundedIcon
                        color="warning"
                        sx={{ fontSize: "12px" }}
                      />
                      <Typography sx={{ whiteSpace: "nowrap" }} variant="xs">
                        {item?.email}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      ) : null}

      {/* <<<<----    Flash Announcement    ---->>>> */}
      {<UpcomingCompetition />}
      {/* <<<<----    DOODLE    ---->>>> */}
      {doodleLoading ? (
        <Skeleton sx={{ mx: 2, mt: 1, height: 200, borderRadius: 4 }} />
      ) : (
        showDoodle && (
          <Paper
            sx={{
              position: "relative",
              overflow: "hidden",
              mx: 2,
              my: 3,
              borderRadius: 4,
              transition: "all 0.5s",
              boxShadow: 1,
              border: `1px solid ${theme.palette.grey[200]}`,
            }}
            onClick={() => setOpenDoodleDetails(true)}
          >
            {/* <Box
              sx={{
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  px: 2,
                  py: 1,
                  my: "auto",
                }}
              >
                {doodleData?.title}
              </Typography>
              <IconButton onClick={() => setOpenDoodleDetails(true)}>
                <InfoOutlinedIcon />
              </IconButton>
            </Box> */}
            <img
              src={`${AMAZON_S3.ERP_BUCKET}/${doodleData?.image}`}
              alt="doodle"
              width={"100%"}
            />
          </Paper>
        )
      )}
      {/* Student Dashboard  */}
      {props.user?.user_level === 13 ? (
        <StudentDashboard update={update} />
      ) : null}
      <Container
        type="main"
        component="main"
        maxWidth="sm"
        sx={{
          p: 0,
          mt: 2,
          backgroundImage: theme.palette.gradient.main,
          position: "relative",
          borderRadius: "24px",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${Ornament})`,
            backgroundRepeat: "repeat",
          }}
        >
          <Grid container spacing={0} sx={{ p: 1, height: "100%" }}>
            {/* <<<<----    Common    ---->>>> */}
            {/* {props.user?.user_level === 13 &&
              FeatureCard({
                name: "Dashboard",
                icon: DashboardRoundedIcon,
                navigate_to: "/student-dashboard",
              })} */}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Communication")
                  ?.child_module.find((o) => o.child_name === "Announcement")
                ? FeatureCard({
                    name: "Announcements",
                    icon: AnnouncementRoundedIcon,
                    navigate_to: "/announcements",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Assessment")
                  ?.child_module.find((o) => o.child_name === "Take Test")
                ? FeatureCard({
                    name: "Assessment",
                    icon: AssessmentRoundedIcon,
                    navigate_to: "/assessment-list",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Assessment")
                  ?.child_module.find((o) => o.child_name === "Report Card")
                ? FeatureCard({
                    name: "Report Card",
                    icon: SummarizeRoundedIcon,
                    navigate_to: "/student-report",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Diary")
                  ?.child_module.find((o) => o.child_name === "Student Diary")
                ? FeatureCard({
                    name: "Diary",
                    icon: LocalLibraryRoundedIcon,
                    navigate_to: "/diary",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ebook")
                  ?.child_module.find((o) => o.child_name === "Ebook View")
                ? FeatureCard({
                    name: "E-Book",
                    icon: MenuBookIcon,
                    navigate_to: "/ebook",
                  })
                : null
              : null}

            {props?.user?.user_level === 13
              ? props?.userNavigationData
                  ?.find((o) => o?.parent_modules === "Ibook")
                  ?.child_module.find((o) => o?.child_name === "Ibook View")
                ? FeatureCard({
                    name: "I-Book",
                    icon: PlayLessonIcon,
                    navigate_to: "/ibook",
                  })
                : null
              : null}

            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Online Books")
                  ?.child_module.find((o) => o.child_name === "Online Books")
                ? FeatureCard({
                    name: "Online Books",
                    icon: PlayLessonIcon,
                    navigate_to: "/online-books",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Student View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Homework")
                  ?.child_module.find(
                    (o) => o.child_name === "Student Homework"
                  )
                ? FeatureCard({
                    name: "Homework",
                    icon: HomeWorkRoundedIcon,
                    navigate_to: "/homework",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Calendar")
                  ?.child_module.find(
                    (o) => o.child_name === "Student Calendar"
                  )
                ? FeatureCard({
                    name: "Calendar",
                    icon: CalendarMonthRoundedIcon,
                    navigate_to: "/calendar",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Attendance")
                  ?.child_module.find((o) => o.child_name === "View Attendance")
                ? FeatureCard({
                    name: "Attendance",
                    icon: ShowChartRoundedIcon,
                    navigate_to: "/attendance",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Time Table")
                  ?.child_module.find(
                    (o) => o.child_name === "Student Time Table"
                  )
                ? FeatureCard({
                    name: "Time Table",
                    icon: AccessTimeIcon,
                    navigate_to: "/timetable",
                  })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Grievance")
                  ?.child_module.find(
                    (o) => o.child_name === "Grievance Student"
                  )
                ? isB2b
                  ? null
                  : FeatureCard({
                      name: "Grievance",
                      icon: ContactSupportRoundedIcon,
                      navigate_to: "/grievance",
                    })
                : null
              : null}
            {props.user?.user_level === 13 &&
              props.userAppsData?.finance &&
              FeatureCard({
                name: "Fee Payment",
                icon: CurrencyRupeeRoundedIcon,
                navigate_to: "/fee-payment",
                externalLink: window?.location?.href.includes(
                  "dheerajinternational"
                )
                  ? "https://formbuilder.ccavenue.com/live/dheeraj-international-school"
                  : "",
              })}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Activity Management")
                  ?.child_module.find((o) => o.child_name === "School Wall")
                ? isB2b
                  ? null
                  : FeatureCard({
                      name: "School Wall",
                      icon: CardMembershipRoundedIcon,
                      navigate_to: "/school-wall",
                    })
                : null
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Online Class")
                  ?.child_module.find(
                    (o) => o.child_name === "Attend Online Class"
                  )
                ? FeatureCard({
                    name: "Online Class",
                    icon: DvrRoundedIcon,
                    navigate_to: "/online-class",
                  })
                : null
              : null}
            {props.user?.user_level === 13 && !isB2b
              ? FeatureCard({
                  name: "School Bus",
                  icon: DirectionsBusRoundedIcon,
                  navigate_to: "/trips-detail",
                })
              : null}
            {props.user?.user_level === 13
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Circular")
                  ?.child_module.find(
                    (o) => o.child_name === "Student Circular"
                  )
                ? FeatureCard({
                    name: "Circular",
                    icon: BlurCircularRoundedIcon,
                    navigate_to: "/circular",
                  })
                : null
              : null}
            {/* <<<<----    TEACHER    ---->>>> */}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Communication")
                  ?.child_module.find((o) => o.child_name === "Announcement")
                ? FeatureCard({
                    name: "Announcements",
                    icon: AnnouncementRoundedIcon,
                    navigate_to: "/announcements-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Assessment")
                  ?.child_module.find(
                    (o) => o.child_name === "Assessment Report"
                  )
                ? FeatureCard({
                    name: "Assessment",
                    icon: AssessmentRoundedIcon,
                    navigate_to: "/assessment-reports",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Diary")
                  ?.child_module.find((o) => o.child_name === "Teacher Diary")
                ? FeatureCard({
                    name: "Diary",
                    icon: LocalLibraryRoundedIcon,
                    navigate_to: "/diary-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Homework")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Homework"
                  )
                ? FeatureCard({
                    name: "Homework",
                    icon: HomeWorkRoundedIcon,
                    navigate_to: "/homework-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Time Table")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Time Table"
                  )
                ? FeatureCard({
                    name: "Time Table",
                    icon: AccessTimeIcon,
                    navigate_to: "/timetable-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData?.find(
                  (o) => o.parent_modules === "Attendance"
                )
                ? FeatureCard({
                    name: "Attendance",
                    icon: ShowChartRoundedIcon,
                    navigate_to: "/attendance-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Calendar")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Calendar"
                  )
                ? FeatureCard({
                    name: "Calendar",
                    icon: CalendarMonthRoundedIcon,
                    navigate_to: "/calendar-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ebook")
                  ?.child_module.find((o) => o.child_name === "Ebook View")
                ? FeatureCard({
                    name: "E-Book",
                    icon: MenuBookIcon,
                    navigate_to: "/ebook",
                  })
                : null
              : null}

            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ibook")
                  ?.child_module.find((o) => o.child_name === "Ibook View")
                ? FeatureCard({
                    name: "I-Book",
                    icon: PlayLessonIcon,
                    navigate_to: "/ibook",
                  })
                : null
              : null}

            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Online Class")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher View Class"
                  )
                ? FeatureCard({
                    name: "Online Class",
                    icon: DvrRoundedIcon,
                    navigate_to: "/online-class/view",
                  })
                : null
              : null}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Circular")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Circular"
                  )
                ? FeatureCard({
                    name: "Circular",
                    icon: BlurCircularRoundedIcon,
                    navigate_to: "/teacher-circular",
                  })
                : null
              : null}

            {props.user?.user_level === 11 &&
              FeatureCard({
                name: "Observation Evaluation",
                icon: PersonSearchRoundedIcon,
                navigate_to: "/observation/teacher-view",
                isDisabled: !isObservationEnabled,
              })}
            {props.user?.user_level === 28 &&
              FeatureCard({
                name: "Observation Evaluation",
                icon: PersonSearchRoundedIcon,
                navigate_to: `/observation/non-acad-view?level=${props.user?.user_level}`,
                isDisabled: !isObservationEnabled,
              })}
            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Online Books")
                  ?.child_module.find((o) => o.child_name === "Online Books")
                ? FeatureCard({
                    name: "Online Books",
                    icon: PlayLessonIcon,
                    navigate_to: "/online-books",
                  })
                : null
              : null}

            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "User Management")
                  ?.child_module.find((o) => o.child_name === "User Groups")
                ? FeatureCard({
                    name: "User Management",
                    icon: PeopleRoundedIcon,
                    navigate_to: "/user-management/view-user",
                  })
                : null
              : null}

            {props.user?.user_level === 11
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Activity Management")
                  ?.child_module.find(
                    (o) => o.child_name === "Activity Management"
                  )
                ? FeatureCard({
                    name: "Activity Management",
                    icon: DashboardRoundedIcon,
                    navigate_to: "/activity-management",
                  })
                : null
              : null}

            {/* <<<<----    PRINCIPAL    ---->>>> */}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Communication")
                  ?.child_module.find((o) => o.child_name === "Announcement")
                ? FeatureCard({
                    name: "Announcements",
                    icon: AnnouncementRoundedIcon,
                    navigate_to: "/announcements-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Assessment")
                  ?.child_module.find(
                    (o) => o.child_name === "Assessment Report"
                  )
                ? FeatureCard({
                    name: "Assessment",
                    icon: AssessmentRoundedIcon,
                    navigate_to: "/assessment-reports",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData?.find(
                  (o) => o.parent_modules === "Attendance"
                )
                ? FeatureCard({
                    name: "Attendance",
                    icon: ShowChartRoundedIcon,
                    navigate_to: "/attendance-principal",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Calendar")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Calendar"
                  )
                ? FeatureCard({
                    name: "Calendar",
                    icon: CalendarMonthRoundedIcon,
                    navigate_to: "/calendar-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Diary")
                  ?.child_module.find((o) => o.child_name === "Teacher Diary")
                ? FeatureCard({
                    name: "Diary",
                    icon: LocalLibraryRoundedIcon,
                    navigate_to: "/diary-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ebook")
                  ?.child_module.find((o) => o.child_name === "Ebook View")
                ? FeatureCard({
                    name: "E-Book",
                    icon: MenuBookIcon,
                    navigate_to: "/ebook",
                  })
                : null
              : null}

            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ibook")
                  ?.child_module.find((o) => o.child_name === "Ibook View")
                ? FeatureCard({
                    name: "I-Book",
                    icon: PlayLessonIcon,
                    navigate_to: "/ibook",
                  })
                : null
              : null}

            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}

            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Homework")
                  ?.child_module.find((o) => o.child_name === "Management View")
                ? FeatureCard({
                    name: "Homework",
                    icon: HomeWorkRoundedIcon,
                    navigate_to: "/homework-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Time Table")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Time Table"
                  )
                ? FeatureCard({
                    name: "Time Table",
                    icon: AccessTimeIcon,
                    navigate_to: "/timetable-principal",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Circular")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Circular"
                  )
                ? FeatureCard({
                    name: "Circular",
                    icon: BlurCircularRoundedIcon,
                    navigate_to: "/teacher-circular",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Online Books")
                  ?.child_module.find((o) => o.child_name === "Online Books")
                ? FeatureCard({
                    name: "Online Books",
                    icon: PlayLessonIcon,
                    navigate_to: "/online-books",
                  })
                : null
              : null}
            {props.user?.user_level === 8
              ? props.userNavigationData?.some(
                  (o) => o.parent_modules === "Activity Management"
                )
                ? FeatureCard({
                    name: "Activity Management",
                    icon: DashboardRoundedIcon,
                    navigate_to: "/activity-management",
                  })
                : null
              : null}

            {props.user?.user_level === 8
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "User Management")
                  ?.child_module.find((o) => o.child_name === "View User")
                ? FeatureCard({
                    name: "User Management",
                    icon: PeopleRoundedIcon,
                    navigate_to: "/user-management/view-user",
                  })
                : null
              : null}

            {/* {FeatureCard({
          name: "Desktop View",
          icon: ScreenSearchDesktopRoundedIcon,
          navigate_to: API_MASTER.slice(0, -5),
          externalLink: true,
        })} */}
            {/* <Box
          sx={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url(${HBD1})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: `${theme.palette.background.default}99`,
          }}
        ></Box> */}
            {/* <<<<----    COORDINATOR = 10   ---->>>> */}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Communication")
                  ?.child_module.find((o) => o.child_name === "Announcement")
                ? FeatureCard({
                    name: "Announcements",
                    icon: AnnouncementRoundedIcon,
                    navigate_to: "/announcements-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Assessment")
                  ?.child_module.find(
                    (o) => o.child_name === "Assessment Report"
                  )
                ? FeatureCard({
                    name: "Assessment",
                    icon: AssessmentRoundedIcon,
                    navigate_to: "/assessment-reports",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData?.find(
                  (o) => o.parent_modules === "Attendance"
                )
                ? FeatureCard({
                    name: "Attendance",
                    icon: ShowChartRoundedIcon,
                    navigate_to: "/attendance-principal",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Calendar")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Calendar"
                  )
                ? FeatureCard({
                    name: "Calendar",
                    icon: CalendarMonthRoundedIcon,
                    navigate_to: "/calendar-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Diary")
                  ?.child_module.find((o) => o.child_name === "Teacher Diary")
                ? FeatureCard({
                    name: "Diary",
                    icon: LocalLibraryRoundedIcon,
                    navigate_to: "/diary-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ebook")
                  ?.child_module.find((o) => o.child_name === "Ebook View")
                ? FeatureCard({
                    name: "E-Book",
                    icon: MenuBookIcon,
                    navigate_to: "/ebook",
                  })
                : null
              : null}

            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Ibook")
                  ?.child_module.find((o) => o.child_name === "Ibook View")
                ? FeatureCard({
                    name: "I-Book",
                    icon: PlayLessonIcon,
                    navigate_to: "/ibook",
                  })
                : null
              : null}

            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Homework")
                  ?.child_module.find((o) => o.child_name === "Management View")
                ? FeatureCard({
                    name: "Homework",
                    icon: HomeWorkRoundedIcon,
                    navigate_to: "/homework-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Time Table")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Time Table"
                  )
                ? FeatureCard({
                    name: "Time Table",
                    icon: AccessTimeIcon,
                    navigate_to: "/timetable-principal",
                  })
                : null
              : null}
            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Circular")
                  ?.child_module.find(
                    (o) => o.child_name === "Teacher Circular"
                  )
                ? FeatureCard({
                    name: "Circular",
                    icon: BlurCircularRoundedIcon,
                    navigate_to: "/teacher-circular",
                  })
                : null
              : null}

            {props.user?.user_level === 10
              ? props.userNavigationData?.some(
                  (o) => o.parent_modules === "Activity Management"
                )
                ? FeatureCard({
                    name: "Activity Management",
                    icon: DashboardRoundedIcon,
                    navigate_to: "/activity-management",
                  })
                : null
              : null}

            {props.user?.user_level === 10
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "User Management")
                  ?.child_module.find((o) => o.child_name === "View User")
                ? FeatureCard({
                    name: "User Management",
                    icon: PeopleRoundedIcon,
                    navigate_to: "/user-management/view-user",
                  })
                : null
              : null}

            {/* CCA */}
            {[64].includes(props.user?.user_level) &&
              FeatureCard({
                name: "Video Observation",
                icon: PlayCircleOutlinedIcon,
                navigate_to: "/observation-list-videolink",
                isDisabled: !isObservationEnabled,
              })}

            {/* <<<<----    OBSERVATION EVALUATION ()    ---->>>> */}

            {[2, 8, 9, 10, 26, 27, 34, 47, 57].includes(
              props.user?.user_level
            ) &&
              FeatureCard({
                name: "Observation Evaluation",
                icon: PersonSearchRoundedIcon,
                navigate_to: "/observation-evaluation-menu",
                isDisabled: !isObservationEnabled,
              })}

            {/* <<<<----    DRIVER    ---->>>> */}
            {props.user?.user_level === 15 && !isB2b
              ? FeatureCard({
                  name: "Bus Details",
                  icon: BusAlertRoundedIcon,
                  navigate_to: "/bus-details",
                })
              : null}
            {/* <<<<----    TRANSPORT INCHARGE    ---->>>> */}
            {props.user?.user_level === 28 && !isB2b
              ? FeatureCard({
                  name: "Bus Details",
                  icon: BusAlertRoundedIcon,
                  navigate_to: "/bus-details",
                })
              : null}
            {/* For All users */}
            {FeatureCard({
              name: "QR Content",
              icon: QrCodeScannerRoundedIcon,
              navigate_to: "/qr-content",
            })}
            {/* For Relation Manager(47), Front office Executive(14) */}
            {/* {[14, 47].includes(props.user?.user_level) &&
              !isB2b &&
              FeatureCard({
                name: "Relationship Management",
                icon: HubRoundedIcon,
                navigate_to: "/relation-managment",
              })} */}
            {!isB2b &&
              FeatureCard({
                name: "School Gate",
                icon: GppGoodRoundedIcon,
                navigate_to: "/myschoolgate",
              })}
            {isB2b &&
              window.location.href.includes("dps.letseduvate.com") &&
              FeatureCard({
                name: "School Gate",
                icon: GppGoodRoundedIcon,
                navigate_to: "/myschoolgate",
              })}

            {/* {[8, 57, 26].includes(props.user?.user_level) &&
              FeatureCard({
                name: "Vacancy",
                icon: WorkIcon,
                navigate_to: "/vacancies",
              })} */}

            {/* <<<<----    CENTRAL TEAM (2)    ---->>>> */}
            {props.user?.user_level === 2
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Homework")
                  ?.child_module.find(
                    (o) => o.child_name === "Homework Dashboard"
                  )
                ? FeatureCard({
                    name: "Homework",
                    icon: HomeWorkRoundedIcon,
                    navigate_to: "/hw-dashboard",
                  })
                : null
              : null}
            {props.user?.user_level === 1
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 2
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 26
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
            {props.user?.user_level === 27
              ? props.userNavigationData
                  ?.find((o) => o.parent_modules === "Lesson Plan")
                  ?.child_module.find((o) => o.child_name === "Teacher View")
                ? FeatureCard({
                    name: "Lesson Plan",
                    icon: ImportContactsOutlinedIcon,
                    navigate_to: "/lesson-plan-teacher",
                  })
                : null
              : null}
          </Grid>
        </div>
      </Container>
      {/* <<<<----    ALL OTHER USERS    ---->>>> */}
      {[8, 10, 11, 13, 15, 28, 47].includes(props.user?.user_level) ? null : (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Paper sx={{ p: 2, width: "100%" }}>
            <div
              style={{
                backgroundImage: `url(${ComingSoonImg})`,
                height: 100,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            {props.user?.user_level === 23 ? (
              <Typography
                variant="subtitle2"
                sx={{ pt: 2, color: theme.palette.error.main }}
              >
                <b>User level not assigned to your profile.!!!</b>
              </Typography>
            ) : (
              <Typography variant="subtitle2" sx={{ pt: 2 }}>
                <b>More features coming soon for you!!!</b>
              </Typography>
            )}

            {props.user?.user_level === 23 ? (
              <Typography variant="caption">
                <b>Kindly contact the school system administrator.</b>
              </Typography>
            ) : (
              <Typography variant="caption">
                Please use the desktop version for now.
              </Typography>
            )}
          </Paper>
        </Box>
      )}
      <Box sx={{ p: 2 }}></Box>
      {/* <Button
        onClick={() => {
          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            setAlert("info", "Please update your app for this feature!!");
            console.log(err);
          }
        }}
      >
        OHHHHHH
      </Button> */}

      <SwipeableDrawer
        onClose={() => setOpenDoodleDetails(false)}
        onOpen={() => {}}
        open={openDoodleDetails}
        anchor="bottom"
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        sx={{ zIndex: 10001 }}
      >
        <Card
          sx={{
            minWidth: 275,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,

            overflow: "auto",
          }}
        >
          <CardContent>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                p: 2,

                position: "relative",
              }}
            >
              <Puller />
            </Box>
            <Typography>{doodleData?.title}</Typography>
            <Box sx={{ p: 0 }}>
              <img
                src={`${AMAZON_S3.ERP_BUCKET}/${doodleData?.image}`}
                alt="doodle"
                width={"100%"}
              />
              <Box sx={{ p: 0.5, py: 2 }}>
                <Typography
                  variant="sm"
                  type="regular"
                  color={theme.palette.gray.main}
                >
                  {doodleData?.description}
                </Typography>
              </Box>
              {(doodleData?.enable_branches || []).some(
                (branchId) => branchId === props?.branch?.id
              ) && props?.user?.user_level === 13 ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pb: 1,
                  }}
                >
                  <Button variant="contained" fullWidth onClick={handleFinance}>
                    Pay Now
                  </Button>
                </Box>
              ) : null}
              <Divider />
              <Box sx={{ py: 1 }}>
                <Typography
                  variant="xs"
                  type="regular"
                  color={theme.palette.gray.main}
                  p={2}
                >
                  Posted on {doodleData?.doodle_date}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </SwipeableDrawer>
      {/* Settings Drawer */}
      <SettingsDrawer
        showSettingsDrawer={showSettingsDrawer}
        setShowSettingsDrawer={setShowSettingsDrawer}
        onUpdate={onUpdate}
      />
      {showProfileDrawer && (
        <ProfileDrawer
          showProfileDrawer={showProfileDrawer}
          setShowProfileDrawer={setShowProfileDrawer}
        />
      )}
      {/* PopUp Details */}
      {props.user?.user_level === 13 && financeBranchId ? (
        <PopUp financeBranchId={financeBranchId} />
      ) : null}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  userNavigationData: state.user.userNavigationData,
  userAppsData: state.user.userAppsData,
  moduleId: state.user.moduleId,
  academicYear: state.user?.branch?.session_year,
  branchId: state.user.branchId,
  acadYearList: state.user.acadYearList,
  currentAcadYear: state.user.currentAcadYear,
  branch: state.user.branch,
  schoolDetails: state.user.schoolDetails,
  sysConfig: state.user.userData,
});

const mapActionsToProps = {
  setAcademicYear,
};

export default connect(mapStateToProps, mapActionsToProps)(Home);

function FeatureCard(item, index) {
  const navigate = useNavigate();
  const platform = localStorage.getItem("platform");

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "warning",
    message: "error",
  });
  const setAlert = (severity = "info", message = "error") => {
    setOpenSnackbar(true);
    setSnackbarData({
      severity: severity,
      message: message,
    });
  };

  if (item?.isDisabled) return null;

  return (
    <Grid item xs={3} textAlign={"center"} key={index}>
      {item.externalLink ? (
        <a
          style={{ textDecoration: "none" }}
          href={item.externalLink}
          target="_blank"
          rel="noreferrer"
        >
          <Box
            sx={{
              backgroundColor: "white",
              py: 2,
              px: 1,
              borderRadius: 2,
              overflow: "hidden",
              overflow: "hidden",
              fontSize: "0.75rem",
              fontWeight: "400",
              color: theme.palette.text.primary,
              // color: theme.palette.text.primary,
              color: "#fff",
            }}
          >
            <Avatar
              sx={{
                margin: "auto",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <item.icon />
            </Avatar>

            <Typography variant="sm" type="semibold" color={"text.primary"}>
              {item.name}
            </Typography>
          </Box>
        </a>
      ) : (
        <Box
          onClick={() => {
            if (
              item?.name === "QR Content" &&
              (platform === "mb_droid" || platform === "mb_ios")
            ) {
              window.flutter_inappwebview.callHandler("openQRContentHandler", {
                QRContent: "QRContent",
              });
            } else {
              navigate(item.navigate_to);
            }
          }}
          style={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              // backgroundColor: "white",
              py: 2,
              px: 1,
              borderRadius: 2,
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <Avatar
              sx={{
                borderRadius: 3,
                margin: "auto",
                backgroundColor: theme.palette.primary.main,
                border: "0.5px solid #ffffffc2",
                p: 1,
                width: 56,
                height: 56,
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.16) 100%)",
                boxShadow: "0px 0px 22px 0px #175CD3 inset",
              }}
              variant="rounded"
            >
              <item.icon />
            </Avatar>
            <div
              style={{
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                component="div"
                sx={{
                  pt: 1,
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                  // overflowWrap: "anywhere",

                  overflow: "hidden",

                  color: "#fff",

                  textAlign: "center",
                  /* Caption/semibold */
                  // fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  letterSpacing: "0.4px",
                  opacity: 0.800000011920929,
                }}
                variant="xs"
                type="semibold"
              >
                {item.name}
              </Typography>
            </div>
          </Box>
        </Box>
      )}
    </Grid>
  );
}

function ComingSoonFeatureCard(item, index) {
  const navigate = useNavigate();
  const platform = localStorage.getItem("platform");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "warning",
    message: "error",
  });
  const setAlert = (severity = "info", message = "error") => {
    setOpenSnackbar(true);
    setSnackbarData({
      severity: severity,
      message: message,
    });
  };
  return (
    <>
      <Grid item xs={3} textAlign={"center"} key={index}>
        <Box
          sx={{
            // backgroundColor: "white",
            py: 2,
            px: 1,
            borderRadius: 2,
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            setAlert("warning", "Feature Coming soon for your Branch");
          }}
        >
          <Avatar
            sx={{
              margin: "auto",
              backgroundColor: theme.palette.primary.main,
              borderRadius: 3,
              margin: "auto",
              backgroundColor: theme.palette.primary.main,
              border: "0.5px solid #ffffffc2",
              p: 1,
              width: 56,
              height: 56,
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.16) 100%)",
              boxShadow: "0px 0px 22px 0px #175CD3 inset",
            }}
            variant="rounded"
          >
            <item.icon />
          </Avatar>
          <div
            style={{
              width: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              component="div"
              sx={{
                pt: 1,
                // textOverflow: "ellipsis",
                overflow: "hidden",

                color: "#fff",

                whiteSpace: "pre-wrap",
                // overflowWrap: "anywhere",

                textAlign: "center",
                /* Caption/semibold */
                // fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                letterSpacing: "0.4px",
                opacity: 0.800000011920929,
              }}
              variant="xs"
              type="semibold"
            >
              {item.name}
            </Typography>
          </div>
        </Box>
      </Grid>
      <SimpleSnackbar
        message={snackbarData.message}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarData.severity}
      />
    </>
  );
}
