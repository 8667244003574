import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DiaryCardSkeleton from "./DiaryCardSkeleton";
import DiaryCard from "./DiaryCard";
import NoData from "components/NoData";
import axiosInstance from "util/axiosInstance";
import { connect } from "react-redux";
import dayjs from "dayjs";

function DailyDiary(props) {
  const { date, setAlert } = props;

  const [diaryData, setDiaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moduleId, setModuleId] = useState("");

  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  const fetchDailyDiary = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `/academic/new/dialy-diary-messages/?date=${formattedDate}&diary_type=2`
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          setDiaryData(res?.data?.result);
        } else {
          setAlert("error", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", err?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDailyDiary();
  }, [date]);

  useEffect(() => {
    if (props.userNavigationData && props.userNavigationData.length) {
      props.userNavigationData.forEach((item) => {
        if (
          item.parent_modules === "Diary" &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === "Student Diary") {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, [moduleId]);

  return (
    <Box>
      {isLoading ? (
        <Stack>
          {[1, 2, 3, 4].map((i, index) => (
            <DiaryCardSkeleton key={index} />
          ))}
        </Stack>
      ) : (
        <Box sx={{ minHeight: "70vh" }}>
          {diaryData?.length > 0 ? (
            <>
              {diaryData?.map((each, index1) => {
                return (
                  <Box key={index1}>
                    {/* <div>----- {each?.subject_name} -----</div> */}
                    {each?.grade_data?.map((item, index) => {
                      return (
                        <DiaryCard
                          key={index}
                          diaryData={item}
                          subject={each}
                          date={formattedDate}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </>
          ) : (
            <NoData text="No Diary found for this date" />
          )}
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  moduleId: state.user.moduleId,
  userNavigationData: state.user.userNavigationData,
  academicYearId: state.user?.branch?.session_year?.id,
});

export default connect(mapStateToProps)(DailyDiary);
