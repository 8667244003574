import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import moment from "moment";

function DiaryCard(props) {
  return (
    <Card
      sx={{
        minWidth: 275,
        mb: 2,
        backgroundColor: `${props?.diaryType == 2 ? "#fffde8" : "#fff"}`,
      }}
    >
      <CardContent>
        <Typography variant="subtitle1">
          <b>{props.subjectName}</b>
        </Typography>
        {props?.diaryData?.title && (
          <Typography variant="subtitle2">
            <b>{props?.diaryData?.title}</b>
          </Typography>
        )}

        {props?.diaryData?.teacher_report?.homework && (
          <Typography
            sx={{ textTransform: "capitalize" }}
            variant="subtitle2"
            color="text.primary"
          >
            {props?.diaryData?.teacher_report?.homework}
          </Typography>
        )}

        {props.diaryType && (
          <Chip size="small" label={showDiaryType(props.diaryType)} />
        )}

        <Box sx={{ mt: 0.5, mb: 0.5 }}>
          <Typography variant="caption" color="text.secondary">
            Created by: <b>{props?.createdBy}</b>
          </Typography>
          <br style={{ height: 0 }} />

          <Typography variant="caption" color="text.secondary">
            Created on: <b>{moment(props?.createdAt).format("DD MMM YYYY")}</b>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Chip
            variant="outlined"
            icon={<AttachFileIcon />}
            label={`${props.files} files`}
          ></Chip>

          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              props.setShowDrawer(true);
              props.setDrawerData(props.diaryData);
            }}
          >
            View more
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

function showDiaryType(n) {
  if (n == 1) {
    return "General Diary";
  } else if (n == 2) {
    return "Daily Diary";
  }
}

export default DiaryCard;
