import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  Chip,
  Divider,
  Avatar,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { styled } from "@mui/material/styles";

import { connect } from "react-redux";
import { API_REPORT, X_DTS_HOST, useFlutter } from "../../apiConfig";
import axiosInstance from "../../util/axiosInstance";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";

import moment from "moment/moment";
import theme from "../../util/theme";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

function HomeWork(props) {
  const navigate = useNavigate();
  const [hwPerformance, setHwPerformance] = useState([]);
  const [hwPerformanceLoading, setHwPerformanceLoading] = useState(false);
  const [overallPerformance, setOverallPerformance] = useState(0);

  useEffect(() => {
    if (props?.current_acad_year?.id) {
      fetchHwPerformance();
    }
  }, [props?.current_acad_year?.id]);

  const fetchHwPerformance = () => {
    setHwPerformanceLoading(true);
    axiosInstance
      .get(
        `${API_REPORT}/reports/dashboard_student_hw_performance/?session_id=${props?.current_acad_year?.id}`,
        {
          headers: {
            "X-DTS-Host": X_DTS_HOST,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setHwPerformance(res?.data?.result);
          if (res?.data?.result?.total_assigned !== 0) {
            setOverallPerformance(
              (
                (res?.data?.result?.total_submitted /
                  res?.data?.result?.total_assigned) *
                100
              )?.toFixed(2)
            );
          }
        }
      })
      .catch((err) => {
        console.log("Hw Performance fetch error", err);
      })
      .finally(() => {
        setHwPerformanceLoading(false);
      });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        overallPerformance <= 50
          ? theme.palette.error.main
          : overallPerformance <= 75
          ? theme.palette.secondary.main
          : overallPerformance <= 90
          ? theme.palette.success.light
          : theme.palette.success.main,
    },
  }));

  const getHwStatusCode = (hwData) => {
    if (hwData?.hw_status === "evaluated") {
      return 3;
    } else {
      if (hwData?.hw_status === "submitted") {
        return 2;
      } else {
        return 1;
      }
    }
  };

  //   status_choices = (
  //     ('0', 'un-opened'),
  //     ('1', 'opened'),
  //     ('2', 'submitted'),
  //     ('3', 'evaluated')
  // )

  const platform = localStorage.getItem("platform");

  const hwCardClickHandler = (hwData) => {
    if (
      getHwStatusCode(hwData) === 1 &&
      (platform === "mb_droid" || platform === "mb_ios") &&
      useFlutter
    ) {
      console.log("in flutter mode");
      window.flutter_inappwebview.callHandler("studentHwSubmitHandler", {
        homeworkId: hwData?.id,
      });
    } else {
      navigate(`/homework-submit/${getHwStatusCode(hwData)}/${hwData?.id}/`);
    }
  };

  return (
    <Box
      className="color-white-bg"
      sx={{
        width: "100%",
        p: 1,
        py: 2,
        // borderRadius: 2,
        my: 2,
        // borderRadius: 4,
      }}
    >
      <Box sx={{ p: 0.5, pb: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ alignItems: "center" }}
        >
          <Typography
            variant="xl"
            type="medium"
            color={theme.palette.gray.dark2}
          >
            Homework
          </Typography>
          <ArrowForwardIosIcon
            sx={{ fontSize: 16, color: theme.palette.gray.main }}
            onClick={() => navigate("/homework")}
          />
        </Box>
        <Typography variant="sm" type="regular" color={theme.palette.gray.main}>
          {hwPerformance?.today_assigned_homework_count} Assigned today •{" "}
          {hwPerformance?.latest_pending_homework_count} Pending
        </Typography>
      </Box>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Box
            className="bg-white"
            sx={{
              p: 1,
              // mx: 1,
              flex: 1,
              // flexBasis: "40vw",
              // maxWidth: "200px",
              // height: "100%",
              borderRadius: 4,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              gap: 1,
              justifyContent: "space-around",
              lineHeight: 0,
              backgroundColor: `rgba(0,0,0,0,0.5)`,
              // border: "2px solid red",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
              border: "1px solid #F0F0F0",
              // minWidth: "150px",
            }}
            // onClick={() => navigate("/calendar")}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 0.1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ position: "relative" }}>
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    disableShrink
                    value={hwPerformance?.cur_month_performance}
                    color="error"
                    sx={{
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                  />
                </Box>
                <Typography
                  variant="md"
                  type="semibold"
                  color={theme.palette.gray.main}
                >
                  {Number(hwPerformance?.cur_month_performance ?? 0)?.toFixed(
                    1
                  )}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor:
                    hwPerformance?.monthly_performance > 0
                      ? "#F6FEF9"
                      : "#FEE4E2",
                  p: 0.25,
                  px: 0.5,
                  borderRadius: 4,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color:
                      hwPerformance?.monthly_performance > 0
                        ? "#12B76A"
                        : "#F04438",
                  }}
                  variant="xs"
                  type="medium"
                >
                  {hwPerformance?.monthly_performance?.toFixed(1)}
                  {hwPerformance?.monthly_performance ? "%" : null}
                </Typography>
                {hwPerformance?.monthly_performance ? (
                  <>
                    {hwPerformance?.monthly_performance > 0 ? (
                      <TrendingUpRoundedIcon
                        sx={{ color: "#12B76A", fontSize: "16px" }}
                      />
                    ) : (
                      <TrendingDownRoundedIcon
                        sx={{ color: "#F04438", fontSize: "16px" }}
                      />
                    )}
                  </>
                ) : null}
              </Box>
            </Box>
            <Typography
              variant="sm"
              type="regular"
              color={theme.palette.gray.main}
            >
              Submissions in {moment().format("MMMM")}
            </Typography>
          </Box>
          <Box
            className=" bg-white"
            sx={{
              p: 1,
              // mx: 1,
              flex: 1,
              // flexBasis: "40vw",
              // maxWidth: "200px",
              // height: "100%",
              borderRadius: 4,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              gap: 1,
              justifyContent: "space-around",
              lineHeight: 0,
              backgroundColor: `rgba(0,0,0,0,0.5)`,
              // border: "2px solid red",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
              border: "1px solid #F0F0F0",
              // minWidth: "150px",
            }}
            // onClick={() => navigate("/calendar")}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 0.1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ position: "relative" }}>
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    disableShrink
                    value={Number(overallPerformance)}
                    sx={{
                      color: "#2970FF",
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={30}
                    thickness={6}
                    {...props}
                  />
                </Box>
                <Typography
                  variant="md"
                  type="semibold"
                  color={theme.palette.gray.main}
                >
                  {Number(overallPerformance)?.toFixed(1)}%
                </Typography>
              </Box>
              {hwPerformance?.total_submitted ? (
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#F2F4F7",
                    p: 0.25,
                    px: 0.5,
                    borderRadius: 4,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#667085",
                    }}
                    variant="xs"
                    type="regular"
                  >
                    {hwPerformance?.total_submitted} /{" "}
                    {hwPerformance?.total_assigned}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Typography
              variant="sm"
              type="regular"
              color={theme.palette.gray.main}
            >
              Overall performance
            </Typography>
          </Box>
        </Box>
      </Box>

      {(hwPerformance?.today_assigned_homework?.length > 0 ||
        hwPerformance?.pending_homework?.length > 0) && (
        <Divider sx={{ py: 2 }} />
      )}

      {hwPerformance?.today_assigned_homework?.length > 0 && (
        <Box
          sx={{
            my: 1,
            //  border: "2px solid red"
            px: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="sm"
              type="regular"
              color={theme.palette.gray.main}
              sx={{ my: 1 }}
            >
              Assigned today
            </Typography>
            <ArrowForwardIosIcon
              sx={{ fontSize: 16, color: theme.palette.gray.main }}
              onClick={() => navigate("/homework")}
            />
          </Box>

          <Box
            className="scrollBar-hide"
            sx={{
              display: "flex",
              overflowX: "auto",
              // width: "100%",
              p: 1,
              px: 0,
              pb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
                flexWrap: "nowrap",
                gap: 1,
              }}
            >
              {hwPerformanceLoading && (
                <Box display="flex">
                  <Skeleton width={150} height={125} sx={{ mr: 1 }} />
                  <Skeleton width={150} height={125} />
                </Box>
              )}
              {hwPerformance?.today_assigned_homework?.map((each, index) => {
                return (
                  <Box
                    className="bg-white"
                    sx={{
                      px: 2,
                      py: 1,
                      ml: 0.5,
                      width: "60vw",
                      maxWidth: "400px",
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      lineHeight: 0,
                      backgroundColor: `rgba(0,0,0,0,0.5)`,
                      border: "1px solid #EAECF0",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={() => hwCardClickHandler(each)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        // jus,
                        width: "100%",
                      }}
                    >
                      <Typography variant="md" type="medium" noWrap>
                        {each?.homework_name}
                      </Typography>
                      <Box>
                        <Typography
                          variant="sm"
                          type="regular"
                          color={theme.palette.gray.main}
                        >
                          {each?.subject_name} • Due on{" "}
                          {moment(each?.last_submission_dt).format("DD MMM")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}

              {hwPerformance?.today_assigned_homework_count > 2 && (
                <Box
                  className=" bg-white"
                  sx={{
                    p: 2,
                    mx: 1,
                    width: "60vw",
                    height: "100%",
                    maxWidth: "400px",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    lineHeight: 0,
                    backgroundColor: `rgba(0,0,0,0,0.5)`,
                  }}
                  onClick={() => {
                    navigate("/homework");
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="md" type="medium">
                      + {hwPerformance?.today_assigned_homework_count - 2} more
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {hwPerformance?.pending_homework?.length > 0 && (
        <Box
          sx={{
            my: 1,
            //  border: "2px solid red"
            px: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="sm"
              type="regular"
              color={"text.secondary"}
              sx={{ my: 1 }}
            >
              Pending homework
            </Typography>
            <ArrowForwardIosIcon
              sx={{ fontSize: 16, color: "text.secondary" }}
              onClick={() => navigate("/homework")}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              // width: "100%",
              p: 1,
              px: 0,
              pb: 2,
            }}
            className="scrollBar-hide"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "center",
                flexWrap: "nowrap",
                gap: 1,
                // border: "1px solid green",
              }}
            >
              {hwPerformanceLoading && (
                <Box display="flex">
                  <Skeleton width={150} height={125} sx={{ mr: 1 }} />
                  <Skeleton width={150} height={125} />
                </Box>
              )}
              {hwPerformance?.pending_homework?.map((each, index) => {
                return (
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      ml: 0.5,
                      width: "60vw",
                      maxWidth: "400px",
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      lineHeight: 0,
                      backgroundColor: theme.palette.warning.light6,
                      border: `1px solid ${theme.palette.warning.light4}`,
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    }}
                    onClick={() => hwCardClickHandler(each)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        // jus,
                        width: "100%",
                      }}
                    >
                      <Typography variant="md" type="medium" noWrap>
                        {each?.homework_name}
                      </Typography>
                      <Box>
                        <Typography
                          variant="sm"
                          type="regular"
                          color="text.secondary"
                        >
                          {each?.subject_name} • Due on{" "}
                          {moment(each?.last_submission_dt).format("DD MMM")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}

              {hwPerformance?.latest_pending_homework_count > 2 && (
                <Box
                  className=" bg-white"
                  sx={{
                    p: 2,
                    mx: 1,
                    width: "60vw",
                    height: "100%",
                    maxWidth: "400px",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    lineHeight: 0,
                    backgroundColor: `rgba(0,0,0,0,0.5)`,
                  }}
                  onClick={() => {
                    navigate("/homework");
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="md" type="medium">
                      + {hwPerformance?.latest_pending_homework_count - 2} more
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* <Box sx={{ display: "flex" }}>
        <Box>
          {hwPerformance?.cur_month_performance !== null && (
            <Box sx={{}}>
              <Typography color="text.secondary" variant="subtitle1">
                % Submission in <b>This Month </b>({moment().format("MMM")}) -
                {hwPerformance?.cur_month_performance} %
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" sx={{ mt: 0.5 }}>
                  <Typography
                    variant="subtitle1"
                    color={
                      hwPerformance?.monthly_performance > 0
                        ? theme.palette.success.main
                        : theme.palette.error.main
                    }
                  >
                    <b>{hwPerformance?.monthly_performance} </b>
                  </Typography>
                  <Typography color="text.secondary" variant="subtitle1">
                    {" "}
                    % since previous Month
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box
          className="card-border color-gray-light"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 1,
            px: 2,
            py: 1,
            my: 1.5,
          }}
          onClick={() => navigate("/homework")}
        >
          <Typography variant="subtitle2">
            <b>View more</b>
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,

  branchDetail: state.user?.branch,
});

export default connect(mapStateToProps)(HomeWork);
