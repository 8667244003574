import {
  Box,
  Button,
  Card,
  Chip,
  Drawer,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { connect } from "react-redux";
import store from "../redux/store";
import {
  SET_BRANCH,
  SET_CURRENT_ACADEMIC_YEAR,
  SET_BRANCH_LIST,
} from "../redux/types";
import axiosInstance from "../util/axiosInstance";
import { useSelector } from "react-redux";

import { AlertNotificationContext } from "../context-api/alert-context/alert-state";
import { setDefaultAcadYearAndBranch } from "../redux/actions/userActions";

function SettingsDrawer(props) {
  const { showSettingsDrawer, setShowSettingsDrawer, acadYearList } = props;

  // let currentListOfBranch = props.currentBranchList;
  const currentListOfBranch = useSelector(
    (state) => state.user.currentBranchList
  );

  // branch: state.user.branch,
  const branch = useSelector((state) => state.user.branch);

  const [acadYrId, setAcadYrId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branchLoading, setBranchLoading] = useState(false);

  const { setAlert } = React.useContext(AlertNotificationContext);

  const alertAction = (
    <Button
      color="inherit"
      size="small"
      onClick={() => {
        setDefaultAcadYearAndBranch();
        setShowSettingsDrawer(false);
        setAlert(
          "success",
          `Default Academic year and Branch selected!`,
          7000,
          true
        );
      }}
    >
      Choose Default
    </Button>
  );

  const closeDrawer = () => {
    if (currentListOfBranch?.length > 0) {
      // can close
      setShowSettingsDrawer(false);
    } else {
      // can't close
      setAlert(
        "error",
        `Please select a valid combination`,
        7000,
        false,
        alertAction
      );
    }
  };

  const currentAcadYearState = useSelector(
    (state) => state.user.currentAcadYear
  );

  // currentBranchList: state.user?.currentBranchList,

  useEffect(() => {
    if (!props?.currentAcadYearId) {
      setDefaultAcadYearAndBranch();
    }
  }, []);

  // const [selectedAcadYr, setSelectedAcadYr] = useState(
  //   props?.currentAcadYearId
  // );

  // useEffect(() => {
  //   setSelectedAcadYr(props?.currentAcadYearId);
  // }, [props?.currentAcadYearId]);

  // const [prevAcadYearId] = useState(props.currentAcadYear?.id);

  // useEffect(() => {
  //   if (!!props.currentAcadYear?.id) {
  //     if (props.currentAcadYear?.id !== prevAcadYearId) {
  //       fetchBranchList(props?.currentAcadYear?.id, props?.moduleId);
  //     }
  //   }
  // }, [props.currentAcadYear?.id, props?.moduleId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setBranchList(props?.currentBranchList);
  // }, [props?.currentBranchList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const fetchBranchList = (
    acadId = props?.currentAcadYear?.id,
    moduleId = props?.moduleId
  ) => {
    setBranchLoading(true);
    axiosInstance
      .get(`/erp_user/branch/?session_year=${acadId}&module_id=${moduleId}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          // setBranchList(res?.data?.data?.results);
          store.dispatch({
            type: SET_BRANCH_LIST,
            payload: res?.data?.data?.results,
          });

          // if (!props?.branch?.branch?.id) {
          if (res?.data?.data?.results[0]) {
            store.dispatch({
              type: SET_BRANCH,
              payload: res?.data?.data?.results[0],
            });
            if (!!props?.onUpdate) {
              props?.onUpdate();
            }
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setBranchLoading(false);
      });
  };

  const handleAcadYear = (item) => {
    store.dispatch({
      type: SET_CURRENT_ACADEMIC_YEAR,
      payload: item,
    });

    setAcadYrId(item?.id);

    fetchBranchList(item?.id);

    // if (!!props?.onUpdate) {
    //   props?.onUpdate();
    // }
  };

  const handleBranchChange = (item) => {
    store.dispatch({
      type: SET_BRANCH,
      payload: item,
    });

    if (!!props?.onUpdate) {
      props?.onUpdate();
    }

    setBranchId(item?.id);
  };

  return (
    <>
      <Drawer
        anchor="top"
        open={showSettingsDrawer}
        onClose={closeDrawer}
        sx={{
          width: "50%",
          //  color: "pink",
          //  backgroundColor: "blue"
          // border: "2px solid red",
        }}
        className="th-drawer-white"
        style={{ backgroundColor: "#ffffff!important" }}
      >
        {/* {list(anchor)} */}
        <Card
          sx={{
            borderRadius: 0,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="subtitle1">
              <b>App Settings</b>
            </Typography>

            {/* Select Academic Year */}
            <Box>
              <Typography variant="subtitle2">Academic Year:</Typography>

              {acadYearList.length <= 0 ? (
                <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                  No data!
                </Typography>
              ) : (
                acadYearList?.map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.id === currentAcadYearState?.id ? (
                      <Chip
                        variant="filled"
                        color="primary"
                        label={item?.session_year}
                        sx={{
                          mr: 1,
                          mb: 1,
                          borderRadius: 1,
                          textTransform: "capitalize",
                        }}
                      />
                    ) : (
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={item?.session_year}
                        sx={{
                          mr: 1,
                          mb: 1,
                          borderRadius: 1,
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleAcadYear(item);
                        }}
                      />
                    )}
                  </React.Fragment>
                ))
              )}
            </Box>

            {/* Select Branch */}
            {/*  */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Select Branch:
              </Typography>
              {branchLoading ? (
                <Skeleton width="60%" />
              ) : (
                <Box>
                  {currentListOfBranch?.length <= 0 ? (
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlign: "center" }}
                    >
                      No branch found!
                    </Typography>
                  ) : (
                    currentListOfBranch?.map((item, index) => (
                      <React.Fragment key={index}>
                        {item?.branch?.id === branch?.branch?.id ? (
                          <Chip
                            variant="filled"
                            color="primary"
                            label={item?.branch?.branch_name}
                            sx={{
                              mr: 1,
                              mb: 1,
                              borderRadius: 1,
                              textTransform: "capitalize",
                            }}
                          />
                        ) : (
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={item?.branch?.branch_name}
                            sx={{
                              mr: 1,
                              mb: 1,
                              borderRadius: 1,
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              handleBranchChange(item);
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))
                  )}
                </Box>
              )}

              {/* {item?.id === props.branchId ? ( */}
            </Box>
          </Box>
        </Card>

        <Box
          sx={{
            // position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // p: 0,
            // m: 0,
            // opacity: 0.5,
            // backgroundColor: "transparent",
            // border: "1px solid red",

            // backgroundColor: theme.palette.background.paper,
            backgroundColor: "transparent",
            p: 1,
            position: "relative",
            height: "5",
          }}
          onClick={() => {
            closeDrawer();
          }}
        >
          <IconButton
            aria-label="close drawer"
            size="large"
            sx={{ mt: 1, p: 1.5, transform: "scale(1.8)" }}
          >
            <CancelRoundedIcon />
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  // branch: state.user.branch,
  acadYearList: state.user.acadYearList,
  currentAcadYear: state.user.currentAcadYear,
  currentAcadYearId: state.user.currentAcadYear?.id,
  moduleId: state.user.moduleId,
  // currentBranchList: state.user?.currentBranchList,
});

export default connect(mapStateToProps)(SettingsDrawer);
