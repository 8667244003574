import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import DiaryCardSkeleton from "./DiaryCardSkeleton";
import DiaryCard from "../DiaryOld/DiaryCard";
import NoData from "../../../components/NoData";
import moment from "moment";
import axiosInstance from "../../../util/axiosInstance";
import { connect } from "react-redux";
import dayjs from "dayjs";
import GeneralDiaryCard from "./GeneralDiaryCard";
import DiaryDrawer from "./DiaryDrawer";

function GeneralDiary(props) {
  const {
    date,
    setAlert,
    // setShowDrawer,
    // setDrawerData,
    startDate,
    endDate,
    filtered,
  } = props;

  const [showDrawer, setShowDrawer] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState({});

  const [diaryData, setDiaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleId, setModuleId] = useState("");

  const fetchGeneralDiary = () => {
    setIsLoading(true);
    const academicYear = props?.academicYearId;
    const sessionYearParam = `&session_year=${academicYear}`;
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    // `/academic/new/dialy-diary-messages/?date=${formattedDate}&diary_type=2`

    axiosInstance
      .get(
        `/academic/v2/general-dairy-messages/?created_date=${formattedDate}&dairy_type=1&session_year=${academicYear}&branch=${props?.branchData?.branch?.id}`
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          setDiaryData(res?.data?.result?.results);
        } else {
          setAlert("error", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", err?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchGeneralDiary();
  }, [date, props?.academicYearId]);

  useEffect(() => {
    if (props.userNavigationData && props.userNavigationData.length) {
      props.userNavigationData.forEach((item) => {
        if (
          item.parent_modules === "Diary" &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === "Student Diary") {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, [moduleId]);

  return (
    <>
      <Box>
        {isLoading ? (
          <Stack>
            {[1, 2, 3, 4].map((i, index) => (
              <DiaryCardSkeleton key={index} />
            ))}
          </Stack>
        ) : (
          <Box sx={{ minHeight: "70vh" }}>
            {diaryData?.length > 0 ? (
              diaryData?.map((each, index) => {
                return (
                  <GeneralDiaryCard
                    key={index}
                    diaryData={each}
                    subject={each?.subject_name}
                    setShowDrawer={setShowDrawer}
                    setDrawerData={setDrawerData}
                    // isStudentDiary={true}
                    // fetchDiaryList={fetchDailyDiaryList}
                  />
                );
              })
            ) : (
              <NoData />
            )}
          </Box>
        )}
      </Box>

      <DiaryDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        // drawerData={}
        drawerData={drawerData}
        diaryType={drawerData?.dairy_type}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  moduleId: state.user.moduleId,
  userNavigationData: state.user.userNavigationData,
  academicYearId: state.user?.currentAcadYear?.id,
  branchData: state.user?.branch,
});

export default connect(mapStateToProps)(GeneralDiary);
